//Provide a global abstraction from the implementation details of an api call
import { BlobApi } from './blob-api';
import { ClientAgentApi } from './clientagent-api';
import { ClientApi } from './client-api';
import { FranchiseeApi } from './franchisee-api';
import { PaymentProfileApi } from './payment-profile-api';
import { ProjectApi } from './project-api';
import { PurchaseOrderApi } from './purchase-order-api';
import { QuoteApi } from './quote-api';
import { SupplierApi } from './supplier-api';
import { UserApi } from './user-api';

export interface ApiFactory {
  blob: () => BlobApi;
  quote: () => QuoteApi;
  client: () => ClientApi;
  project: () => ProjectApi;
  franchisee: () => FranchiseeApi;
  paymentProfile: () => PaymentProfileApi;
  purchaseOrder: () => PurchaseOrderApi;
  supplier: () => SupplierApi;
  clientAgent: () => ClientAgentApi;
  user: () => UserApi;
}

let _apiFactory: ApiFactory = {
  quote: () => {
    throw new Error('please call setApiInjector - quote api not registered');
  },
  blob: () => {
    throw new Error('please call setApiInjector - blob api not registered');
  },
  client: () => {
    throw new Error('please call setApiInjector - client api not registered');
  },
  project: () => {
    throw new Error('please call setApiInjector - project api not registered');
  },
  franchisee: () => {
    throw new Error('please call setApiInjector - franchisee api not registered');
  },
  paymentProfile: () => {
    throw new Error('please call setApiInjector - paymentProfile api not registered');
  },
  purchaseOrder: () => {
    throw new Error('please call setApiInjector - purchaseOrder api not registered');
  },
  supplier: () => {
    throw new Error('please call setApiInjector - supplier api not registered');
  },
  clientAgent: () => {
    throw new Error('please call setApiInjector - clientAgent api not registered');
  },
  user: () => {
    throw new Error('please call setApiInjector - user api not registered');
  }
};

export function setApiFactory(factory: ApiFactory) {
  _apiFactory = factory;
}

export function getApiFactory() {
  return _apiFactory;
}

export * from '../../webmodule-common/other/api/api-injector';

import { cache } from './cache-registry';
import { emptyGuid } from '../../../webmodule-common/other/api/guid';
import { getApiFactory } from '../../api/api-injector';
import { removeDuplicatIds } from '../../../webmodule-common/other/ui/string-helper-functions';
import { TenantLoginPublicInfo } from '../../api/dealer-api-interface-user';

class BranchUsersCache {
  private _users = new Map<string, TenantLoginPublicInfo[]>();

  public async prefetch(branchIds: string[]) {
    const quoteResults = await getApiFactory().quote().getQuoteUserIdsByOwnerIds({ ownerIds: branchIds });
    const poResults = await getApiFactory().franchisee().getPurchaseOrderUserIdsByBranchIds({ branchIds: branchIds });
    let userIds: string[] = [];
    const userCache = cache().userProfile;
    quoteResults?.values.forEach(x => {
      userIds?.push(...x.userIds);
    });
    poResults?.values.forEach(x => {
      userIds?.push(...x.userIds);
    });

    //single fetch
    userIds = removeDuplicatIds(userIds);
    await userCache.getMany(userIds);

    for (const branchId of branchIds) {
      const quoteResult = quoteResults?.values.find(x => x.ownerId === branchId);
      if (quoteResult) {
        this.addUsersToCache(quoteResult.userIds, branchId);
      }
      const poResult = poResults?.values.find(x => x.branchId === branchId);
      if (poResult) {
        this.addUsersToCache(poResult.userIds, branchId);
      }
    }
  }

  protected async addUsersToCache(userIds: string[], branchId: string) {
    const userCache = cache().userProfile;
    const users = await userCache.getMany(userIds);
    if (users) {
      let branchUsers = this.getUsersForBranch(branchId);
      if (!branchUsers) {
        branchUsers = [];
        this._users[branchId] = branchUsers;
      }
      for (const user of users) {
        if (!branchUsers.find(u => u.id === user.id)) {
          branchUsers.push(user.data);
        }
      }
    }
  }

  /**
   * Gets the users for a given branch.
   * @param branchId Id of the branch.
   * @returns An array of user info for the branch, or undefined.
   */
  public getUsersForBranch(branchId?: string): TenantLoginPublicInfo[] | undefined {
    if (branchId && branchId !== emptyGuid) {
      const branchUsers = this._users[branchId];
      if (branchUsers) {
        return branchUsers;
      }
    }
    return undefined;
  }
}

let _db: BranchUsersCache | null = null;
export function flushBranchUsersCache() {
  _db = null;
}
export function branchUsersCache(): BranchUsersCache {
  if (!_db) {
    _db = new BranchUsersCache();
  }
  return _db;
}

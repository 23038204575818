export const defaultQuoteStateReasons = {
  price: 'Price',
  deadline: 'Deadline',
  productNotSuitable: 'Product Not Suitable',
  competitorWin: 'Competitor Win',
  unresponsiveClient: 'Unresponsive Client',
  indecisiveClient: 'Indecisive Client',
  unhappyClient: 'Unhappy Client',
  difficultClient: 'Difficult Client',
  superceeded: 'Superseded',
  other: 'Other'
};

import { awaitableTemplate } from '../../../../webmodule-common/other/ui/template-processor';
import { cache } from '../../cache-impl/cache-registry';
import { customElement } from 'lit/decorators.js';
import { DataBinding } from '../../../../webmodule-common/other/ui/databinding/databinding';
import { DataTracker, FieldType } from '../../../../webmodule-common/other/ui/databinding/data-tracker';
import { DocumentSubType, ResourceType } from '../../../api/dealer-api-interface-project';
import { emptyGuid } from '../../../../webmodule-common/other/api/guid';
import { EventTemplate, Snippet } from '../../../../webmodule-common/other/ui/events';
import { FormInputAssistant } from '../../../../webmodule-common/other/ui/templateresult/form-input-assistant';
import { FranchiseePurchaseOrderContainerManager } from '../data/franchisee-purchase-order-container';
import { getBranchUserCache } from '../../cache-impl/dealer-branch-user-cache';
import { getPermanentProjectDocumentURL } from '../../cache-impl/project-permanent-document-lookup';
import { html } from 'lit';
import { isEmptyOrSpace } from '../../../../webmodule-common/other/ui/string-helper-functions';
import { PurchaseOrder, PurchaseOrderState } from '../../../api/dealer-api-interface-franchisee';
import {
  PurchaseOrderDetailView,
  PurchaseOrderDetailViewOptions
} from '../../../purchase-orders/views/purchase-order-detail-view';
import { TenantLoginPublicInfo } from '../../../api/dealer-api-interface-user';
import { tlang } from '../../../../webmodule-common/other/language/lang';
import { userDataStore } from '../../common/current-user-data-store';

@customElement('wm-franchiseepurchaseorderdetailview')
export class FranchiseePurchaseOrderDetailView extends PurchaseOrderDetailView {
  private readonly dataBinding: DataBinding;
  private readonly dataTracker: DataTracker;

  private readonly branchUserCache = getBranchUserCache();

  constructor(options: PurchaseOrderDetailViewOptions) {
    super(options);

    this.dataBinding = new DataBinding(
      this.ui,
      undefined,
      (input: string, internalId: string) => `purchase-order-${input}-${internalId}`
    );
    this.dataTracker = new DataTracker(this.dataBinding);

    const addField = (
      fieldName: string,
      propertyType?: FieldType,
      nullable?: boolean,
      editorFieldName?: string,
      data?: () => any
    ) => {
      this.dataTracker.addObjectBinding(
        data ?? (() => this.purchaseOrderManager.container.purchaseOrder),
        fieldName,
        editorFieldName ?? fieldName,
        propertyType ?? FieldType.string,
        nullable ?? false
      );
    };

    addField('title');

    addField('installationDate', FieldType.date, true);
    //addField("supplierSystemLastModifiedDate", FieldType.date, true);
    addField('supplierSystemDeliveryDate', FieldType.date, true);
    addField('supplierSystemClosedDate', FieldType.date, true);
    addField('description', FieldType.string, false);
    addField('supplierSystemStatus', FieldType.string, true);
    addField('reference', FieldType.string, false);
    addField('customPurchaseOrderNumber', FieldType.string, true);
    addField('supplierSystemShippingLeadTime', FieldType.int, true);
    addField('assignedToUserId', FieldType.string, false);
    this.dataTracker.addDynamic(
      'poTotal',
      FieldType.money,
      () =>
        this.purchaseOrderManager.purchaseOrder.calculatedNetTotal +
        this.purchaseOrderManager.purchaseOrder.calculatedAdjustmentTotal
    );
  }

  get purchaseOrder(): PurchaseOrder | null {
    return this.purchaseOrderManager.container.purchaseOrder;
  }

  private get supplierName(): string {
    return this.purchaseOrderManager.quoteManager.supplierName;
  }

  public override async afterConstruction(): Promise<void> {
    await super.afterConstruction();
    await this.branchUserCache.prefetch();
    await this.purchaseOrderManager.needsPurchaseOrder();
    await this.branchUserCache.addUsersToBranch(
      (this.purchaseOrderManager as FranchiseePurchaseOrderContainerManager).branchId,
      [this.purchaseOrder!.assignedToUserId, this.purchaseOrder!.creationUserId, this.purchaseOrder!.lastModifiedUserId]
    );
  }

  public override async prepareForSave(): Promise<void> {
    await this.purchaseOrderManager.needsPurchaseOrder();
    this.dataTracker.applyChangeToValue();
  }

  public override getValidationErrors(): string[] {
    const errors = super.getValidationErrors();

    if (
      this.purchaseOrderManager.container.purchaseOrder &&
      isEmptyOrSpace(this.purchaseOrderManager.container.purchaseOrder.title)
    ) {
      errors.push(tlang`Please add a %%purchase-order%% Title`);
    }

    return errors;
  }

  public buttonMenu(): Snippet {
    let src: string | null = null;

    //TODO - change this design. we dont want to rely on a State and a URL to create a button
    //we need to scan the attached project document links and find the resource
    //for this order if it exists and bind it via a method, then use its public url
    const waitforit = async () => {
      if (
        this.purchaseOrderManager.purchaseOrder.state == PurchaseOrderState.Issued ||
        this.purchaseOrderManager.purchaseOrder.state == PurchaseOrderState.Completed
      ) {
        src = await getPermanentProjectDocumentURL(
          cache().projectResourceLink.getLocalData(this.purchaseOrderManager.purchaseOrderId)?.projectId ?? emptyGuid,
          this.purchaseOrderManager.purchaseOrderId,
          ResourceType.PurchaseOrder,
          DocumentSubType.PurchaseOrderPDF,
          true
        );
      }

      return src != null ? html` <a class="btn btn-secondary" href="${src}">Download Report</a> ` : html``;
    };
    return html`${awaitableTemplate(waitforit)}`;
  }

  protected template(): EventTemplate {
    const forms = new FormInputAssistant(this.dataTracker, this.purchaseOrderManager.isReadOnly());

    const dealerName = userDataStore.franchisee.name;
    const dealerOrderNo = isEmptyOrSpace(dealerName) ? tlang`%%franchisee%% Order No.` : tlang`${dealerName} Order No.`;

    const branchId = (this.purchaseOrderManager as FranchiseePurchaseOrderContainerManager).branchId;
    const users = this.branchUserCache.getBranchUsers(branchId);
    const userToOption = (u: TenantLoginPublicInfo) => ({ text: u.friendlyName ?? '', value: u.id, disabled: false });

    return html`
      <form class="py-3 px-0 frm-purchase-order-details form-two-col">
        <div class="row">
          <div class="po-title-descr-col">
            ${forms.textRequired('title', tlang`Title`, 200)} ${forms.note('description', tlang`Description`, 6000)}
          </div>

          <div>
            ${forms.text('customPurchaseOrderNumber', dealerOrderNo)}
            ${forms.date('installationDate', tlang`Proposed Installation Date`, 'date')}
            ${forms.arraySelectReadonly('assignedToUserId', users, userToOption, tlang`Author`)}
            ${forms.textReadonly('reference', tlang`${this.supplierName} Reference`)}
            ${forms.textReadonly('supplierSystemStatus', tlang`${this.supplierName} Progress Status`)}
            ${forms.dateReadonly('supplierSystemDeliveryDate', tlang`${this.supplierName} Delivery Date`, 'date')}
            ${forms.dateReadonly('supplierSystemClosedDate', tlang`${this.supplierName} Closed Date`, 'date')}
            ${forms.intReadonly('supplierSystemShippingLeadTime', tlang`${this.supplierName} Shipping Lead`)}
          </div>
        </div>
      </form>

      <h2 class="section-header">${tlang`%%purchase-order%% Items`}</h2>
      <div>${this.table}</div>

      <form class="py-3 px-0 purchase-order-price-summary form-two-col">
        <div class="row">
          <div class=""></div>

          <div class="purchase-order-items-summary">
            <div class="purchase-order-items-summary-wrapper">
              <div class="row">
                <div class="order-price-summary">
                  <div class="row mb-2 align-items-center form-col-item">
                    <div class="order-price-summary-wrapper">${forms.moneyReadonly('poTotal', tlang`Net`)}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    `;
  }
}

/// <summary>
/// examples
///   QuoteItemType.Basic (reserved 1)
///     1 = Shipping
///     2 = Freehand
///     3 = SpecialItem
///   QuoteItemType.Provider
///        will have types based on the provider code.. ie CID_FRAM=7
/// </summary>

//properties for QuoteItemType on the quote item, when the provider is v6
export const quoteItemContentType = {
  shipping: 1,
  freehand: 2,
  specialItem: 3,
  dealerQuotePriceAdjustment: 4,
  ServerSideContentTypeOffset: 1000,
  Reserved_ServerSideShippingItem: 1000,
  ServerSideReservedMax: 1999,
  ServerSideUserReservedOffset: 2000,
  ServerSideUserReservedMax: 2999
};

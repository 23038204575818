import { emptyGuid } from '../../webmodule-common/other/api/guid';
import { sharedExecute } from '../../webmodule-common/other/common/helpers/callbacks';

export enum V6OfflineCause {
  Connection = 0,
  Upgrading = 1,
  VersionMismatch = 2,
  InvalidV6ClientCode = 3
}

export interface QuoteSupplier {
  //GUID which is from GlobalSupplier on license server
  supplierId: string;
  description: string;
  online: boolean;
  offlineCause?: V6OfflineCause;
}

export type QuoteSupplierEvent = (refreshList: boolean) => Promise<QuoteSupplier[]>;
let _callback: QuoteSupplierEvent = async (_refreshList: boolean) => [];
export function setQuoteSupplierProviderCallback(callback: QuoteSupplierEvent) {
  _callback = sharedExecute(callback);
}
export function isMultiSupplier(): boolean {
  return _isMulti;
}
let _isMulti = false;
let _primarySupplierId = emptyGuid;
export function primarySupplierId(): string {
  return _primarySupplierId;
}
export function singleSupplierId(): string | null {
  return _isMulti ? null : _primarySupplierId;
}

export async function getQuoteSuppliers(refreshList = false): Promise<QuoteSupplier[]> {
  const suppliers = await _callback(refreshList);
  _isMulti = suppliers.length > 1;
  _primarySupplierId = suppliers[0]?.supplierId;
  return suppliers;
}

import { FranchiseeQuoteDEView, FranchiseeQuoteView } from '../franchisee-quote-view';
import { ButtonCaptionProvider, QuoteDataEntryView } from '../../../../quotes/views/quote-view';
import { getApiFactory } from '../../../../api/api-injector';
import {
  AskConfirmation,
  confirmationButtons,
  ConfirmationButtonType
} from '../../../../../webmodule-common/other/ui/modal-confirmation';
import { tlang } from '../../../../../webmodule-common/other/language/lang';
import { fireQuickInformationToast } from '../../../../../webmodule-common/other/toast-away';
import { DealerReport } from '../../../../api/dealer-api-interface-clientagent';
import { v6Editors, v6SupportsVersion, v6VersionMap } from '../../../../v6config/v6config';
import { V6PickerFamilyButton } from '../../../../../webmodule-common/interop/webmodule-interop';
import { customElement } from 'lit/decorators.js';

@customElement('wm-franchiseev6quotedeview')
export class FranchiseeV6QuoteDEView extends FranchiseeQuoteDEView {
  protected clientAgentApi = getApiFactory().clientAgent();
  frameButtons: (V6PickerFamilyButton | null)[] | null = null;
  buildFrameButtons(): (ButtonCaptionProvider | null)[] {
    return this.frameButtons ?? super.buildFrameButtons();
  }

  public override async generateReportV2(dealerReport: DealerReport): Promise<boolean> {
    if (
      await AskConfirmation(
        tlang`This is not the final report. Continue?`,
        confirmationButtons[ConfirmationButtonType.yesNo]
      )
    ) {
      await this.clientAgentApi.generateReport({
        id: this.quote.id,
        dealerReport: dealerReport
      });

      fireQuickInformationToast(tlang`You will be emailed a link to the document when it is ready`);
    }

    return false;
  }

  public async afterConstruction(): Promise<void> {
    await super.afterConstruction();
    await this.quoteContainerManager.needsQuote();
    await this.quoteContainerManager.needsQuoteItems();
    if (v6SupportsVersion(v6VersionMap.hasMultipleFrameAdd)) {
      this.frameButtons = await v6Editors().getFramePickerButtons(this.quoteContainerManager.quote.supplierId);
    }
  }
}

@customElement('wm-franchiseev6quoteview')
export class FranchiseeV6QuoteView extends FranchiseeQuoteView {
  protected createView(): QuoteDataEntryView {
    return new FranchiseeV6QuoteDEView(this.options, this);
  }
}

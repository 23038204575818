// eslint-disable-next-line import/named
import {
  CurrencyTypeOption,
  FranchiseeConfiguration,
  InputUpdateFranchiseeConfiguration,
  TaxRate
} from '../../api/dealer-api-interface-franchisee';
import { customElement } from 'lit/decorators.js';
import { DataBinding } from '../../../webmodule-common/other/ui/databinding/databinding';
import { DataProvider, ProviderBackup } from '../../../webmodule-common/other/data-provider';
import {
  DataTracker,
  DynamicValueBinder,
  FieldType
} from '../../../webmodule-common/other/ui/databinding/data-tracker';
import { emptyGuid, newGuid } from '../../../webmodule-common/other/api/guid';
import { EventTemplate, Snippet } from '../../../webmodule-common/other/ui/events';
import { fireQuickSuccessToast } from '../../../webmodule-common/other/toast-away';
import { FormInputAssistant } from '../../../webmodule-common/other/ui/templateresult/form-input-assistant';
import { getCurrentUser } from '../../../webmodule-common/other/api/current-user';
import { html } from 'lit';
import { isAutoSaving } from '../../../webmodule-common/other/save-workflow';
import { isEmptyOrSpace } from '../../../webmodule-common/other/ui/string-helper-functions';
import { localDateTimeToServer } from '../../../webmodule-common/other/datetime-converter';
import { PageControlTabWithIndependantSaving } from '../../../webmodule-common/other/ui/data-entry-screen-base';
import { showDevelopmentError } from '../../../webmodule-common/other/development-error';
import { tlang } from '../../../webmodule-common/other/language/lang';
import { userSecurity } from '../../../webmodule-common/other/api/user-security';
import { getAssetCdnPath } from '../../../webmodule-common/other/common/assets';

@customElement('wm-franchiseeconfigurationview')
export class FranchiseeConfigurationView extends PageControlTabWithIndependantSaving {
  private backup: ProviderBackup<InputUpdateFranchiseeConfiguration>;

  private dataBinding: DataBinding;
  private dataTracker: DataTracker;

  private maxTaxRowCount: number;

  constructor(franchiseeConfiguration: DataProvider<InputUpdateFranchiseeConfiguration>) {
    super();
    this.pageFragment = 'configuration';
    this.backup = new ProviderBackup<InputUpdateFranchiseeConfiguration>(franchiseeConfiguration);

    this.dataBinding = new DataBinding(this.ui, this.elementId, input => {
      return `${input}-${this.elementId}`;
    });
    this.dataBinding.allowMissingElements = true; // skip taxes
    this.dataTracker = new DataTracker(this.dataBinding);

    this.maxTaxRowCount = this.taxRates.length;

    const addField = (
      fieldName: string,
      propertyType?: FieldType,
      nullable?: boolean,
      editorFieldName?: string,
      data?: () => any
    ) => {
      this.dataTracker.addObjectBinding(
        data ?? (() => this.backup.item.franchiseeConfiguration),
        fieldName,
        editorFieldName ?? fieldName,
        propertyType ?? FieldType.string,
        nullable ?? false
      );
    };

    addField('unitType', FieldType.int, false);
    addField('currencyTypeOption', FieldType.int, false);
    addField('allowNonTaxableItems', FieldType.boolean, false);

    for (let irate = 0; irate < this.maxTaxRowCount; irate++) this.addTaxRate(irate);

    this.dataTracker.eventChange = () => this.requestUpdate();
  }

  get isAdmin() {
    return userSecurity().isAdmin() || userSecurity().isSupplier();
  }

  get config(): FranchiseeConfiguration {
    return this.backup.item.franchiseeConfiguration;
  }

  get taxRates(): TaxRate[] {
    return this.backup.item.taxRates;
  }

  public isDataReadonly(): boolean {
    return !this.isAdmin;
  }

  addTaxRate(index: number) {
    const fieldName = `taxRate_name_${index}`;
    const fieldRate = `taxRate_rate_${index}`;
    this.dataTracker.addBinding(
      new DynamicValueBinder(
        FieldType.float,
        false,
        () => {
          if (index >= this.taxRates.length) return 0;
          return this.taxRates[index].rate;
        },
        value => {
          if (index >= this.taxRates.length) return;
          this.taxRates[index].rate = (value as number) ?? 0;
        },
        () => false
      ),
      fieldRate,
      fieldRate,
      FieldType.float,
      false
    );
    this.dataTracker.addBinding(
      new DynamicValueBinder(
        FieldType.string,
        false,
        () => {
          if (index >= this.taxRates.length) return '';
          return this.taxRates[index].name;
        },
        value => {
          if (index >= this.taxRates.length) return;
          this.taxRates[index].name = (value as string) ?? '';
        },
        () => false
      ),
      fieldName,
      fieldName,
      FieldType.string,
      false
    );
  }

  validateTaxRate(taxRate: TaxRate, index: number, errors: string[]) {
    if (isEmptyOrSpace(taxRate.name)) errors.push(tlang`Please enter a %%tax%% Name in position ${index + 1}`);

    if (taxRate.rate < 0) errors.push(tlang`Please provide a non negative %%tax%% Rate on "${taxRate.name}"`);
  }

  getValidationErrors(): string[] {
    const errors: string[] = [];

    if (!this.config) {
      errors.push('FranchiseeConfiguration is null');
      return errors;
    }

    const currencyTypeOption = this.config.currencyTypeOption;
    const quoteType = this.config.quoteType;

    if (!currencyTypeOption) errors.push(tlang`Please select a Currency`);

    if (!quoteType) errors.push(tlang`Please select a %%quote%% Type`);

    this.taxRates.forEach((rate, index) => this.validateTaxRate(rate, index, errors));
    return errors;
  }

  public async prepareForSave(): Promise<void> {
    //if (this.dataTracker.modified) this.dataTracker.applyChangeToValue();
  }

  public internalDataChanged(): boolean {
    return this.backup.changed;
  }

  async onEnter(): Promise<void> {
    this.backup.reset();
    this.requestUpdate();
  }

  public allowDeletePage(): boolean {
    return false;
  }

  protected async revertChanges(): Promise<boolean> {
    super.revertChanges();
    this.backup.reset();
    return true;
  }

  protected async internalSaveData(): Promise<boolean> {
    console.log('Saving Franchisee Configuration');
    if (!this.config) {
      await showDevelopmentError(`franchisee-configuration-view, franchiseeConfiguration is null`);
      return false;
    }

    const result = await this.backup.commitChanges();
    if (result?.franchiseeConfiguration) {
      if (!isAutoSaving()) fireQuickSuccessToast(tlang`Configuration saved`);
      return true;
    }
    return false;
  }

  protected getCaption(): Snippet {
    return tlang`Configuration`;
  }

  protected bodyTemplate(): EventTemplate {
    const forms = new FormInputAssistant(this.dataTracker, this.isDataReadonly());
    forms.immediateBindingUpdate = true;

    const addTaxEvent = () => {
      this.taxRates.push({
        dateCreated: localDateTimeToServer(new Date()),
        dateModified: localDateTimeToServer(new Date()),
        franchiseeId: this.config.id,
        id: newGuid(),
        modifiedUserId: getCurrentUser()?.id ?? emptyGuid,
        name: '',
        rate: 0,
        position: this.taxRates.length,
        recordVersion: ''
      });
      const currentTaxCount = this.taxRates.length;
      if (currentTaxCount > this.maxTaxRowCount) {
        this.addTaxRate(this.maxTaxRowCount);
        this.maxTaxRowCount = currentTaxCount;
      }
      this.requestUpdate();
    };

    const taxRowsTemplate = () => {
      const rowTemplate = (index: number) => {
        const fieldName = `taxRate_name_${index}`;
        const fieldRate = `taxRate_rate_${index}`;
        const removeTaxEvent = () => {
          this.taxRates.splice(index, 1);
          this.requestUpdate();
        };
        const deleteBtnTemplate = () =>
          index === 0
            ? html``
            : html` <webmodule-button size="small" variant="default" @click=${removeTaxEvent}>
                <img
                  slot="prefix"
                  class="icon action-delete"
                  src=${getAssetCdnPath('/assets/icons/bin.svg')}
                  alt="Delete icon"
                />
              </webmodule-button>`;

        return html` <div class="row tax-row">
          <div>${forms.textRequired(fieldName, tlang`%%tax%% Name`, 60)}</div>
          <div>
            <div class="row">
              <div class="col-12">${forms.floatRequired(fieldRate, tlang`%%tax%% Rate %`)}</div>
              <div class="col-1 tax-row-delete">${deleteBtnTemplate()}</div>
            </div>
          </div>
        </div>`;
      };

      return this.taxRates.map((_x, index) => rowTemplate(index));
    };

    const addTaxButtonTemplate = () => {
      return html` <div class="row">
        <div class="col-2 mb-3">
          <webmodule-button size="small" variant="primary" ?disabled=${this.isDataReadonly()} @click=${addTaxEvent}>
            ${tlang`Add %%tax%%`}
          </webmodule-button>
        </div>
      </div>`;
    };

    return html` <div>
      <form id="configurationSettingsForm" class="form-two-col">
        <h2>${tlang`System Defaults`}</h2>
        <div class="row">
          <div>${forms.enumPicker('currencyTypeOption', CurrencyTypeOption, tlang`Currency`)}</div>
        </div>
        <h2>${tlang`Financial Defaults`}</h2>
        ${taxRowsTemplate()} ${addTaxButtonTemplate()}
        <h2>${tlang`Non-taxable !!freehand!!`}</h2>
        <div class="row">
          <div>${forms.switch('allowNonTaxableItems', tlang`Allow non-taxable Items`)}</div>
        </div>
      </form>
    </div>`;
  }
}

import { BranchQuoteConversationView, ConversationActions } from './wm-branchquote-conversation';
import {
  BranchQuoteSupport,
  BranchQuoteSupportItemType,
  BranchQuoteSupportStatus,
  ViewConversationEntry
} from '../../api/dealer-api-interface-franchisee';
import { ConversationEntryUpdateEvent, CreateConversationEntry } from './wm-conversation-entry';
import { currentUserClaims } from '../../../webmodule-common/other/currentuser-claims';
import { customElement, property, query, state } from 'lit/decorators.js';
import { emptyGuid, newGuid } from '../../../webmodule-common/other/api/guid';
import { flagInSet } from '../../../webmodule-common/other/ui/string-helper-functions';
import { getApiFactory } from '../../api/api-injector';
import { getSupportStatusBadge } from './conversation-helper';
import { html, TemplateResult } from 'lit';
import { LitElementBase } from '../../../webmodule-common/other/litelement-base';
import { tlang } from '../../../webmodule-common/other/language/lang';

@customElement('wmview-branchquote-conversation-editor')
export class WebModuleBranchQuoteConversationEditor extends LitElementBase {
  @property() showHeader = true;
  @property() showCloseButton = true;
  isEditingDraft: any;
  @state() quoteTitle?: TemplateResult;
  @property()
  branchQuoteSupport?: BranchQuoteSupport;
  @query('#conversation')
  conversation?: BranchQuoteConversationView;
  @property() supplierName = '';
  protected claims = currentUserClaims();
  protected conversationEntryId: string = newGuid();
  protected quoteApi = getApiFactory().quote();
  @state()
  protected addNoteTemplate?: TemplateResult;
  @state()
  protected editNoteTemplate?: TemplateResult;
  protected franchiseapi = getApiFactory().franchisee();
  @state()
  protected conversationActions?: ConversationActions;

  constructor() {
    super();
  }

  get isEditorVisible() {
    return this.addNoteTemplate !== undefined || this.editNoteTemplate !== undefined;
  }

  get forceReadonly() {
    return this.isReadonly || this.ticketClosed;
  }

  public get hasChanged(): boolean {
    return this.conversation?.hasChanged ?? false;
  }

  get branchQuoteSupportId(): string {
    return this.branchQuoteSupport?.id ?? emptyGuid;
  }

  protected get ticketClosed() {
    return (
      !this.branchQuoteSupport ||
      // eslint-disable-next-line no-bitwise
      flagInSet(this.branchQuoteSupport.status, BranchQuoteSupportStatus.Cancelled | BranchQuoteSupportStatus.Resolved)
    );
  }

  public getValidationErrors(): string[] {
    return this.conversation?.getValidationErrors() ?? [];
  }

  public async save() {
    return (await this.conversation?.save()) ?? true;
  }

  public branchSupportActionsTemplate(): unknown {
    const a = this.conversationActions;
    if (!a) return html``;
    return html`${a.postToSupplier(true)}${a.reactivate(true)}${a.cancel(true)}${a.resolve(true)}`;
  }

  conversationNoteHeader(): unknown {
    let title = tlang``;
    if (this.isEditorVisible) {
      const editCommentHeader = this.isEditingDraft ? tlang`Edit Draft Comments` : tlang`Edit Comments`;
      title = this.addNoteTemplate !== undefined ? tlang`Add Comments` : editCommentHeader;
    }
    return html`<h2 class="main-header-style">${title}</h2>`;
  }

  conversationNoteTemplates(): unknown {
    if (this.addNoteTemplate !== undefined) return this.addNoteTemplate;
    if (this.editNoteTemplate !== undefined) return this.editNoteTemplate;
    return html``;
  }

  protected async firstUpdated() {
    if (this.conversation) {
      this.conversationActions = this.conversation.actionsTemplate();
      this.dispatchCustom('!ui-changed', { ui: this });
    }
  }

  protected eventNewConversationEntry = (e: CustomEvent<{ enabled: boolean; editor: TemplateResult }>) => {
    this.isEditingDraft = false;
    if (e.detail.enabled) {
      this.addNoteTemplate = e.detail.editor;
      this.editNoteTemplate = undefined;
    } else this.addNoteTemplate = undefined;
  };

  protected eventConversationSaved = async (
    _e: CustomEvent<{
      updateEvent: ConversationEntryUpdateEvent;
      showMessage: boolean;
      completionPromise: Promise<void>;
    }>
  ) => {
    this.log('conversation entry saved');
  };

  protected log(s) {
    console.log(s);
  }

  protected eventConversationCreated = async (
    _e: CustomEvent<{
      updateEvent: CreateConversationEntry;
      showMessage: boolean;
      completionPromise: Promise<void>;
    }>
  ) => {
    this.log('conversation entry created');
  };

  protected eventConversationChanged = async (
    e: CustomEvent<{
      branchQuoteSupport: BranchQuoteSupport;
    }>
  ) => {
    this.log('conversation support saved');
    this.branchQuoteSupport = e.detail.branchQuoteSupport;
    this.dispatchSavedEvent();
  };

  protected eventConversationChanging = async (
    e: CustomEvent<{
      branchQuoteSupport: BranchQuoteSupport;
      status: BranchQuoteSupportStatus;
      allowChange: Promise<boolean>;
      validations: string[];
    }>
  ) => {
    e.detail.validations.push(...this.getValidationErrors());
    this.log('conversation status changing');
  };

  protected eventEditConversationEntry = (
    e: CustomEvent<{
      enabled: boolean;
      editor: TemplateResult;
      entry: ViewConversationEntry;
    }>
  ) => {
    this.isEditingDraft = false;
    if (e.detail.enabled) {
      this.isEditingDraft = e.detail.entry.draft;
      this.editNoteTemplate = e.detail.editor;
      this.addNoteTemplate = undefined;
    } else this.editNoteTemplate = undefined;
  };

  protected render(): unknown {
    const hasBranchSupport = this.branchQuoteSupport !== undefined;
    const readonly =
      this.isReadonly ||
      !hasBranchSupport ||
      this.branchQuoteSupport?.type === BranchQuoteSupportItemType.EngineeredToOrder;
    const clost = this.ticketClosed;

    const alwaysShowEditor = !readonly && hasBranchSupport && !clost;
    const conversation = this.branchQuoteSupport
      ? html`
          <div class="border-top border-secondary mt-2 ">
            <wm-branchquoteconversationview
              .alwaysShowNewNoteEditor=${alwaysShowEditor}
              .readonly=${readonly}
              .showHeader=${false}
              .useExternalEditing=${true}
              id="conversation"
              .branchQuoteSupport=${this.branchQuoteSupport}
              .emptyNote=""
              .supplierName=${this.supplierName}
              ?sortAscending=${true}
              @wm-event-new-entry=${this.eventNewConversationEntry}
              @wm-event-edit-entry=${this.eventEditConversationEntry}
              @wm-event-conversation-saved=${this.eventConversationSaved}
              @wm-event-conversation-created=${this.eventConversationCreated}
              @wm-event-changed=${this.eventConversationChanged}
              @wm-event-changing=${this.eventConversationChanging}
            ></wm-branchquoteconversationview>
          </div>
        `
      : html``;

    const header = this.conversation?.getFullHeaderTemplate(true, false, true);
    const typeHeader =
      this.branchQuoteSupport?.type == BranchQuoteSupportItemType.EngineeredToOrder
        ? tlang`%%special-item%%`
        : tlang`%%support-request%%`;

    const mainHeader = this.showHeader
      ? html`
          <div class="main-header d-flex justify-content-between  border-bottom border-secondary mb-2">
            <h2>${typeHeader} ${getSupportStatusBadge(this.branchQuoteSupport?.status)}</h2>
            <div>${this.showCloseButton ? this.conversationActions?.close() : ''}</div>
          </div>
        `
      : html``;
    return html`
      <div class="support-ticket-editor-main form-two-col ">
        <div class="row">
          <div class="eto-left form-column">
            ${mainHeader}
            <div>
              <div class="support-ticket-editor-container conversation">
                <div class="row">${header}</div>
              </div>
            </div>
            <div class="conversation-note">${this.conversationNoteHeader()} ${this.conversationNoteTemplates()}</div>
          </div>
          <div class="eto-right form-column">
            <div class="conversation-header-block">
              <h2 class="main-header-style border-0">${tlang`Comments`}</h2>
              ${this.conversationActions?.attachmentsOnlySwitch()}
            </div>
            <div class="eto-edit-conversation">${conversation}</div>
          </div>
        </div>
      </div>
    `;
  }

  private dispatchSavedEvent() {
    this.dispatchCustom('saved', {
      branchQuoteSupport: this.branchQuoteSupport
    });
  }
}

import { DevelopmentError } from '../../../webmodule-common/other/development-error';
import { getAssetCdnPath } from '../../../webmodule-common/other/common/assets';
import { html, TemplateResult } from 'lit';
import { isEmptyOrSpace } from '../../../webmodule-common/other/ui/string-helper-functions';
import { lang, tlang } from '../../../webmodule-common/other/language/lang';
import {
  Quote,
  QuoteItem,
  QuoteItemType,
  QuoteState,
  QuoteType,
  ViewQuoteSummary
} from '../../api/dealer-api-interface-quote';
import { quoteItemContentType } from './default-quote-item-content-type';
import { supplierQuoteItemContentType } from './supplier-quote-item-content-type';

export const processQuoteItemState = {
  updating: 'updating',
  validating: 'validating',
  completed: 'completed',
  finalizing: 'finalizing',
  errors: 'errors'
};

export const getQuoteNumberSuffix = (q: ViewQuoteSummary | Quote | null, includeState = true) => {
  if (!q) return '';
  const suffix = isEmptyOrSpace(q.quoteSuffix) ? '    ' : `-${q.quoteSuffix.padStart(3, ' ')}`;

  return q.quoteNumber === 0
    ? includeState
      ? tlang`Draft`
      : ''
    : `${q.quoteNumber.toString().padStart(6, '0')}${suffix}`;
};

export const formatQuoteNumber = (
  quoteSuffix: string,
  quoteNumber: number,
  quoteType: QuoteType,
  includeState = true
) => {
  const suffix = isEmptyOrSpace(quoteSuffix) ? '    ' : `-${quoteSuffix.padStart(3, ' ')}`;

  const prefix =
    quoteType === QuoteType.Estimate
      ? tlang`%%estimate-abbrev%%`
      : quoteType === QuoteType.Quote
        ? tlang`%%quote-abbrev%%`
        : quoteType === QuoteType.Display
          ? tlang`%%display-sample-abbrev%%`
          : tlang`%%warranty-item-abbrev%%`;
  return quoteNumber === 0
    ? includeState
      ? tlang`Draft`
      : ''
    : `${prefix}: ${quoteNumber.toString().padStart(6, '0')}${suffix}`;
};

export const getQuoteNumberFormatted = (q: ViewQuoteSummary | Quote | null | undefined, includeState = true) => {
  if (!q) return '';

  return formatQuoteNumber(q.quoteSuffix, q.quoteNumber, q.quoteType, includeState);
};

export const internalExtendedListPriceField = 'extendedListPrice';
export const internalMultiplierField = 'multiplier';
export const internalPriceAdjustmentSupplierField = 'priceAdjustmentSupplier';
export const internalDealerCostField = 'dealerCost';
export const internalMarginField = 'margin';
export const internalPriceAdjustmentField = 'priceAdjustment';

export const internalQuoteItemFields = [
  internalExtendedListPriceField,
  internalMultiplierField,
  internalPriceAdjustmentSupplierField,
  internalDealerCostField,
  internalMarginField,
  internalPriceAdjustmentField
];

export function quoteTypeToString(value: QuoteType): string {
  switch (value) {
    case QuoteType.None:
      return '';
    case QuoteType.Estimate:
      return tlang`%%estimate%%`;
    case QuoteType.Display:
      return tlang`%%display-sample%%`;
    case QuoteType.Quote:
      return tlang`%%quote%%`;
    case QuoteType.Warranty:
      return tlang`%%warranty-item%%`;
    default:
      throw new DevelopmentError(`QuoteTypeToString: Unknown QuoteType value: ${value}`);
  }
}

export function getQuoteItemTab(title: string, itemPosition?: number): TemplateResult {
  if (itemPosition === undefined)
    return html` <div class="tab-caption-block"><span class="tab-caption">${title}</span></div>`;
  return html` <div class="tab-caption-block">
    <span class="tab-pos">#${itemPosition}</span><span class="tab-caption">${title}</span>
  </div>`;
}

export const getQuoteTitle = (q: ViewQuoteSummary | Quote | null | undefined, state?: QuoteState) => {
  if (!q) return html``;
  const quote = q as Quote;
  let quoteNumber = getQuoteNumberFormatted(quote, false);
  if (!isEmptyOrSpace(quoteNumber)) quoteNumber += ' ';
  return html`${quoteNumber}${quote.title} ${getQuoteStatus(state ?? quote.state, true)}`;
};

export const getQuoteTitleStr = (
  q: ViewQuoteSummary | Quote | null | undefined,
  state?: QuoteState,
  excludeState?: boolean
) => {
  if (!q) return html``;
  const quote = q as Quote;
  let quoteNumber = getQuoteNumberFormatted(quote, false);
  const stateTempl = excludeState ? '' : getQuoteStatusStr(state ?? quote.state);
  if (!isEmptyOrSpace(quoteNumber)) quoteNumber += ' ';
  return `${quoteNumber}${quote.title} ${stateTempl}`;
};
export const getQuoteStatusStr = (state: number) => {
  const value = QuoteState[state].toLowerCase();
  const text = `%%quote-state-${value}%%`;

  return lang(text);
};

export const getQuoteStatus = (state: number, asBadge = false, asRaw = false) => {
  if (!asBadge) return html`${QuoteState[state]}`;

  const value = QuoteState[state].toLowerCase();
  const text = `%%quote-state-${value}%%`;

  return asRaw
    ? `<span class="badge badge-${value}">${lang(text)}</span>`
    : html`<span class="badge badge-${value}">${lang(text)}</span>`;
};

export function htmlEncode(value: string): string {
  return value.replace(/&/g, '&amp;').replace(/</g, '&lt;').replace(/>/g, '&gt;').replace(/"/g, '&quot;');
}

export function quoteItemThumbSrc(item: QuoteItem): string {
  let src = '';
  if (isShipping(item)) {
    src = getAssetCdnPath('images/delivery.svg');
  } else if (isFreehand(item)) {
    src = getAssetCdnPath('images/freehand.svg');
  } else if (isSpecialItem(item)) {
    src = getAssetCdnPath('images/special-item.svg');
  } else if (isSSI(item)) {
    src = getAssetCdnPath('images/freehand-ssi.svg');
  } else if (isDealerQuotePriceAdjustment(item)) {
    src = getAssetCdnPath('images/dealer-quote-price-adjustment.svg');
  } else src = getAssetCdnPath('images/freehand.svg');
  return src;
}

export function isShipping(quoteItem: QuoteItem) {
  return (
    (quoteItem.itemType === QuoteItemType.Basic && quoteItem.quoteItemContentType === quoteItemContentType.shipping) ||
    isSSI(quoteItem, quoteItemContentType.Reserved_ServerSideShippingItem)
  );
}

export function isDefaultShipping(quoteItem: QuoteItem) {
  return quoteItem.itemType === QuoteItemType.Basic && quoteItem.quoteItemContentType === quoteItemContentType.shipping;
}

export function isFreehand(quoteItem: QuoteItem) {
  return quoteItem.itemType === QuoteItemType.Basic && quoteItem.quoteItemContentType === quoteItemContentType.freehand;
}

export function isAnyFreehand(quoteItem: QuoteItem) {
  return isFreehand(quoteItem) || isDealerQuotePriceAdjustment(quoteItem);
}

export function isDealerQuotePriceAdjustment(quoteItem: QuoteItem) {
  return (
    quoteItem.itemType === QuoteItemType.Basic &&
    quoteItem.quoteItemContentType === quoteItemContentType.dealerQuotePriceAdjustment
  );
}

export function isSpecialItem(quoteItem: QuoteItem) {
  return (
    quoteItem.itemType === QuoteItemType.Basic && quoteItem.quoteItemContentType === quoteItemContentType.specialItem
  );
}

export const serverSideContentTypeOffset = quoteItemContentType.ServerSideContentTypeOffset;
export const serverSideShippingItem = quoteItemContentType.Reserved_ServerSideShippingItem;
export const serverSideReservedMax = quoteItemContentType.ServerSideReservedMax;
export const serverSideUserReservedMax = quoteItemContentType.ServerSideUserReservedMax;

export function isContentTypeSSI(aType: number) {
  return aType >= serverSideContentTypeOffset && aType <= serverSideUserReservedMax;
}

export function isSSI(quoteItem?: QuoteItem | null, ofSpecificType?: number) {
  if (!quoteItem) return false;
  const valid =
    quoteItem.itemType === QuoteItemType.Basic &&
    quoteItem.quoteItemContentType >= serverSideContentTypeOffset &&
    quoteItem.quoteItemContentType <= serverSideUserReservedMax;
  return ofSpecificType ? valid && quoteItem.quoteItemContentType === ofSpecificType : valid;
}

export function isFrame(quoteItem: QuoteItem) {
  return (
    quoteItem.itemType === QuoteItemType.Provider &&
    quoteItem.quoteItemContentType === supplierQuoteItemContentType.CID_FRAM
  );
}

export function isSupplierItem(quoteItem: QuoteItem) {
  return isFrame(quoteItem) || isSpecialItem(quoteItem);
}

export function mustAppearOnPurchaseOrder(quoteItem: QuoteItem) {
  return !isAnyFreehand(quoteItem) && !isShipping(quoteItem);
}

export function canAppearOnSupplierOrder(quoteItem: QuoteItem) {
  //return isSupplierItem(quoteItem) || isSSI(quoteItem) || isShipping(quoteItem);
  return !isAnyFreehand(quoteItem);
}

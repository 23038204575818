import { ApiCommunications, NullPromise } from '../../../webmodule-common/interop/webmodule-interop';
import { cache } from './cache-registry';
import { DataCacheBase } from '../../../webmodule-common/cache/data-cache-base';
import { getApiFactory } from '../../api/api-injector';
import { isEmptyOrSpace } from '../../../webmodule-common/other/ui/string-helper-functions';
import { ItemReference } from '../../../webmodule-common/cache/definitions/cache-item-reference';
import {
  ProjectState,
  ResultGetProjectStateChangeReasonsByOwnerIds,
  ResultProjectStateChangeReasonsForState
} from '../../api/dealer-api-interface-project';
import { ProjectStateChangeReasonCacheData } from './cache-data';

export class ProjectStateChangeReasonCache extends DataCacheBase<ProjectStateChangeReasonCacheData> {
  private projectApi = getApiFactory().project();

  constructor(api: ApiCommunications) {
    super(api);
  }

  protected override async internalFetch(
    _requestIds: string[]
  ): NullPromise<ItemReference<ProjectStateChangeReasonCacheData>[]> {
    const result = await this.projectApi.getProjectStateChangeReasons({ projectStateReasonsOwnerIds: _requestIds });
    if (result) {
      return this.processInternalFetch(result);
    }
    return null;
  }

  private async processInternalFetch(
    result: ResultGetProjectStateChangeReasonsByOwnerIds
  ): NullPromise<ItemReference<ProjectStateChangeReasonCacheData>[]> {
    return result.projectReasons.map(vr => {
      const item: ItemReference<ProjectStateChangeReasonCacheData> = {
        id: vr.projectStateReasonOwnerId,
        displayValue: vr.projectStateReasonOwnerId,
        data: { projectStateChangeReasons: vr.projectReasons },
        onClick: null
      };
      return item;
    });
  }
}

/**
 * Gets the project state change reasons for a given project state and franchisee owner.
 * @param projectState The state of the project.
 * @param franchiseeId The Id of the owning franchisee.
 * @returns The array of project state change reasons, or null if none were found.
 */
export async function getProjectStateChangeReasonsForState(
  projectState: ProjectState,
  franchiseeId: string
): NullPromise<ResultProjectStateChangeReasonsForState> {
  if (isEmptyOrSpace(franchiseeId)) return null;

  const reasons = async () => {
    const results = await cache().projectStateReason.getData(franchiseeId);
    if (results) {
      const changeReasons = results.projectStateChangeReasons.find(vr => vr.category == projectState);
      if (changeReasons) {
        return changeReasons;
      }
    }
    return null;
  };
  const foundReason = await reasons();
  if (foundReason) {
    return foundReason;
  }
  return null;
}

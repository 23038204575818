import { asMarkdownTemplate } from '../../../webmodule-common/other/general/markdown';
import { getApiFactory } from '../../api/api-injector';
import { html, TemplateResult } from 'lit';
import { InputUpdateFranchiseeProperties } from '../../api/dealer-api-interface-franchisee';
import { LitBaseModal } from '../../../webmodule-common/other/ui/modal/modal-factory-lit';
import { lockUIandExecute } from '../../../webmodule-common/other/ui-lock';
import { QuoteSupplier } from '../../quotes/quote-service';
import { tlang } from '../../../webmodule-common/other/language/lang';

export class SupplierTermsAndConditionsModal extends LitBaseModal {
  supplier: QuoteSupplier;
  ok = false;
  tac: string;
  constructor(supplier: QuoteSupplier, tac: string) {
    super();
    this.supplier = supplier;
    this.tac = tac;
  }
  async canClose(): Promise<boolean> {
    return this.ok;
  }
  modalSize = 'modal-xl terms-of-use-modal';

  get isFooterVisible(): boolean {
    return true;
  }
  eventAccept = async (_e: Event) => {
    const input: InputUpdateFranchiseeProperties = {
      properties: {}
    };
    const key = `supplier-${this.supplier.supplierId}-tac`;
    input.properties[`supplier-${this.supplier.supplierId}-tac`] = 'true';
    await lockUIandExecute(async () => {
      const r = await getApiFactory().franchisee().updateFranchiseePropreties(input);
      if (r) this.ok = r.properties[key] === 'true';
      window.location.reload();
    });
  };
  title(): string | TemplateResult {
    return tlang`Terms and Conditions for ${this.supplier.description}`;
  }
  footerTemplate(): TemplateResult | null | undefined {
    return html` <webmodule-button size="small" variant="primary" @click=${this.eventAccept}
      >${tlang`Accept`}</webmodule-button
    >`;
  }
  bodyTemplate(): TemplateResult {
    const tacTemplate = asMarkdownTemplate(this.tac, true);
    return html`<div>
      <h3>${tlang`The following terms must be accepted to use this software`}</h3>
      ${tacTemplate}
    </div>`;
  }
}

import { money } from '../other/currency-formatter';

export interface IPricingStrategy {
  /**
   * Calculates the price with adjustment based on the provided base price, percentage, and optional adjustment value.
   * @param basePrice The base price.
   * @param percentage The percentage.
   * @param adjustment The optional adjustment value.
   * @returns The price with adjustment.
   */
  getPriceWithAdjustment(basePrice: number, percentage: number, adjustment?: number): number;
}

/**
 * The abstract class BasePricingStrategy is the base class for all pricing strategies.
 * It provides common functionality for calculating prices with adjustments.
 */
export abstract class BasePricingStrategy implements IPricingStrategy {
  protected static readonly PercentageDivider = 100;

  /**
   * Calculates the price with adjustment based on the provided base price, percentage and optional adjustment value.
   * @param basePrice The base price of the item.
   * @param percentage The percentage to adjust the price by.
   * @param adjustment Optional adjustment value to add to the calculated price.
   * @returns The price with adjustment.
   */
  public getPriceWithAdjustment(basePrice: number, percentage: number, adjustment?: number): number {
    return money(money(this.calculatePrice(basePrice, percentage)) + (adjustment ?? 0));
  }

  /**
   * Calculates the price with adjustment using the specified base price, percentage, and optional adjustment.
   * @param basePrice The base price of the item.
   * @param percentage The percentage to apply to the base price.
   * @returns The calculated price with adjustment.
   */
  protected abstract calculatePrice(basePrice: number, percentage: number): number;
}

/**
 * The MarginPricingStrategy class is a concrete implementation of the BasePricingStrategy class.
 * It calculates the price with a margin adjustment based on a given base price and percentage.
 * The margin adjustment is added to the calculated price.
 */
export class MarginPricingStrategy extends BasePricingStrategy {
  /**
   * Calculates the price.
   * @param basePrice The base price of the item.
   * @param percentage The percentage of adjustment to be applied to the base price.
   * @returns The calculated price of the item.
   */
  protected calculatePrice(basePrice: number, percentage: number): number {
    return (basePrice / (BasePricingStrategy.PercentageDivider - percentage)) * BasePricingStrategy.PercentageDivider;
  }
}

/**
 * The MarkupPricingStrategy class is a concrete implementation of the BasePricingStrategy class.
 * It calculates the price with a markup adjustment based on a given base price and percentage.
 * The markup adjustment is added to the calculated price.
 */
export class MarkupPricingStrategy extends BasePricingStrategy {
  /**
   * Calculates the price.
   * @param basePrice The base price of the item.
   * @param percentage The percentage adjustment to apply to the base price.
   * @returns The calculated price of the item.
   */
  protected calculatePrice(basePrice: number, percentage: number): number {
    return basePrice * (1 + percentage / BasePricingStrategy.PercentageDivider);
  }
}

export const pricingStrategies = {
  margin: new MarginPricingStrategy(),
  markup: new MarkupPricingStrategy()
};

import { html } from 'lit';
import { ModalPickerDialog, ModalPickerDialogOptions } from '../../../webmodule-common/other/ui/modal-picker-base';
import { NullPromise } from '../../../webmodule-common/interop/types/misc';
import { PaymentProfileApi } from '../../api/payment-profile-api';
import { PaymentProfileSummary } from '../../api/dealer-api-interface-franchisee';
import { PaymentProfileSummaryPickerBrowser } from './payment-profile-list-table';
import { singleSupplierId } from '../../quotes/quote-service';
import { tlang } from '../../../webmodule-common/other/language/lang';

export interface PaymentProfilePickerOptions extends ModalPickerDialogOptions<PaymentProfileSummary> {
  api: PaymentProfileApi;
  supplierId: string | null;
}

export async function PaymentProfilePicker(api: PaymentProfileApi): NullPromise<PaymentProfileSummary> {
  return new Promise<PaymentProfileSummary | null>((resolve, reject) => {
    const picker = new PaymentProfileBrowserPicker({
      onSelect: (selected: PaymentProfileSummary | null) => {
        resolve(selected);
      },
      // eslint-disable-next-line prefer-promise-reject-errors
      onCancel: () => reject(),
      title: tlang`Select a %%payment-profile%%`,
      api: api,
      supplierId: singleSupplierId()
    });
    picker.showModal();
  });
}

export class PaymentProfileBrowserPicker extends ModalPickerDialog<PaymentProfileSummary> {
  private comms: PaymentProfileSummaryPickerBrowser;

  constructor(options: PaymentProfilePickerOptions) {
    super(options);

    this.comms = this.paymentProfilePickerBrowserFactory(options);
  }

  protected paymentProfilePickerBrowserFactory(
    options: PaymentProfilePickerOptions
  ): PaymentProfileSummaryPickerBrowser {
    return new PaymentProfileSummaryPickerBrowser({
      title: () => options.title ?? '',
      onPaymentProfileSelected: paymentProfileSummary => this.select(paymentProfileSummary),
      supplierId: options.supplierId
    });
  }

  bodyTemplate() {
    return html`${this.comms}`;
  }

  footerTemplate() {
    return html` <webmodule-button size="small" variant="default" @click=${() => this.hideModal()}
      >${tlang`Close`}
    </webmodule-button>`;
  }
}

import { buttonsSaveCancel } from '../../../../webmodule-common/other/ui/modal-confirmation';
import { checkValidations } from '../../../../webmodule-common/other/ui/data-entry-screen-helpers';
import { clone, compare } from '../../../../webmodule-common/other/clone';
import { DataBinding } from '../../../../webmodule-common/other/ui/databinding/databinding';
import { DataTracker, FieldType } from '../../../../webmodule-common/other/ui/databinding/data-tracker';
import { DealerQuotePriceAdjustmentMethod } from '../../../api/dealer-api-interface-quote';
import { FormInputAssistant } from '../../../../webmodule-common/other/ui/templateresult/form-input-assistant';
import { html } from 'lit';
import { isDealerQuotePriceAdjustment } from '../../../quotes/data/quote-helper-functions';
import { LitBaseModal } from '../../../../webmodule-common/other/ui/modal/modal-factory-lit';
import { lockUIandExecute } from '../../../../webmodule-common/other/ui-lock';
import { noParalellExecutionAsync } from '../../../../webmodule-common/other/common/helpers/callbacks';
import { tlang } from '../../../../webmodule-common/other/language/lang';
import { userDataStore } from '../../common/current-user-data-store';
import type { FranchiseeQuoteContainerManager } from '../data/franchisee-quote-manager';
import type { PricingModel } from '../../../../webmodule-common/pricing/pricing-model';
import type { QuoteItem, QuoteItemPrice, QuotePrice } from '../../../api/dealer-api-interface-quote';
import type { TemplateResult } from 'lit';

export interface QuotePriceSummaryDataContainer {
  percentMarginOrMarkup: number;
  //non-taxable items total
  nonTaxableSubtotal: number;
  //taxable items total
  taxableSubtotal: number;
  //adjustment on subtotals
  priceAdjustment: number;
  //sum of all items and adjustments without tax
  subtotal: number;

  //tax on taxable items, excluding a price adjustment item tax
  calculatedTaxAmount: number;
  //tax from adjustment item
  adjustedTaxAmount: number;
  taxPercentage: number;
  taxId: string;
  //all totals and adjustments and tax
  calculatedGrossTotal: number;
  isTaxableAdjustment: boolean;

  adjustmentTitle: string;
  adjustmentDescription: string;
  adjustmentComment: string;
}

export function addQuotePriceSummaryToDataTracker(
  summary: () => QuotePriceSummaryDataContainer | undefined,
  dataTracker: DataTracker
) {
  const addField = (
    fieldName: string,
    propertyType?: FieldType,
    nullable?: boolean,
    editorFieldName?: string,
    data?: () => any
  ) => {
    dataTracker.addObjectBinding(
      data ?? summary,
      fieldName,
      editorFieldName ?? fieldName,
      propertyType ?? FieldType.string,
      nullable ?? false
    );
  };
  addField('percentMarginOrMarkup', FieldType.int, true);
  addField('calculatedTaxAmount', FieldType.money, true);
  addField('calculatedGrossTotal', FieldType.money, false);
  addField('priceAdjustment', FieldType.money, false);
  addField('subtotal', FieldType.money, false);
  addField('taxId', FieldType.string, true);
  addField('adjustmentTitle', FieldType.string, true);
  addField('adjustmentDescription', FieldType.string, true);
  addField('adjustmentComment', FieldType.string, true);
  addField('adjustedTaxAmount', FieldType.money, true);
  addField('taxableSubtotal', FieldType.money, true);
  addField('isTaxableAdjustment', FieldType.boolean, false);
  addField('nonTaxableSubtotal', FieldType.money, true);
  addField('itemTotal', FieldType.money, true);
}

export function convertQuoteToQuotePriceSummaryDataContainer(
  qcm: FranchiseeQuoteContainerManager,
  options?: {
    quotePrice?: QuotePrice;
    quoteItems?: QuoteItem[];
    quoteItemPrices?: QuoteItemPrice[];
    isTaxableAdjustment?: boolean;
    mergeTaxFields?: boolean;
    adjustmentTitle?: string;
    adjustmentDescription?: string;
    adjustmentComment?: string;
  }
) {
  const quoteItems = options?.quoteItems ?? qcm.quoteItems;
  const quoteItemPrices = options?.quoteItemPrices ?? qcm.quoteItemPrices;
  const quotePrice = options?.quotePrice ?? qcm.quotePrice;

  const adjustmentPriceItem = quoteItems?.find(x => isDealerQuotePriceAdjustment(x));
  const adjustmentPrice = quoteItemPrices?.find(x => x.id === adjustmentPriceItem?.id);

  //add quotePrice.priceAdjustment to the adjustment price for legacy quotes
  //this will become zero in new quotes, or transfer over as edited
  const lineItemPriceAdjustment = adjustmentPrice?.calculatedGrossSellingPrice ?? 0;
  const quotePricePriceAdjustment = quotePrice?.priceAdjustment;
  const isTaxableAdjustment = adjustmentPrice?.isTaxableItem ?? options?.isTaxableAdjustment ?? true;

  const adjustmentTax = isTaxableAdjustment ? (quotePrice?.taxPercentage / 100) * lineItemPriceAdjustment : 0;

  return {
    priceAdjustment: lineItemPriceAdjustment,
    calculatedGrossTotal: quotePrice?.calculatedGrossTotal,
    calculatedTaxAmount: quotePrice?.calculatedTaxAmount - (options?.mergeTaxFields ? 0 : adjustmentTax),
    percentMarginOrMarkup: quotePrice?.percentMarginOrMarkup,
    nonTaxableSubtotal:
      qcm.getNonTaxableQuoteItemPriceTotal(quoteItemPrices) - (isTaxableAdjustment ? 0 : lineItemPriceAdjustment),
    taxableSubtotal:
      qcm.getTaxableQuoteItemPriceTotal(quoteItemPrices) - (!isTaxableAdjustment ? 0 : lineItemPriceAdjustment),
    isTaxableAdjustment: isTaxableAdjustment,
    adjustedTaxAmount: options?.mergeTaxFields ? 0 : adjustmentTax,
    subtotal: qcm.getQuoteItemPriceTotal(quoteItemPrices) + quotePricePriceAdjustment,
    taxPercentage: quotePrice?.taxPercentage,
    taxId: userDataStore.taxRates.find(taxRate => taxRate.rate === quotePrice?.taxPercentage)?.id ?? '',
    adjustmentTitle: adjustmentPriceItem?.title ?? options?.adjustmentTitle ?? '',
    adjustmentDescription: adjustmentPriceItem?.description ?? options?.adjustmentDescription ?? '',
    adjustmentComment: adjustmentPriceItem?.comment ?? options?.adjustmentComment ?? ''
  };
}

export class QuoteDealerPriceAdjustmentDialog extends LitBaseModal {
  public modalResult = false;
  
  protected get modalSize() {
    return 'modal-lg';
  }

  protected runAdjustment = noParalellExecutionAsync(async (method: DealerQuotePriceAdjustmentMethod) => {
    if (!this.dataChanged) {
      return;
    }

    await this.runAdjustmentInternal(method);
  });
  private quoteManager: FranchiseeQuoteContainerManager;
  private pricingModel: PricingModel;
  private dataTracker: DataTracker;
  private data: QuotePriceSummaryDataContainer;
  private dataClone?: QuotePriceSummaryDataContainer;
  private dataBinding: DataBinding;
  private forms: FormInputAssistant;
  private isClientFacing: boolean;
  private quotePrice: QuotePrice;
  protected eventChangePriceModel = noParalellExecutionAsync(async () => {
    await lockUIandExecute(async () => {
      this.quotePrice.percentMarginOrMarkup = this.data.percentMarginOrMarkup;
      if (!(await this.checkPriceModel())) return;
      await this.runAdjustment(DealerQuotePriceAdjustmentMethod.PriceAdjustment);
    });
  });
  private quoteItemPrices: QuoteItemPrice[];
  private quoteItems: QuoteItem[];

  constructor(quoteManager: FranchiseeQuoteContainerManager, isClientFacing: boolean) {
    super();
    this.isClientFacing = isClientFacing;
    this.quoteManager = quoteManager;
    this.quotePrice = clone(quoteManager.quotePrice);
    this.quoteItemPrices = clone(quoteManager.quoteItemPrices);
    this.quoteItems = clone(quoteManager.quoteItems);
    this.pricingModel = quoteManager.pricingModel;

    this.dataBinding = new DataBinding(
      this.ui,
      undefined,
      (input: string, internalId: string) => `quoteprice-${input}-${internalId}`
    );
    this.dataTracker = new DataTracker(this.dataBinding);
    this.resetEditorValues();

    addQuotePriceSummaryToDataTracker(() => this.data, this.dataTracker);
    this.dataTracker.addDynamic(
      'budget',
      FieldType.money,
      () => this.quoteManager.quote.budget,
      () => {
        //readonlny
      }
    );
    this.forms = new FormInputAssistant(this.dataTracker, () => this.quoteManager.isReadonly(), true);
  }

  get isFooterVisible(): boolean {
    return true;
  }

  get dataChanged(): boolean {
    return !compare(this.data, this.dataClone);
  }

  private get allowNonTaxable() {
    return this.quoteManager.allowNonTaxableItems;
  }

  public static async Execute(
    quoteManager: FranchiseeQuoteContainerManager,
    isClientFacing: boolean
  ): Promise<boolean> {
    const dialog = new QuoteDealerPriceAdjustmentDialog(quoteManager, isClientFacing);
    await dialog.showModal();
    return dialog.modalResult;
  }

  async checkPriceModel() {
    const validations = this.pricingModel.getValueValidations(this.quotePrice.percentMarginOrMarkup);
    if (!(await checkValidations(validations.errors))) return false;
    return true;
  }

  title(): string | TemplateResult {
    //backwards compatible with ModalDialog
    return tlang`${'ref:quote-dealer-priceadjustment-title'}%%franchisee%% %%quote%% Level Price Adjustment`;
  }

  getTaxLabel(): string | undefined {
    const rate = userDataStore.taxRates.find(taxRate => taxRate.id === this.data.taxId);

    if (rate) {
      return tlang`${rate.name} ${rate.rate}%`;
    }

    return tlang`%%tax%% (${this.data.taxPercentage.toFixed(2)}%)`;
  }

  bodyTemplate(): TemplateResult {
    const forms = this.forms;

    const marginMarkupTemplate = !this.isClientFacing
      ? html` ${forms.int('percentMarginOrMarkup', this.quoteManager.getPricingModelLabel(), {
          min: this.pricingModel.editorMin,
          max: this.pricingModel.editorMax,
          toolTip: this.quoteManager.getPricingModelTooltip(),
          events: {
            blur: this.eventChangePriceModel,
            keyup: this.eventKeyupPriceModel
          }
        })}`
      : html``;

    const adjustmentTaxTempate = this.allowNonTaxable
      ? forms.multiEditRow({
          title: tlang`Adjustment Tax`,

          slotted: html`${forms.switch('isTaxableAdjustment', tlang`Taxable Price Adjustment`, {
            events: {
              change: this.eventChangeTotal
            }
          })}${forms.moneyReadonly('adjustedTaxAmount')}`
        })
      : html``;

    const taxSelectorTemplate = forms.arraySelect(
      'taxId',
      userDataStore.taxRates,
      taxRate => {
        return { value: taxRate.id, text: `${taxRate.name} ${taxRate.rate}%`, disabled: false };
      },
      {
        class: 'small',
        placeholder: tlang`Select Tax Rate`,

        events: { change: this.eventTaxRateChanged }
      }
    );

    const taxTemplate = forms.multiEditRow({
      title: tlang`Item Tax`,
      slotted: html`${taxSelectorTemplate}${forms.moneyReadonly('calculatedTaxAmount')}`
    });

    const itemsSubtotalTemplate = this.quoteManager.allowNonTaxableItems
      ? html`${forms.moneyReadonly('nonTaxableSubtotal', tlang`Zero Rated Items`)}
        ${forms.moneyReadonly('taxableSubtotal', tlang`Taxed Items`)}`
      : forms.moneyReadonly('taxableSubtotal', tlang`Items`);

    const noAdjTemplate =
      this.data.priceAdjustment === 0
        ? html`<span class="badge badge-cancelled ms-2">${tlang`No Adjustment Applied`}</span>`
        : html``;
    const adjustmentDetailsTemplate = html`
      <div class="dealer-quote-price-adjustment-details">
        <h4>${tlang`Adjustment Details`}${noAdjTemplate}</h4>
        ${forms.text1('adjustmentTitle', tlang`Title`, { placeholder: tlang`%%franchisee-quote-price-adjustment%%` })}
        ${forms.text1('adjustmentDescription', tlang`Description`, { placeholder: tlang`%%quote%% price adjustment` })}
        ${forms.noteEx('adjustmentComment', tlang`Comment`, {
          class: 'short',
          placeholder: tlang`Add comment if required`
        })}
      </div>
    `;
    return html` ${adjustmentDetailsTemplate}
      <div class="quote-items-dealer-adjustment-wrapper">
        <div>
          <h4>${tlang`%%quote%% Price`}</h4>
          ${marginMarkupTemplate} ${itemsSubtotalTemplate}
          ${forms.money('priceAdjustment', tlang`Adjustment`, {
            events: {
              blur: this.eventChangePriceAdjustment,
              keyup: this.eventKeyupPriceAdjustment
            }
          })}
          ${forms.money('subtotal', tlang`Items Total`, {
            events: {
              blur: this.eventChangeSubtotal,
              keyup: this.eventKeyupSubtotal
            }
          })}
        </div>
        <div class="price-adjustment-tax-wrapper">${adjustmentTaxTempate}${taxTemplate}</div>
        ${forms.money('calculatedGrossTotal', tlang`%%quote%% Total`, {
          events: {
            blur: this.eventChangeTotal,
            keyup: this.eventKeyupTotal
          }
        })}
      </div>`;
  }

  footerTemplate(): TemplateResult | null | undefined {
    const buttons = buttonsSaveCancel();

    return html` ${this.forms.buttonDefault(tlang`Reset`, {
      events: {
        click: () => {
          this.resetAllValues();
        }
      }
    })}
    ${this.createConfirmCancelButtons(buttons.ok, () => this.saveAndClose(), buttons.cancel)}`;
  }

  protected eventKeyupPriceAdjustment = (e: KeyboardEvent) => {
    if (e.code === 'Enter') {
      this.eventChangePriceAdjustment();
    }
  };

  protected eventChangePriceAdjustment = async () => {
    await lockUIandExecute(this.runAdjustment(DealerQuotePriceAdjustmentMethod.PriceAdjustment));
  };

  protected eventKeyupPriceModel = (e: KeyboardEvent) => {
    if (e.code === 'Enter') {
      this.eventChangePriceModel();
    }
  };

  protected eventKeyupSubtotal = (e: KeyboardEvent) => {
    if (e.code === 'Enter') {
      this.eventChangeSubtotal();
    }
  };

  protected eventChangeSubtotal = async () => {
    await lockUIandExecute(this.runAdjustment(DealerQuotePriceAdjustmentMethod.SubtotalAdjustment));
  };

  protected eventKeyupTotal = (e: KeyboardEvent) => {
    if (e.code === 'Enter') {
      this.eventChangeTotal();
    }
  };

  protected eventChangeTotal = async () => {
    await lockUIandExecute(this.runAdjustment(DealerQuotePriceAdjustmentMethod.TotalAdjustment));
  };

  protected async runAdjustmentInternal(method: DealerQuotePriceAdjustmentMethod, dryRun = true) {
    console.log('runAdjustmentInternal ');
    let value: number = 0;
    switch (method) {
      case DealerQuotePriceAdjustmentMethod.PriceAdjustment:
        value = this.data.priceAdjustment;
        break;
      case DealerQuotePriceAdjustmentMethod.SubtotalAdjustment:
        value = this.data.subtotal;
        break;
      case DealerQuotePriceAdjustmentMethod.TotalAdjustment:
        value = this.data.calculatedGrossTotal;
        break;
    }
    if (dryRun) this.quotePrice.taxPercentage = this.data.taxPercentage;
    else this.quoteManager.quotePrice.taxPercentage = this.data.taxPercentage;
    const result = await this.quoteManager.runPricingAdjustments({
      method: method,
      value: value,
      isTaxableAdjustment: this.data.isTaxableAdjustment,
      dryRun: dryRun,
      percentMarginOrMarkup: this.data.percentMarginOrMarkup,

      quotePrice: dryRun ? this.quotePrice : undefined,
      quoteItemPrices: dryRun ? this.quoteItemPrices : undefined,
      quoteItems: dryRun ? this.quoteItems : undefined,
      adjustmentTitle: this.data.adjustmentTitle,
      adjustmentComment: this.data.adjustmentComment,
      adjustmentDescription: this.data.adjustmentDescription
    });
    if (result) {
      this.quotePrice = result.quotePrice;
      this.quoteItemPrices = result.itemPrices;

      this.quoteItems = result.items;

      this.resetEditorValues(true);
    }
    return result;
  }

  private eventTaxRateChanged: (e: Event) => void = () => {
    this.data.taxPercentage = userDataStore.taxRates.find(taxRate => taxRate.id === this.data.taxId)?.rate ?? 0;
    this.eventChangePriceAdjustment();
  };

  private async saveAndClose() {
    if (!(await this.checkPriceModel())) return;
    await this.runAdjustmentInternal(DealerQuotePriceAdjustmentMethod.PriceAdjustment, false);
    this.modalResult = true;
    await this.hideModal();
  }

  private resetAllValues() {
    this.quotePrice = clone(this.quoteManager.quotePrice);
    this.quoteItemPrices = clone(this.quoteManager.quoteItemPrices);
    this.quoteItems = clone(this.quoteManager.quoteItems);
    this.resetEditorValues(true);
  }

  private resetEditorValues(updateEditors = false) {
    this.data = convertQuoteToQuotePriceSummaryDataContainer(this.quoteManager, {
      quotePrice: this.quotePrice,
      quoteItems: this.quoteItems,
      quoteItemPrices: this.quoteItemPrices,
      isTaxableAdjustment: this.data?.isTaxableAdjustment,
      adjustmentComment: this.data?.adjustmentComment,
      adjustmentDescription: this.data?.adjustmentDescription,
      adjustmentTitle: this.data?.adjustmentTitle
    });
    this.dataClone = clone(this.data);
    if (updateEditors) {
      this.dataTracker.resetEditorValue();
      this.requestUpdate();
    }
  }
}

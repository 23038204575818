import { ApiCommunications, NullPromise } from '../../../webmodule-common/interop/webmodule-interop';
import { DataCacheBase } from '../../../webmodule-common/cache/data-cache-base';
import { getPurchaseOrderNumberFormatted } from '../../purchase-orders/data/purchase-order-helper-functions';
import { ItemReference } from '../../../webmodule-common/cache/definitions/cache-item-reference';
import {
  PurchaseOrder,
  RequestGetPurchaseOrdersById,
  ResultGetPurchaseOrdersById
} from '../../api/dealer-api-interface-franchisee';
import { PurchaseOrderCacheData } from './cache-data';
import { SSEOrder } from '../../purchase-orders/data/sse-order';
import { WMEventSource } from '../../api/event-source';

export class PurchaseOrderCache extends DataCacheBase<PurchaseOrderCacheData> {
  constructor(api: ApiCommunications) {
    super(api);
    WMEventSource.getInstance().addEventListener(WMEventSource.order, (_data: unknown, _eventName: string) => {
      const data = _data as SSEOrder;
      this.flush([data.id]);
    });
  }

  protected async processInternalFetch(
    resultPurchaseOrder: ResultGetPurchaseOrdersById | null
  ): NullPromise<ItemReference<PurchaseOrderCacheData>[]> {
    if (!resultPurchaseOrder) return null;

    return resultPurchaseOrder.purchaseOrders.map(q => {
      const qvs: ItemReference<PurchaseOrderCacheData> = {
        id: q.id,
        displayValue: `${getPurchaseOrderNumberFormatted(q as any as PurchaseOrder)}`,
        data: {
          purchaseOrder: q
        },
        onClick: null
      };
      return qvs;
    });
    //
  }

  //override
  protected async internalFetch(requestIds: string[]): NullPromise<ItemReference<PurchaseOrderCacheData>[]> {
    const input: RequestGetPurchaseOrdersById = {
      purchaseOrderIds: requestIds
    };

    const purchaseOrderPromise = this.api.post<ResultGetPurchaseOrdersById>(
      `api/Franchisee/GetPurchaseOrdersById`,
      input
    );

    await Promise.all([purchaseOrderPromise]);

    const resultPO = await purchaseOrderPromise;
    return await this.processInternalFetch(resultPO);
  }
}

import { cache } from '../cache-impl/cache-registry';
import { isEmptyOrSpace } from '../../../webmodule-common/other/ui/string-helper-functions';

export async function getUserDisplayName(id: string) {
  const data = await cache().userProfile.get(id);
  if (!data) return '';
  return data.displayValue;
}

export async function getUserInformativeDisplayName(id: string) {
  const cacheItem = await cache().userProfile.get(id);
  if (!cacheItem) return '';
  if (!isEmptyOrSpace(cacheItem.data.emailAddress)) return `${cacheItem.displayValue} <${cacheItem.data.emailAddress}>`;
  return cacheItem.displayValue;
}

export async function getUserInfo(id: string) {
  const cacheItem = await cache().userProfile.get(id);
  if (!cacheItem) return undefined;

  return cacheItem.data;
}

// eslint-disable-next-line import/named
import { customElement } from 'lit/decorators.js';
import { EventBooleanAsync, EventTemplate, Snippet } from '../../../webmodule-common/other/ui/events';
import { html } from 'lit';
import { ProjectContainerManager } from '../data/project-container';
import { ViewBase } from '../../../webmodule-common/other/ui/view-base';

export interface ProjectDocumentViewOptions {
  projectManager: ProjectContainerManager;
  canClose: EventBooleanAsync;
}
@customElement('wm-projectdocumentview')
export class ProjectDocumentView extends ViewBase {
  protected readonly projectManager: ProjectContainerManager;
  protected canClose: EventBooleanAsync;

  constructor(options: ProjectDocumentViewOptions) {
    super();
    this.projectManager = options.projectManager;
    this.canClose = options.canClose;
  }

  public async invalidate(): Promise<void> {
    this.requestUpdate();
  }

  public async refreshData(): Promise<void> {
    throw new Error('No override provided for refresh data on resource view.');
  }

  public buttonMenu(): Snippet {
    return html``;
  }

  protected template(): EventTemplate {
    return html``;
  }
}

// eslint-disable-next-line import/named
import { constructAsync } from '../../../webmodule-common/other/async-constructor';
import { customElement } from 'lit/decorators.js';
import { DataCache } from '../../../webmodule-common/cache/data-cache';
import { DataCacheGeneric } from '../../../webmodule-common/cache/generic-data-cache';
import { DataEntryOwner } from '../../../webmodule-common/other/ui/DataEntryOwner';
import { DataEntryPageControlView, ModalViewBase } from '../../../webmodule-common/other/ui/data-entry-screen-base';
import { DevelopmentError } from '../../../webmodule-common/other/development-error';
import { EventGetReference, EventSnippet, EventTemplate } from '../../../webmodule-common/other/ui/events';
import { flagInSet } from '../../../webmodule-common/other/ui/string-helper-functions';
import { html } from 'lit';
import { isAutoSaving } from '../../../webmodule-common/other/save-workflow';
import {
  MenuIconOption,
  PageControl,
  PageControlOptions,
  PageManager
} from '../../../webmodule-common/other/ui/page-control';
import { NullPromise, Snippet } from '../../../webmodule-common/interop/webmodule-interop';
import {
  PurchaseOrder,
  PurchaseOrderState,
  PurchaseOrderStateChangeReason
} from '../../api/dealer-api-interface-franchisee';
import { PurchaseOrderCacheData } from '../../dealer-franchisee/cache-impl/cache-data';
import { PurchaseOrderContainer, PurchaseOrderContainerManager } from '../data/purchase-order-container';
import { PurchaseOrderDetailView, PurchaseOrderDetailViewOptions } from './purchase-order-detail-view';
import { SSEOrder } from '../data/sse-order';
import { tlang } from '../../../webmodule-common/other/language/lang';
import { WMEventSource } from '../../api/event-source';

export interface PurchaseOrderViewOptions {
  title: EventSnippet;
  purchaseOrderContainerManager: PurchaseOrderContainerManager;
  purchaseOrderOwnerId: EventGetReference;
  purchaseOrderCache: DataCacheGeneric;
}

export interface PurchaseOrderViewChildFactory {
  getDetailView(options: PurchaseOrderDetailViewOptions): PurchaseOrderDetailView;
}

export class PurchaseOrderViewChildFactoryImpl implements PurchaseOrderViewChildFactory {
  parent: PurchaseOrderDataEntryView;

  constructor(parent: PurchaseOrderDataEntryView) {
    this.parent = parent;
  }

  getDetailView(options: PurchaseOrderDetailViewOptions): PurchaseOrderDetailView {
    return new PurchaseOrderDetailView(options);
  }
}
@customElement('wm-purchaseorderdataentryview')
export class PurchaseOrderDataEntryView extends DataEntryPageControlView {
  protected eventTitle: EventSnippet;
  protected purchaseOrderOwnerId: EventGetReference;
  protected purchaseOrderContainerManager: PurchaseOrderContainerManager;
  protected detailView: PurchaseOrderDetailView;
  protected purchaseOrderCache: DataCache<PurchaseOrderCacheData>;
  protected purchaseOrderViewChildFactory: PurchaseOrderViewChildFactory;

  constructor(options: PurchaseOrderViewOptions, owner?: DataEntryOwner) {
    super(owner);
    this.purchaseOrderViewChildFactory = this.getPurchaseOrderViewChildFactory();
    this.purchaseOrderContainerManager = options.purchaseOrderContainerManager;
    this.eventTitle = options.title;
    this.purchaseOrderOwnerId = options.purchaseOrderOwnerId;
    this.purchaseOrderCache = options.purchaseOrderCache;

    this.detailView = this.purchaseOrderViewChildFactory.getDetailView({
      purchaseOrderCache: this.purchaseOrderCache,
      purchaseOrderManager: this.purchaseOrderContainerManager
    });

    this.purchaseOrderContainerManager.afterSave.push(async () => {
      this.detailView.requestUpdate();
      this.requestUpdate();
    });
  }

  private _eventOrder = (_data: unknown, _eventName: string) => {
    const data = _data as SSEOrder;
    if (data.id !== this.purchaseOrder.id) return;

    if (data.recordVersion !== this.purchaseOrder.recordVersion && this.purchaseOrderContainerManager.isReadOnly())
      this.ui.dispatchEvent(
        new CustomEvent('resource-changed', {
          bubbles: true,
          composed: true,
          detail: {}
        })
      );
  };

  async dispose() {
    WMEventSource.getInstance().removeEventListener(WMEventSource.order, this._eventOrder);
    await super.dispose();
  }

  public get purchaseOrder(): PurchaseOrder {
    if (!this.purchaseOrderContainer.purchaseOrder) throw new DevelopmentError('Purchase order container is null');
    return this.purchaseOrderContainer.purchaseOrder;
  }

  public get purchaseOrderContainer(): PurchaseOrderContainer {
    return this.purchaseOrderContainerManager.container;
  }
  public async afterConstruction() {
    await this.purchaseOrderContainerManager.needsPurchaseOrder();
    WMEventSource.getInstance().addEventListener(WMEventSource.order, this._eventOrder);
    await constructAsync(this.detailView);
    await this.detailView.requestUpdate();

    //this will create the page control
    await super.afterConstruction();
    this.buildActionMenu();
  }

  public createPageControl(): PageControl {
    // build static pages for each of the configured table settings
    const getInitialPageManagers = (): PageManager[] => {
      const pages: PageManager[] = [];
      pages.push(this.createDetailViewPage());
      return pages;
    };

    const options: PageControlOptions = {
      defaultTabIndex: 0,
      menuIcons: undefined,
      pageInitializer: () => getInitialPageManagers()
    };
    return new PageControl(options);
  }

  public override internalDataChanged(): boolean {
    return this.purchaseOrderContainerManager.changed();
  }

  public override getDataDictionaryName(): string {
    return tlang`%%purchase-order%%`;
  }

  public override isDataReadonly(): boolean {
    return this.purchaseOrderContainerManager.isReadOnly();
  }

  public getTitle(): Snippet {
    return this.eventTitle(this.purchaseOrderContainerManager.purchaseOrder);
  }

  public override async prepareForSave(): Promise<void> {
    if (this.isDataReadonly()) return;

    await this.detailView.prepareForSave();
  }

  protected getPurchaseOrderViewChildFactory(): PurchaseOrderViewChildFactory {
    return new PurchaseOrderViewChildFactoryImpl(this);
  }

  protected createDetailViewPage(): PageManager {
    return {
      caption: () => tlang`Details`,
      canClose: () => Promise.resolve(false),
      canLeave: async () => await this.allowPageSwitch(),
      hasDelete: () => false,
      onEnter: async () => {
        await this.detailView.invalidate();
      },
      content: () => {
        return this.detailView.ui;
      },
      buttonMenu: () => {
        return this.detailView.buttonMenu();
      },
      data: this.detailView
    };
  }

  protected bodyTemplate(): EventTemplate {
    this.buildActionMenu();
    return super.bodyTemplate();
  }

  protected override async internalSaveData(): Promise<boolean> {
    return this.purchaseOrderContainerManager.savePurchaseOrder(isAutoSaving());
  }

  protected override getValidationErrors(): string[] {
    return this.detailView.getValidationErrors();
  }

  protected async internalSetState(state: PurchaseOrderState): Promise<boolean> {
    const initialState = this.purchaseOrderContainerManager.purchaseOrder.state;
    this.purchaseOrderContainerManager.purchaseOrder.state = state;
    const saved = await this.performAutoSave();
    if (!saved) {
      this.purchaseOrderContainerManager.purchaseOrder.state = initialState;
    }
    return saved;
  }

  private buildActionMenu() {
    const menuIcons: MenuIconOption[] = [];
    if (!this._pageControl) return;

    const cancel = {
      caption: () => tlang`Cancel`,
      event: async () => {
        return await this.setState(PurchaseOrderState.Cancelled);
      },
      classList: 'btn btn-dark'
    };
    const issue: MenuIconOption = {
      caption: () => tlang`Issue`,
      disabled: !this.canIssue(),
      event: async () => {
        return await this.setState(PurchaseOrderState.IssuedPending);
      }
    };
    const complete = {
      caption: () => tlang`Complete`,
      event: async () => {
        return await this.setState(PurchaseOrderState.Completed);
      }
    };

    const save = {
      event: async () => {
        if (this.isDataReadonly()) return false;

        return await this.performAutoSave();
      },
      caption: () => tlang`Save`
    };
    const validateOrder = {
      event: async () => {
        if (this.isDataReadonly()) return false;

        try {
          await this.performQuoteValidation();
        } finally {
          await this.detailView.refreshData();
          this.dispatchUiChanged();
        }
        return true;
      },
      caption: () => tlang`Validate`
    };

    const status: MenuIconOption = {
      caption: () => tlang`Status`,
      childEvents: []
    };
    const pushValidStatusMenu = (...args) => {
      status.childEvents = args.filter(x => !x.disabled) ?? [];
      if (status.childEvents?.length > 0 && !this.isLockedFromUse) menuIcons.push(status);
    };
    switch (this.purchaseOrderContainerManager.purchaseOrder.state) {
      case PurchaseOrderState.Draft:
        pushValidStatusMenu(issue, cancel);
        break;
      case PurchaseOrderState.Issued:
        pushValidStatusMenu(complete, cancel);
        break;
    }

    if (!isAutoSaving() && !this.isLockedFromUse) menuIcons.push(save);
    if (flagInSet(this.purchaseOrder.state, PurchaseOrderState.Draft) && !this.canIssue() && !this.isLockedFromUse)
      menuIcons.push(validateOrder);
    this.pageControl.setMenuIcons(menuIcons);
  }
  async performQuoteValidation(): Promise<boolean> {
    return true;
  }
  canIssue(): boolean | undefined {
    return true;
  }

  protected get isLockedFromUse(): boolean {
    return this.purchaseOrderContainerManager.isLockedFromUse();
  }

  private async setState(state: PurchaseOrderState): Promise<boolean> {
    await this.prepareForSave();
    const result = this.internalSetState(state);
    this.requestUpdateAndPropagate();
    return result;
  }

  protected async getPurchaseOrderStateReasons(
    _purchaseOrderState: PurchaseOrderState
  ): NullPromise<PurchaseOrderStateChangeReason[]> {
    throw new Error('please overrride this method');
  }
}

@customElement('wm-purchaseorderview')
export class PurchaseOrderView extends ModalViewBase {
  view: PurchaseOrderDataEntryView | null = null;
  options: PurchaseOrderViewOptions;

  constructor(options: PurchaseOrderViewOptions) {
    super();
    this.options = options;
  }

  public override async afterConstruction(): Promise<void> {
    this.view = await constructAsync(this.createView());
  }

  override async canClose(): Promise<boolean> {
    return (await this.view?.canClose()) ?? true;
  }

  protected override getTitle(): Snippet {
    return this.view?.getTitle() ?? '';
  }

  protected get modalSize() {
    return 'modal-fullscreen';
  }

  protected override renderFooterTemplate(): boolean {
    return false;
  }

  protected createView(): PurchaseOrderDataEntryView {
    return new PurchaseOrderDataEntryView(this.options, this);
  }

  protected bodyTemplate(): EventTemplate {
    return html`${this.view?.ui}`;
  }
}

// eslint-disable-next-line import/named
import { customElement } from 'lit/decorators.js';
import { DataBinding } from '../../../webmodule-common/other/ui/databinding/databinding';
import {
  DataTracker,
  DynamicValueBinder,
  FieldType
} from '../../../webmodule-common/other/ui/databinding/data-tracker';
import { emptyGuid, LitTableWrapper } from '../../../webmodule-common/other/webmodule-common';
import { EventTemplate, Snippet } from '../../../webmodule-common/other/ui/events';
import { fireQuickSuccessToast } from '../../../webmodule-common/other/toast-away';
import { FormInputAssistant } from '../../../webmodule-common/other/ui/templateresult/form-input-assistant';
import { html } from 'lit';
import { isAutoSaving } from '../../../webmodule-common/other/save-workflow';
import { PageControlTabWithIndependantSaving } from '../../../webmodule-common/other/ui/data-entry-screen-base';
import { RequestPage, ResultPaginated } from '../../../webmodule-common/other/ui/RequestPage';
import { StockItem } from '../../api/dealer-api-interface-franchisee';
import { StockManager } from '../data/stock-manager';
import { supplierItemContentTypeDisplay } from '../../quotes/data/supplier-quote-item-content-type';
import { tlang } from '../../../webmodule-common/other/language/lang';
import {
  WebmoduleBlurEvent,
  WebmoduleInput,
  WebmoduleInputMoney,
  WebModuleLitTable,
  WebModuleLitTableColumnDef
} from '../../../webmodule-common/components/src/webmodule-components';

interface FranchiseeStockOptions {
  stockManager: StockManager;
}

interface FranchiseeStockTableOptions extends FranchiseeStockOptions {
  formInputAssistant?: FormInputAssistant;
}

@customElement('wm-franchiseestocktable')
export class FranchiseeStockTable extends LitTableWrapper<StockItem> {
  stockManager: StockManager;
  formInputAssistant?: FormInputAssistant;

  filter?: string;

  constructor(options?: FranchiseeStockTableOptions) {
    super();

    const resolveOptions: FranchiseeStockTableOptions = {
      stockManager: new StockManager(null),
      formInputAssistant: undefined,
      ...options
    };

    this.stockManager = resolveOptions.stockManager;
    this.formInputAssistant = resolveOptions.formInputAssistant;
  }

  getDefaultSortAsc(): boolean {
    return false;
  }

  enableFiltering(): boolean {
    return true;
  }

  updateFilter(_searchTerm: string | null) {
    if (_searchTerm) this.filter = _searchTerm;
    else {
      this.filter = undefined;
      this.stockManager.refreshItemsFlag = true;
    }
  }

  override async getRowsFromServer(_request: RequestPage): Promise<ResultPaginated<StockItem>> {
    await this.stockManager.needsStockItems(this.filter);

    const items = this.stockManager.items;

    if (!items)
      return {
        count: 0,
        pageCount: 0,
        pageIndex: 0,
        pageSize: 2000,
        results: []
      };

    const results = this.convertItems(
      items?.sort((a, b) => {
        return a.itemContentType === b.itemContentType ? 0 : a.itemContentType > b.itemContentType ? 1 : -1;
      })
    );

    return {
      count: results.length,
      pageCount: 1,
      pageIndex: 0,
      pageSize: results.length,
      results: results
    };
  }

  convertItems(stockItems: StockItem[]): StockItem[] {
    const results: StockItem[] = [];

    let lastItemContentType = -1;
    for (const item of stockItems) {
      if (lastItemContentType !== item.itemContentType) {
        lastItemContentType = item.itemContentType;
        results.push({
          code: supplierItemContentTypeDisplay(lastItemContentType, true, true),
          dateCreated: Date.now().toLocaleString(),
          description: '',
          id: emptyGuid,
          itemContentType: -1,
          recordVersion: '',
          singleUnitCost: 0,
          stockCategoryId: ''
        });
      }
      results.push(item);
    }
    return results;
  }

  getColumns(): WebModuleLitTableColumnDef[] {
    const cols: WebModuleLitTableColumnDef[] = [];
    cols.push({
      title: 'Code',
      fieldName: 'code',
      classes: 'stock-item-code no-pseudo',
      displayValue: (_table: WebModuleLitTable, row: StockItem) => {
        return row.code;
      }
    });

    cols.push({
      title: 'Description',
      fieldName: 'description',
      classes: 'stock-item-description no-pseudo',
      displayValue: (_table: WebModuleLitTable, row: StockItem) => {
        if (row.itemContentType === -1) return html``;

        const field = `stock_description_${row.id}`;
        return this.formInputAssistant ? this.formInputAssistant?.text1(field, undefined, undefined) : html``;
      }
    });

    cols.push({
      title: 'Cost Per Unit',
      fieldName: 'singleUnitCost',
      classes: 'stock-item-singleUnitCost no-pseudo',
      displayValue: (_table: WebModuleLitTable, row: StockItem) => {
        if (row.itemContentType === -1) return html``;

        const field = `stock_unit_cost_${row.id}`;
        return this.formInputAssistant ? this.formInputAssistant?.money(field, undefined, undefined) : html``;
      }
    });

    return cols;
  }

  public getItemRowClass(item: StockItem): string {
    return item.itemContentType === -1 ? 'stock-item-subheader' : '';
  }

  protected enableTableClass(): string {
    return 'franchisee-stock-table';
  }
}

@customElement('wm-franchiseestockview')
export class FranchiseeStockView extends PageControlTabWithIndependantSaving {
  public ShowTab = false;
  protected table: FranchiseeStockTable;
  private stockManager: StockManager;
  private readonly dataBinding: DataBinding;
  private readonly dataTracker: DataTracker;

  constructor(options?: FranchiseeStockOptions) {
    super();
    this.pageFragment = 'stock';
    const resolveOptions: Required<FranchiseeStockOptions> = {
      stockManager: new StockManager(null),
      ...options
    };

    this.stockManager = resolveOptions.stockManager;

    this.dataBinding = new DataBinding(this.ui, this.elementId, input => {
      return `${input}`;
    });

    this.dataBinding.allowMissingElements = true;
    this.dataTracker = new DataTracker(this.dataBinding);

    this.table = this.stockTableFactory({
      formInputAssistant: new FormInputAssistant(this.dataTracker),
      ...resolveOptions
    });
  }

  override async afterConstruction(): Promise<void> {
    await this.stockManager.needsStockItems();

    this.ShowTab = this.stockManager.showTab;

    if (this.stockManager.items) {
      for (const item of this.stockManager.items) {
        this.addStockBinding(item.id);
      }
    }
  }

  getValidationErrors(): string[] {
    const errors: string[] = [];

    if (!this.stockManager.reportValidation()) {
      this.stockManager.getValidation().map(x => errors.push(`Code (${x.code}) - ${x.message}`));
    }

    return errors;
  }

  public async prepareForSave(): Promise<void> {
    if (this.dataTracker.modified) this.dataTracker.applyChangeToValue();
  }

  public internalDataChanged(): boolean {
    return this.stockManager.changed();
  }

  async onEnter(): Promise<void> {
    this.requestUpdate();
    await this.table.refreshData();
  }

  public allowDeletePage(): boolean {
    return false;
  }

  protected async internalSaveData(): Promise<boolean> {
    console.log('Saving Franchisee Stock');

    const result = await this.stockManager.saveStockItems();

    if (!result) return false;

    if (!isAutoSaving()) fireQuickSuccessToast(tlang`!!stock!! saved`);

    return true;
  }

  protected getCaption(): Snippet {
    return tlang`!!stock!!`;
  }

  protected bodyTemplate(): EventTemplate {
    return html` <div>
      <form id="stockForm" class="form-one-col">
        <h2>${tlang`!!stock!!`}</h2>
        <div class="row" @webmodule-blur="${(e: WebmoduleBlurEvent) => this.editVal(e)}">${this.table}</div>
      </form>
    </div>`;
  }

  protected stockTableFactory(options?: FranchiseeStockTableOptions) {
    return new FranchiseeStockTable(options);
  }

  private editVal(e: WebmoduleBlurEvent) {
    const target = e.target as WebmoduleInput | WebmoduleInputMoney | undefined;

    if (target) this.dataTracker.setEditorValue(target.id, target.value);
  }

  // stock_description

  private addStockBinding(itemId: string) {
    const fieldSingleUnitCost = `stock_unit_cost_${itemId}`;
    const fielddescription = `stock_description_${itemId}`;

    this.dataTracker.addBinding(
      new DynamicValueBinder(
        FieldType.money,
        true,
        () => {
          const si = this.stockManager.getStockItem(itemId);

          if (!si) return null;

          return si.singleUnitCost == null ? null : (si.singleUnitCost as number);
        },
        value => {
          this.stockManager.updateStockItem(itemId, undefined, value as number);
        },
        () => false
      ),
      fieldSingleUnitCost,
      fieldSingleUnitCost,
      FieldType.money,
      true
    );

    this.dataTracker.addBinding(
      new DynamicValueBinder(
        FieldType.string,
        true,
        () => {
          const si = this.stockManager.getStockItem(itemId);

          if (!si) return null;

          return si.description == null ? null : (si.description as string);
        },
        value => {
          this.stockManager.updateStockItem(itemId, value as string, undefined);
        },
        () => false
      ),
      fielddescription,
      fielddescription,
      FieldType.string,
      false
    );
  }
}

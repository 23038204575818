import { customElement } from 'lit/decorators.js';
import { NullPromise } from '../../../../webmodule-common/interop/webmodule-interop';
import { PaymentProfileApi } from '../../../api/payment-profile-api';
import {
  PaymentProfileBrowserPicker,
  PaymentProfilePickerOptions
} from '../../../franchisee/view/payment-profile-picker';
import { PaymentProfileSummary } from '../../../api/dealer-api-interface-franchisee';
import {
  PaymentProfileSummaryPickerBrowser,
  PaymentProfileSummaryPickerOptions
} from '../../../franchisee/view/payment-profile-list-table';
import { tlang } from '../../../../webmodule-common/other/language/lang';

interface FranchiseePaymentProfilePickerOptions extends PaymentProfilePickerOptions {
  supplierId: string;
}

export async function FranchiseePaymentProfilePicker(
  api: PaymentProfileApi,
  supplierId: string
): NullPromise<PaymentProfileSummary> {
  return new Promise<PaymentProfileSummary | null>((resolve, reject) => {
    const picker = new FranchiseePaymentProfileBrowserPicker({
      onSelect: (selected: PaymentProfileSummary | null) => {
        resolve(selected);
      },
      // eslint-disable-next-line prefer-promise-reject-errors
      onCancel: () => reject(),
      title: tlang`Select a %%payment-profile%%`,
      api: api,
      supplierId: supplierId
    });
    picker.showModal();
  });
}

export class FranchiseePaymentProfileBrowserPicker extends PaymentProfileBrowserPicker {
  constructor(options: FranchiseePaymentProfilePickerOptions) {
    super(options);
  }

  protected paymentProfilePickerBrowserFactory(
    options: FranchiseePaymentProfilePickerOptions
  ): PaymentProfileSummaryPickerBrowser {
    return new FranchiseePaymentProfileSummaryPickerBrowser({
      title: () => options.title ?? '',
      onPaymentProfileSelected: paymentProfileSummary => this.select(paymentProfileSummary),
      supplierId: options.supplierId
    });
  }
}

@customElement('wm-franchiseepaymentprofilesummarypickerbrowser')
export class FranchiseePaymentProfileSummaryPickerBrowser extends PaymentProfileSummaryPickerBrowser {
  constructor(options: PaymentProfileSummaryPickerOptions) {
    super(options);
  }
}

import { ApiCommunications, NullPromise } from '../../../webmodule-common/interop/webmodule-interop';
import { cache } from './cache-registry';
import { DataCacheBase } from '../../../webmodule-common/cache/data-cache-base';
import { getApiFactory } from '../../api/api-injector';
import { isEmptyOrSpace } from '../../../webmodule-common/other/ui/string-helper-functions';
import { ItemReference } from '../../../webmodule-common/cache/definitions/cache-item-reference';
import {
  QuoteState,
  ResultGetQuoteStateChangeReasonsByOwnerIds,
  ResultQuoteStateChangeReasonsForState
} from '../../api/dealer-api-interface-quote';
import { QuoteStateChangeReasonCacheData } from './cache-data';

export class QuoteStateChangeReasonCache extends DataCacheBase<QuoteStateChangeReasonCacheData> {
  private quoteApi = getApiFactory().quote();

  constructor(api: ApiCommunications) {
    super(api);
  }

  protected override async internalFetch(
    _requestIds: string[]
  ): NullPromise<ItemReference<QuoteStateChangeReasonCacheData>[]> {
    const result = await this.quoteApi.getQuoteStateChangeReasons({ quoteStateReasonsOwnerIds: _requestIds });
    if (result) {
      return this.processInternalFetch(result);
    }
    return null;
  }

  private async processInternalFetch(
    result: ResultGetQuoteStateChangeReasonsByOwnerIds
  ): NullPromise<ItemReference<QuoteStateChangeReasonCacheData>[]> {
    return result.quoteReasons.map(vr => {
      const item: ItemReference<QuoteStateChangeReasonCacheData> = {
        id: vr.quoteStateReasonOwnerId,
        displayValue: vr.quoteStateReasonOwnerId,
        data: { quoteStateChangeReasons: vr.quoteReasons },
        onClick: null
      };
      return item;
    });
  }
}

export async function getQuoteStateChangeReasonsForState(
  quoteState: QuoteState,
  franchiseeId: string
): NullPromise<ResultQuoteStateChangeReasonsForState> {
  if (isEmptyOrSpace(franchiseeId)) return null;

  const reasons = async () => {
    const results = await cache().quoteStateReason.getData(franchiseeId);
    if (results) {
      const changeReasons = results.quoteStateChangeReasons.find(vr => vr.category == quoteState);
      if (changeReasons) {
        return changeReasons;
      }
    }
    return null;
  };
  const foundReason = await reasons();
  if (foundReason) {
    return foundReason;
  }
  return null;
}

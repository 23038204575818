import { Branch, Franchisee, FranchiseeConfiguration, TaxRate } from '../../api/dealer-api-interface-franchisee';
import { DevelopmentError } from '../../../webmodule-common/other/development-error';
import { NullPromise } from '../../../webmodule-common/interop/webmodule-interop';

export interface FranchiseeConfigData {
  franchiseeConfiguration: FranchiseeConfiguration;
  taxRates: TaxRate[];
}
export interface IFranchiseeDataAdaptor {
  getFranchisee: () => Franchisee;
  getFranchiseeConfig: () => FranchiseeConfigData;
  setFranchisee: (franchisee: Franchisee) => NullPromise<Franchisee>;
  setFranchiseeConfig: (franchiseeConfig: FranchiseeConfigData) => NullPromise<FranchiseeConfigData>;
  getUserBranches: () => Branch[];
  getAllBranches: () => Promise<Branch[]>;
  setBranch: (branch: Branch) => Promise<Branch | null>;
}

let _adaptor: IFranchiseeDataAdaptor | undefined;
export function setFranchiseeDataAdaptor(adaptor: IFranchiseeDataAdaptor) {
  _adaptor = adaptor;
}

export function franchiseeDataAdaptor(): IFranchiseeDataAdaptor {
  if (!_adaptor) throw new DevelopmentError('IFranchiseeDataAdaptor is not assigned');
  return _adaptor;
}

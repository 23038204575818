import { checkValidations } from '../../../webmodule-common/other/ui/data-entry-screen-helpers';
import { clone, compare } from '../../../webmodule-common/other/clone';
import { currentUserClaims } from '../../../webmodule-common/other/currentuser-claims';
import { customElement } from 'lit/decorators.js';
import { DataBinding } from '../../../webmodule-common/other/ui/databinding/databinding';
import {
  DataTracker,
  DynamicValueBinder,
  EventValue,
  EventValueGetter,
  EventValueSetter,
  FieldType
} from '../../../webmodule-common/other/ui/databinding/data-tracker';
import { EventTemplate, Snippet } from '../../../webmodule-common/other/ui/events';
import { FormInputAssistant } from '../../../webmodule-common/other/ui/templateresult/form-input-assistant';
import { html, TemplateResult } from 'lit';
import { isAutoSaving } from '../../../webmodule-common/other/save-workflow';
import { ModalDialog } from '../../../webmodule-common/other/ui/modal-base';
import { money } from '../../../webmodule-common/other/currency-formatter';
import { noParalellExecutionAsync } from '../../../webmodule-common/other/common/helpers/callbacks';
import { PricingModel } from '../../../webmodule-common/pricing/pricing-model';
import { pricingStrategies } from '../../../webmodule-common/pricing/pricing-strategies';
import { QuoteContainerManager, StockLookupViewExtra } from '../data/quote-container';
import { QuoteItemPrice, QuotePrice, QuotePriceCalculation } from '../../api/dealer-api-interface-quote';
import { tlang } from '../../../webmodule-common/other/language/lang';

export const addDynamicMoney = (
  dataTracker: DataTracker,
  fieldName: string,
  getter: EventValueGetter,
  setter: EventValueSetter,
  readonly?: () => boolean
) => {
  dataTracker.addBinding(
    new DynamicValueBinder(FieldType.money, true, getter, setter, readonly),
    fieldName,
    fieldName,
    FieldType.money,
    true
  );
};

export const addDynamicFloat = (
  dataTracker: DataTracker,
  fieldName: string,
  getter: EventValueGetter,
  setter: EventValueSetter,
  readonly?: () => boolean
) => {
  dataTracker.addBinding(
    new DynamicValueBinder(FieldType.float, true, getter, setter, readonly),
    fieldName,
    fieldName,
    FieldType.float,
    true
  );
};

@customElement('wm-quoteitempriceadjustment')
export class QuoteItemPriceAdjustment extends ModalDialog {
  public ok = false;
  protected quoteItemPrice: QuoteItemPrice;

  protected quotePrice: QuotePrice;
  protected originalQuoteItemPrice: QuoteItemPrice;
  protected forceReadonly: boolean;
  protected quantity: number;
  protected buyInCost: StockLookupViewExtra[] | undefined;
  protected claims = currentUserClaims();
  protected quoteManager: QuoteContainerManager;
  protected pricingModel: PricingModel;
  protected dataTracker = new DataTracker(new DataBinding(this.ui));
  protected forms = new FormInputAssistant(this.dataTracker, () => this.forceReadonly, true);
  protected isPriceStrategyValid = noParalellExecutionAsync(async (): Promise<boolean> => {
    const validations = this.pricingModel.getValueValidations(
      this.quoteItemPrice.percentMarginOrMarkup ?? this.quotePrice.percentMarginOrMarkup,
      undefined,
      this.pricingModel.calculationLabel
    );
    return await checkValidations(validations.errors);
  });

  //protect from blur and enter triggering because of internal validation popups using noparalell
  protected eventChangeMarginOrMarkup = noParalellExecutionAsync(async () => {
    const p = this.quoteItemPrice;
    //anything over 100 will be treated as 100.
    //100 will result in an infinity error, which will throw a validation on trying to close.
    if (p.percentMarginOrMarkup === this.quotePrice.percentMarginOrMarkup) p.percentMarginOrMarkup = null;
    //we need to do this because lit doesnt recognize the change when we want empty string to default
    this.dataTracker.setEditorValue(
      'percentMarginOrMarkup',
      p.percentMarginOrMarkup ?? this.quotePrice.percentMarginOrMarkup
    );

    this.runPriceStrategyAndUpdatePrice(p);
    this.requestUpdate();
    await this.isPriceStrategyValid();
  });
  protected isClientFacing: boolean;

  constructor(
    isClientFacing: boolean,
    quoteManager: QuoteContainerManager,
    quoteItemPrice: QuoteItemPrice,
    quantity: number,
    forceReadonly: boolean,
    buyInCost: StockLookupViewExtra[] | undefined
  ) {
    super();
    this.isClientFacing = isClientFacing;
    this.pricingModel = quoteManager.pricingModel;
    this.quoteManager = quoteManager;
    this.forceReadonly = forceReadonly;
    this.quantity = quantity;
    this.quotePrice = this.quoteManager.quotePrice;
    this.buyInCost = buyInCost;
    this.quoteItemPrice = clone(quoteItemPrice);
    this.originalQuoteItemPrice = quoteItemPrice;

    addDynamicFloat(
      this.dataTracker,
      'quantity',
      () => {
        return this.quantity;
      },
      (_value: EventValue) => {
        console.log('Quantity is readonly');
      },
      () => false
    );

    addDynamicFloat(
      this.dataTracker,
      'percentMarginOrMarkup',
      () => {
        return this.quoteItemPrice.percentMarginOrMarkup ?? this.quotePrice.percentMarginOrMarkup;
      },
      (value: EventValue) => {
        if (value === this.quotePrice.percentMarginOrMarkup) this.quoteItemPrice.percentMarginOrMarkup = null;
        else this.quoteItemPrice.percentMarginOrMarkup = value as number;
      },
      () => false
    );
    addDynamicMoney(
      this.dataTracker,
      'costPrice',
      () => this.quoteItemPrice.quantityCost,
      (_value: EventValue) => {
        console.log('suppilerCost readonly');
      },
      () => true
    );

    addDynamicMoney(
      this.dataTracker,
      'subtotal',
      () => this.quoteItemPrice.calculatedNetSellingPrice,
      (_value: EventValue) => {
        console.log('suppilerCost readonly');
      },
      () => true
    );

    this.dataTracker.addObjectBinding(
      () => this.quoteItemPrice,
      'priceAdjustment',
      'priceAdjustment',
      FieldType.money,
      false
    );
    this.dataTracker.addObjectBinding(
      () => this.quoteItemPrice,
      'calculatedGrossSellingPrice',
      'calculatedGrossSellingPrice',
      FieldType.money,
      false
    );
  }

  protected get modalSize() {
    return 'modal-md';
  }

  async canClose(): Promise<boolean> {
    if (isAutoSaving()) {
      if (!(await this.isValid())) return false;
      this.save();
    }
    return true;
  }

  getValidationErrors(): string[] {
    const validations = this.pricingModel.getValueValidations(
      this.quoteItemPrice.percentMarginOrMarkup ?? this.quotePrice.percentMarginOrMarkup,
      undefined,
      this.pricingModel.calculationLabel
    );
    return validations.errors;
  }

  getMarginLabel(): string {
    return tlang`${this.pricingModel.calculationLabel} (default ${this.quotePrice.percentMarginOrMarkup.toString()}%)`;
  }

  protected eventChangeGrossSellingPrice = () => {
    const p = this.quoteItemPrice;
    const priceAdjustment = p.calculatedGrossSellingPrice - p.calculatedNetSellingPrice;
    p.priceAdjustment = money(priceAdjustment, 2);
    this.dataTracker.setEditorValue('priceAdjustment', p.priceAdjustment);
    this.requestUpdate();
  };

  protected eventKeyupMarginOrMarkup = (e: KeyboardEvent) => {
    if (e.code === 'Enter') {
      this.eventChangeMarginOrMarkup();
    }
  };
  protected eventKeyupGrossSellingPrice = (e: KeyboardEvent) => {
    if (e.code === 'Enter') {
      this.eventChangeGrossSellingPrice();
    }
  };
  protected eventKeyupPriceAdjustment = (e: KeyboardEvent) => {
    if (e.code === 'Enter') {
      this.eventChangePriceAdjustment();
    }
  };
  protected eventChangePriceAdjustment = () => {
    const p = this.quoteItemPrice;
    this.runPriceStrategyAndUpdatePrice(p);
    this.requestUpdate();
  };

  protected async isValid(): Promise<boolean> {
    this.prepareForSave();
    return await checkValidations(this.getValidationErrors());
  }

  protected getTitle(): Snippet {
    return html`${tlang`Line Item Price`}`;
  }

  protected prepareForSave() {
    //do nothing
  }

  protected save() {
    this.ok = !compare(this.originalQuoteItemPrice, this.quoteItemPrice);
    Object.assign(this.originalQuoteItemPrice, this.quoteItemPrice);
  }

  protected footerTemplate(): TemplateResult {
    const saveEvent = async () => {
      if (await this.isValid()) {
        this.save();
        await this.hideModal();
      }
    };
    const closeEvent = () => this.hideModal();

    return this.forceReadonly
      ? html` <webmodule-button size="small" variant="default" @click="${closeEvent}">Close</webmodule-button>`
      : html`
          <webmodule-button size="small" variant="default" @click=${closeEvent}>Close</webmodule-button>
          <webmodule-button size="small" variant="primary" @click=${saveEvent}>Save</webmodule-button>
        `;
  }

  protected bodyTemplate(): EventTemplate {
    const forms = this.forms;
    return html`
      <form class="line-item-price-adjustment form-one-col">
        <div class="row">
          <h2>${tlang`%%franchisee%%`}</h2>
          <div class="form-column">
            ${forms.intReadonly('quantity', tlang`Quantity`)}${this.getCostPriceTemplate(forms)}
            ${this.percentMarginOrMarkupTemplate(forms)} ${this.getSubtotalTemplate(forms)}
            ${this.priceAdjustmentTemplate(forms)} ${this.getCalculatedGrossSellingPriceTemplate(forms)}
          </div>
        </div>
      </form>
    `;
  }

  protected getSubtotalTemplate(forms: FormInputAssistant) {
    return forms.moneyReadonly('subtotal', tlang`Sub Total`);
  }

  protected getCalculatedGrossSellingPriceTemplate(forms: FormInputAssistant) {
    return forms.money('calculatedGrossSellingPrice', tlang`Sell Price`, {
      events: {
        blur: this.eventChangeGrossSellingPrice,
        keyup: this.eventKeyupGrossSellingPrice
      }
    });
  }

  protected priceAdjustmentTemplate(forms: FormInputAssistant) {
    return forms.money('priceAdjustment', tlang`Price Adjustment`, {
      events: {
        blur: this.eventChangePriceAdjustment,
        keyup: this.eventKeyupPriceAdjustment
      }
    });
  }

  protected percentMarginOrMarkupTemplate(forms: FormInputAssistant) {
    return this.isClientFacing
      ? undefined
      : forms.float('percentMarginOrMarkup', this.getMarginLabel(), {
          events: {
            blur: this.eventChangeMarginOrMarkup,
            keyup: this.eventKeyupMarginOrMarkup
          }
        });
  }

  private getCostPriceTemplate(forms: FormInputAssistant) {
    return this.isClientFacing ? undefined : forms.moneyReadonly('costPrice', tlang`Cost Price`);
  }

  private runPriceStrategyAndUpdatePrice(p: QuoteItemPrice) {
    const pricingStrategy =
      this.quotePrice.calculation === QuotePriceCalculation.Margin
        ? pricingStrategies.margin
        : pricingStrategies.markup;
    p.calculatedNetSellingPrice = money(
      pricingStrategy.getPriceWithAdjustment(
        p.quantityCost,
        p.percentMarginOrMarkup ?? this.quotePrice.percentMarginOrMarkup,
        undefined
      ),
      2
    );
    p.calculatedGrossSellingPrice = money(p.calculatedNetSellingPrice + p.priceAdjustment, 2);
  }
}

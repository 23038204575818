import { isValidV6ConfigVersion, v6Util } from './v6config';

export function getV6QuoteItemFrameDimensions(v6QuoteItem?: object | null): string {
  if (!v6QuoteItem || !isValidV6ConfigVersion()) return '';

  const displayString = v6Util().getCustomFrameDimensions(v6QuoteItem);
  if (displayString != undefined) return displayString;
  const values = v6Util().getFrameDimensions(v6QuoteItem);
  const displayWidth = values?.displayWidth ? `${values.displayWidth} (W)` : `${values?.width ?? 0} (W)`;
  const displayHeight = values?.displayHeight ? `${values.displayHeight} (H)` : `${values?.height ?? 0} (H)`;

  if (values.width && values.height) {
    return values.displayHeightFirst ? `${displayHeight} x ${displayWidth}` : `${displayWidth} x ${displayHeight}`;
  } else if (values.height) return `"${displayHeight}`;
  else if (values.width) return `${displayWidth}`;
  else return '';
}

import { DevelopmentError } from '../../webmodule-common/other/development-error';
import { fireQuickInformationToast } from '../../webmodule-common/other/toast-away';
import { getCurrentUser } from '../../webmodule-common/other/api/current-user';
import { html } from 'lit';
import { tlang } from '../../webmodule-common/other/language/lang';
import { tlangMarkdown } from '../../webmodule-common/other/general/markdown';
import { WMEventSourceClient } from '../../webmodule-common/other/general/event-source-client';

// eslint-disable-next-line @typescript-eslint/no-extraneous-class
export class WMEventSource {
  public static quote = 'quote';
  public static order = 'order';
  public static projectDocument = 'project-document';
  public static projectDocumentDel = 'project-document-delete';
  public static project = 'project';
  public static projectResource = 'project-resource-reference';
  public static projectResourceDel = 'project-resource-reference-delete';
  public static v6configServiceStatus = 'v6-service-status';
  public static afterConnect() {
    const user = getCurrentUser();
    if (!user) throw new DevelopmentError('User not connected');
    const dontShow = true; // localStorage.getItem(`source-event-notify:${userId}:v3`) === 'hide';
    if (dontShow) return;
    const clickEvent = () => {
      localStorage.setItem(`source-event-notify:${user.id}:v3`, 'hide');
    };
    const infoTemplate = html`<div>
      ${tlangMarkdown`${'ref:eventsource-connected'}**You are now receiving
            live updates from server as they happen.**
            
            Your !!quote!! and !!project!! will refresh as data is updated on the server.
            `}
      <webmodule-button size="small" variant="primary" data-bs-dismiss="toast" @click=${clickEvent}>
        ${tlang`Don't show again`}
      </webmodule-button>
    </div>`;

    fireQuickInformationToast(infoTemplate, 60000);
  }
  public static getInstance(): WMEventSourceClient {
    return WMEventSourceClient.getInstance();
  }
}

import { Client, Contact } from '../../api/dealer-api-interface-client';
import { ClientApi } from '../../api/client-api';
import { clone, compare } from '../../../webmodule-common/other/clone';
import { EventBoolean, EventNotify, EventSnippet, NullPromise } from '../../../webmodule-common/other/ui/events';
import { fireQuickSuccessToast } from '../../../webmodule-common/other/toast-away';
import { localDateToServer } from '../../../webmodule-common/other/datetime-converter';
import { newGuid } from '../../../webmodule-common/other/api/guid';
import { runEventNotify } from '../../../webmodule-common/other/array-helper';
import { showDevelopmentError } from '../../../webmodule-common/other/development-error';
import { tlang } from '../../../webmodule-common/other/language/lang';
import { validId } from '../../../webmodule-common/other/ui/string-helper-functions';

export class ClientContainer {
  clientId: string;
  client: Client | null;
  primaryContactName: string;
  primaryContactEmail: string;

  constructor(clientId: string, client: Client | null, primaryContactName: string, primaryContactEmail: string) {
    this.clientId = clientId;
    this.client = client;
    this.primaryContactEmail = primaryContactEmail;
    this.primaryContactName = primaryContactName;
  }
}

export class ClientContainerManager {
  public container: ClientContainer;
  protected backup: ClientContainer;
  protected clientApi: ClientApi;
  public title: EventSnippet;
  afterSave: EventNotify[] = [];
  forceLocked: EventBoolean | undefined;

  constructor(clientContainer: ClientContainer, clientApi: ClientApi, title: EventSnippet, forceLocked?: EventBoolean) {
    if (clientContainer.client && clientContainer.client.id !== clientContainer.clientId)
      throw new Error(`Parameter mismatch between client and clientId`);
    this.forceLocked = forceLocked;
    this.container = clientContainer;
    this.clientApi = clientApi;
    this.backup = clone(this.container);
    this.title = title;
  }

  /**
   * the id for this managed container
   */
  public get clientId(): string {
    return this.container.clientId;
  }

  public get isNew(): boolean {
    return !validId(this.client.id);
  }
  /**
   * returns the client object after needsClient is called, or throws an error if the client is unavailable.
   */
  public get client(): Client {
    if (!this.container.client) {
      throw new Error(tlang`Client is null`);
    }
    return this.container.client;
  }

  public changed(): boolean {
    return !compare(this.container, this.backup);
  }

  public isReadonly(): boolean {
    return this.forceLocked?.() ?? false;
  }

  public async saveClient(silently: boolean): Promise<boolean> {
    if (this.isReadonly()) {
      await showDevelopmentError('Trying to save readonly client');
      return false;
    }

    if (await this.internalSave()) {
      if (!silently) fireQuickSuccessToast(tlang`%%client%% Saved "${this.title()}"`);

      await this.doAfterSave();
      return true;
    }
    return false;
  }

  /**
   * Override this to customize the save logic.
   * @returns True if the save operations have succeeded, false otherwise.
   */
  protected async internalSave(): Promise<boolean> {
    const isNew = this.isNew;
    const result = isNew
      ? (await this.clientApi.createClient({ client: this.client }))?.client
      : (await this.clientApi.updateClient({ client: this.client }))?.client;
    let contactResult: Contact | null = null;
    if (result && isNew) {
      contactResult =
        (
          await this.clientApi.createContact({
            contact: {
              id: newGuid(),
              name: this.container.primaryContactName,
              email: this.container.primaryContactEmail,
              dateCreated: localDateToServer(new Date()),
              clientId: result.id,
              recordVersion: '',
              title: tlang`New %%contact%%`,
              mobile: ''
            },
            isPrimary: true
          })
        )?.contact ?? null;
    }
    if (result) {
      this.resetClient(result, contactResult ?? (await this.getPrimaryContact(result.id)));
      return true;
    }
    return false;
  }

  public async needsClient() {
    if (!this.container.client) {
      const result = await this.clientApi.getClient({
        clientId: this.clientId
      });
      if (result) {
        this.resetClient(result.client, await this.getPrimaryContact(result.client.id));
      } else return false;
    }
    return true;
  }
  protected async getPrimaryContact(_clientId: string): NullPromise<Contact> {
    return null;
  }

  private resetClient(client: Client, contact: Contact | null) {
    this.container.client = client;
    this.container.primaryContactName = contact?.name ?? '';
    this.container.primaryContactEmail = contact?.email ?? '';
    this.backup.client = clone(client);
    this.backup.primaryContactName = contact?.name ?? '';
    this.backup.primaryContactEmail = contact?.email ?? '';
  }

  /**
   * execute all bound events after any save operation to allow for re-rendering and refreshing of state
   */
  private async doAfterSave(): Promise<void> {
    await runEventNotify(this.afterSave);
  }
}

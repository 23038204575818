import { customElement } from 'lit/decorators.js';
import { V6QuoteSupplierGlassView } from '../../../../quotes/v6/v6-quote-supplier-glass-view';

import { V6FranchiseeQuoteProviderData } from '../../data/franchisee-quote-provider-data';
@customElement('wm-franchiseev6quotesupplierglassview')
export class FranchiseeV6QuoteSupplierGlassView extends V6QuoteSupplierGlassView {
  protected getQuoteIGUs(): unknown {
    const data = this.quoteManager.quoteProviderData() as V6FranchiseeQuoteProviderData;
    return data.igu ?? [];
  }

  protected setQuoteIGUs(items: unknown) {
    const data = this.quoteManager.quoteProviderData() as V6FranchiseeQuoteProviderData;
    data.igu = items;
  }
}

import { Client, Contact } from '../../api/dealer-api-interface-client';
import {
  FranchiseeClient,
  PaymentProfile,
  PurchaseOrderBranchView,
  QuoteItemConversation,
  QuoteItemConversationType,
  ResultPurchaseOrderStateChangeReasonsForState
} from '../../api/dealer-api-interface-franchisee';
import {
  Resource,
  ResultProjectStateChangeReasonsForState,
  ViewProjectDocument,
  ViewProjectSummary
} from '../../api/dealer-api-interface-project';
import { ResultQuoteStateChangeReasonsForState, ViewQuoteSummary } from '../../api/dealer-api-interface-quote';

export interface SupplierCacheData {
  supplierId: string;
  name: string;
}

export interface QuoteCacheData {
  quoteSummary: ViewQuoteSummary;
}

export interface ContactCacheData {
  contact: Contact;
}

export interface PaymentProfileCacheData {
  paymentProfile: PaymentProfile;
}

export interface ClientCacheData {
  client: Client;
  franchiseeClient: FranchiseeClient | null;
}

export interface ProjectCacheData {
  projectSummary: ViewProjectSummary;
}

export interface ProjectPermanentDocumentCacheData {
  documents: ViewProjectDocument[];
}

export class QuoteItemConversations {
  items: QuoteItemConversation[] = [];
  quoteItemId = '';

  constructor(quoteItemId: string) {
    this.quoteItemId = quoteItemId;
  }

  merge(other: QuoteItemConversations) {
    if (other.quoteItemId === this.quoteItemId) other.items.forEach(x => this.add(x));
  }

  byType(type: QuoteItemConversationType): string | undefined {
    return this.items.find(x => x.type === type)?.conversationId;
  }

  add(item: QuoteItemConversation) {
    if (
      !this.items.find(
        x => x.conversationId === item.conversationId && x.quoteItemId === item.quoteItemId && x.type === item.type
      )
    )
      this.items.push(item);
  }
}

export interface PurchaseOrderCacheData {
  purchaseOrder: PurchaseOrderBranchView;
}

export interface ResourceCacheData {
  resource: Resource;
}

export interface QuoteStateChangeReasonCacheData {
  quoteStateChangeReasons: ResultQuoteStateChangeReasonsForState[];
}

export interface ProjectStateChangeReasonCacheData {
  projectStateChangeReasons: ResultProjectStateChangeReasonsForState[];
}

export interface PurchaseOrderStateChangeReasonCacheData {
  purchaseOrderStateChangeReasons: ResultPurchaseOrderStateChangeReasonsForState[];
}

// eslint-disable-next-line import/named

import {
  addCurrentUserEventListener,
  getCurrentUser,
  removeCurrentUserEventListener,
  setUserClaims
} from '../../webmodule-common/other/api/current-user';
import { addURLResolvers, goStaticURL } from '../../webmodule-common/other/ui/resource-resolver';
import { appConfig, appConfigMenuPageItems } from './app-config';
import { AppIndex } from '../../webmodule-common/other/app-index';
import { appOutOfDate } from '../../webmodule-common/other/debug';
import { attachRouter, setRoutes } from '../../webmodule-common/other/router';
import { claimIdentity, userSecurity } from '../../webmodule-common/other/api/user-security';
import { classMap } from 'lit-html/directives/class-map.js';
import { currentUserClaims } from '../../webmodule-common/other/currentuser-claims';
import { customElementTry, registerIconLibrary } from '../../webmodule-common/components/src/webmodule-components';
import { firstValidString } from '../../webmodule-common/other/ui/string-helper-functions';
import { franchiseeComponentRegistry } from './componentRegistry';
import { getApi } from '../api/api-injector';
import { GlobalSupplierNotification } from '../v6config/supplier-services';
import { injectThemeClasses, isTestSite } from '../../webmodule-common/other/domain';
import { lockUIandExecute } from '../../webmodule-common/other/ui-lock';
import { setUserReloadEvent, verifySession } from '../../webmodule-common/other/user-session-verifier';
import { v6Config, v6SupportsVersion, v6Util, v6VersionMap } from '../v6config/v6config';
import type { MenuItem } from '../../webmodule-common/other/app-index';
import type { ResolveURLEntry } from '../../webmodule-common/other/ui/resource-resolver';
import type { UserPublicInfo } from '../../webmodule-common/other/api/current-user';

import {
  _v6FrameConfigApiVersion,
  _v6FrameConfigVersion,
  _v6SupplierVersion,
  currentSupplierNames,
  getApplicationStartupEvents
} from './application-initialization';
import { FormInputAssistantBase } from '../../webmodule-common/other/ui/templateresult/form-input-assistant-base';
import { getPageController } from '../../webmodule-common/other/ui/pages/page-base';
import { getQuoteSuppliers } from '../quotes/quote-service';
import { html } from 'lit';
import { iconRegistry } from '../../webmodule-common/other/ui/icons/register-icons';
import { information } from '../../webmodule-common/other/ui/modal-option';
import { lapsedMinutes, sleep } from '../../webmodule-common/other/general/time';
import { noSqlDb } from '../../webmodule-common/database/no-sql-db';
import { query, state } from 'lit/decorators.js';
import { tlang } from '../../webmodule-common/other/language/lang';
import { userDataStore } from './common/current-user-data-store';
import { webcomponentRegistry } from '../../webmodule-common/other/ui/webcomponent-registry';
import { WMEventSource } from '../api/event-source';
import type { Branch, ConnectedState } from '../api/dealer-api-interface-franchisee';
import type { NoSqlDb } from '../../webmodule-common/database/no-sql-db';
import type { SupplierInfo } from '../../webmodule-common/interop/webmodule-interop';
import type { TemplateResult } from 'lit';
import { getAssetCdnPath } from '../../webmodule-common/other/common/assets';
import { awaitableTemplate } from '../../webmodule-common/other/ui/template-processor';

function rebindRouter() {
  const config = appConfig();
  setRoutes(config.routes);
  addURLResolvers(
    config.routes
      .filter(x => x.resolveUrl !== undefined)
      .map(x => {
        return x.resolveUrl as ResolveURLEntry;
      })
  );
}

// this is our concrete top level application, everything else should be generic
@customElementTry('franchisee-app-index')
export class FranchiseeAppIndex extends AppIndex {
  protected noSqlDb: NoSqlDb = noSqlDb;
  // Do not remove below line as this will stop icons from working on our site.
  protected icons = iconRegistry;
  protected webcomponents = webcomponentRegistry;
  protected franchiseeComponents = franchiseeComponentRegistry;
  @query('#main-body')
  private mainBody!: HTMLElement;
  private _supplierOnlineCheckTimer?: NodeJS.Timeout;
  @state()
  private _lastCheckedSupplierOnline?: Date;
  @state()
  private _supplierOnline = GlobalSupplierNotification.getDefaultInstance()?.online;
  @state() private _supplierOfflineMessage = GlobalSupplierNotification.getDefaultInstance()?.offlineMessage;

  constructor() {
    super();
    rebindRouter();

    registerIconLibrary('system', {
      resolver: name => getAssetCdnPath(`/assets/icons/${name}.svg`)
    });

    registerIconLibrary('bootstrap', {
      resolver: name => `https://cdn.jsdelivr.net/npm/bootstrap-icons@1.10.4/icons/${name}.svg`
    });

    registerIconLibrary('fa', {
      resolver: name => {
        const filename = name.replace(/^fa[rbs]-/, '');
        let folder = 'regular';
        if (name.startsWith('fas-')) folder = 'solid';
        if (name.startsWith('fab-')) folder = 'brands';
        return `https://cdn.jsdelivr.net/npm/@fortawesome/fontawesome-free@6.5.2/svgs/${folder}/${filename}.svg`;
      },
      mutator: svg => svg.setAttribute('fill', 'currentColor')
    });

    this.userDisplayName = getCurrentUser()?.friendlyName;
  }

  async userStateChanged(_user: UserPublicInfo | null) {
    if (appOutOfDate()) return;
    const user = getCurrentUser();
    if (user !== null) {
      const gsn = GlobalSupplierNotification.getDefaultInstance();
      gsn?.addEventListenter(this._supplierStatusCallback);
      this._supplierOnline = gsn?.online;
      this._supplierOfflineMessage = gsn?.offlineMessage;

      await this.updateUIAspectsAfterLogin();
      WMEventSource.getInstance().connectUser();
    } else {
      this.userDisplayName = '';
      WMEventSource.getInstance().disconnect();
    }
  }

  generateBuildNumberText(): TemplateResult {
    const copyEvent = async () => {
      function parts(val: string[], from, count: number): string {
        //Supplier Ver "10.0.13.31.30.380481012"
        return val.slice(from, from + count).join('.');
      }

      function supplierDetails(suppinfo: SupplierInfo, buildnumber: string) {
        const nums = suppinfo.version.split('.');
        return `
### ${tlang`%%supplier%% "${suppinfo.description}`}"

+ ${tlang`Macros "${parts(nums, 0, 3)}`}"
+ ${tlang`DB Instance Version "${parts(nums, 3, 1)}`}"
+ ${tlang`DB Pricing Version "${parts(nums, 4, 1)}`}"
+ ${tlang`V6 "${parts(nums, 5, 1)}`}"
+ ${tlang`API "${firstValidString(_v6FrameConfigApiVersion, buildnumber)}`}"
------------------------------------------------------

            `;
      }

      const v6Suppliers = (await v6Config().suppliers(true)) ?? [];
      const text = `# Dealer's Module (SoftTech dealer CPQ)
      
+ ${tlang`Location`} "${window.location.hostname}"     
+ ${tlang`Dealer Build "${this.buildNumber}`}"
+ ${tlang`Frame Config "${_v6FrameConfigVersion}`}"
      
## Supplier Details
${v6Suppliers.map(x => supplierDetails(x, this.buildNumber ?? '')).join('\n')}

            `;
      navigator?.clipboard?.writeText(text);
      await information(text, tlang`Copied to clipboard`);
    };

    const forms = new FormInputAssistantBase(false);
    return html`${forms.clickableIcon({
      library: 'system',
      name: 'db-version',
      events: { click: copyEvent }
    })}`;
  }

  serviceOnlineTemplate() {
    return this._supplierOnline
      ? html``
      : html` <div class="supplier-offline-alert">
          <span>${this._supplierOfflineMessage}</span>
        </div>`;
  }

  connectedCallback(): void {
    super.connectedCallback();
    const gsn = GlobalSupplierNotification.getDefaultInstance();
    gsn?.addEventListenter(this._supplierStatusCallback);
    this._supplierOnline = gsn?.online;
    this._supplierOfflineMessage = gsn?.offlineMessage;

    addCurrentUserEventListener(this);
    setUserReloadEvent(async () => {
      const user = getCurrentUser();
      if (user) {
        await userDataStore.reLoadFranchisee();
        await this.updateUIAspectsAfterLogin();
      } else {
        userDataStore.clear();
      }

      this.requestUpdate();
    });
    if (gsn) gsn.extraText = tlang`Testing connection every 5 minutes.`;
    this._supplierOnlineCheckTimer = setTimeout(this._supplierOnlineCheckEvent, 60000);
  }

  disconnectedCallback(): void {
    clearTimeout(this._supplierOnlineCheckTimer);
    this._supplierOnlineCheckTimer = undefined;
    GlobalSupplierNotification.getDefaultInstance()?.remEventListenter(this._supplierStatusCallback);
    super.disconnectedCallback();
    removeCurrentUserEventListener(this);
  }

  userNameMenuContent(): unknown {
    return html` <webmodule-icon library="fa" name="fas-user" slot="prefix"></webmodule-icon
      >${this.userDisplayName?.trim()}`;
  }

  aboveUserNameTemplate(): unknown {
    const branchName = userDataStore.loaded ? userDataStore.defaultBranch.name.trim() : '';

    return userDataStore.loaded
      ? html`
          <webmodule-dropdown placement="bottom">
            <webmodule-button variant="text" slot="trigger" caret>
              <webmodule-icon library="fa" name="fas-building" slot="prefix"></webmodule-icon>
              ${branchName}
            </webmodule-button>
            <webmodule-menu> ${this.customBranchMenuElements()}</webmodule-menu>
          </webmodule-dropdown>
        `
      : html``;
  }

  globalSupportInformation(): string | TemplateResult {
    const cu = getCurrentUser();
    if (!cu) return '';
    const cc = currentUserClaims();
    if (cc.isCynclyStaff) {
      return cc.isAgent // cyncly staff can log in as a dealer or supplier depending on support needed
        ? `CYNCLY Support AS SUPPLIER connected to "${
            (cu as any).tenantName
          }", Using Supplier(s) ${currentSupplierNames}`
        : `CYNCLY Support AS DEALER connected to "${(cu as any).tenantName}", Using Supplier ${currentSupplierNames}`;
    } else if (cc.isAgent) {
      return tlang`%%Supplier%% Support Connected to "${(cu as any).tenantName}"`;
    }
    return '';
  }

  customUserMenuElements(): TemplateResult {
    return html``;
  }

  protected cynclyStaffMenuItems() {
    const getSupplierTemplates = async () => {
      const eventRestartV6 = (supplierId: string) => {
        return async () => await v6Util().restartV6Service(supplierId);
      };

      const v6Suppliers = (await v6Config().suppliers(true)) ?? [];

      if (!v6SupportsVersion(v6VersionMap.hasRestartV6)) return html``;

      return html`${v6Suppliers?.map(x => {
        return html` <webmodule-menu-item @click=${eventRestartV6(x.id)}
          >${tlang`Restart V6 Service "${x.description}"`}
        </webmodule-menu-item>`;
      })}`;
    };

    return html`${awaitableTemplate(getSupplierTemplates())}`;
  }

  customBranchMenuElements(): TemplateResult {
    const doNothing = (e: Event) => {
      e.stopImmediatePropagation();
      e.preventDefault();
    };
    const switchBranchEvent = (branch: Branch) => () => {
      this.changeBranch(branch);
    };
    const branchTemplate = (branch: Branch) => {
      const isSelected = branch.id === userDataStore.defaultBranch.id;
      return html`
        <webmodule-menu-item
          class="${classMap({
            'fw-bolder': isSelected
          })}"
          @click=${switchBranchEvent(branch)}
        >
          <webmodule-icon
            class="${classMap({
              'text-primary': isSelected
            })}"
            slot="prefix"
            library="fa"
            name="fas-caret-right"
          ></webmodule-icon>
          ${branch.name}
        </webmodule-menu-item>
      `;
    };
    const switchBranchTemplate = () => {
      return userDataStore
        .getUserBranches()

        .map(x => branchTemplate(x));
    };
    if (!getCurrentUser() || !userDataStore.loaded) return html``;
    if (userSecurity().claimIsTrue(claimIdentity.allBranches) && userDataStore.getUserBranches().length > 1) {
      return html`
        <webmodule-menu-label>${tlang`!!branch!!`}</webmodule-menu-label>
        ${switchBranchTemplate()}
        <webmodule-divider></webmodule-divider>
      `;
    } else
      return html` <webmodule-menu-label>${tlang`!!branch!!`}</webmodule-menu-label>

        <webmodule-menu-item @click=${doNothing}>
          <webmodule-icon class="text-primary" slot="prefix" library="fa" name="fas-caret-right"></webmodule-icon>
          ${userDataStore.defaultBranch.name}
        </webmodule-menu-item>
        <webmodule-divider></webmodule-divider>`;
  }

  async changeBranch(branch: Branch) {
    await lockUIandExecute(async () => {
      if (!(await getPageController().pagesCanClose())) return;
      goStaticURL('');
      await sleep(300);
      userDataStore.defaultBranch = branch;
      await userDataStore.setV6OverrideKey();

      //the line below is executed in the line above

      await getPageController().disposeAndRefresh();
      this.requestUpdate();
    });
  }

  async disconnectUser() {
    await getPageController().disposeAll();
    try {
      await getApi().post<ConnectedState>('api/Franchisee/DisconnectUser', {});
      setUserClaims({});
    } catch {
      console.log(`Error disconnecting user`);
    }
    return true;
  }

  public updateLogoTemplate() {
    if (this.outOfDate) return;
    const api = getApi();
    try {
      const logoURl = isTestSite()
        ? api.fullUrl(`api/file/${userDataStore.franchisee.negativeLogoVirtualPath}`)
        : api.fullUrl(`api/file/${userDataStore.franchisee.positiveLogoVirtualPath}`);
      this.logoTemplate = userDataStore.franchisee.positiveLogoVirtualPath
        ? html`<img src=${logoURl} crossorigin="anonymous" class="img-fluid" alt="Brand" width="90" />`
        : html` <svg width="1" height="1"></svg>`;
    } catch {
      //may be doing this after a failed login or out of date issue
    }
  }

  protected getMenuItems(): MenuItem[] {
    return appConfigMenuPageItems();
  }

  protected async firstUpdated() {
    attachRouter(this.mainBody);
    let interval;

    await getApplicationStartupEvents();
    const verifyUser = async () => {
      clearInterval(interval);
      await verifySession();
      interval = setTimeout(verifyUser, 500);
    };
    interval = setTimeout(verifyUser, 500);
    injectThemeClasses();
  }

  private _supplierOnlineCheckEvent = async () => {
    const gsn = GlobalSupplierNotification.getDefaultInstance();
    if (!gsn) return;
    if (!gsn.online) {
      if (!this._lastCheckedSupplierOnline || lapsedMinutes(this._lastCheckedSupplierOnline) > 5) {
        const suppliers = await getQuoteSuppliers(true);
        if (suppliers.length > 0 && suppliers[0].online) {
          this._lastCheckedSupplierOnline = undefined;
        } else this._lastCheckedSupplierOnline = new Date();
      }
      gsn.extraText = tlang`Testing connection every 5 minutes. Last Check ${this._lastCheckedSupplierOnline?.toLocaleTimeString()}  `;
    } else this._lastCheckedSupplierOnline = undefined;
    this._supplierOnlineCheckTimer = setTimeout(this._supplierOnlineCheckEvent, 60000);
  };

  private _supplierStatusCallback = () => {
    try {
      this._supplierOnline = GlobalSupplierNotification.getInstance().online;
      this._supplierOfflineMessage = GlobalSupplierNotification.getInstance().offlineMessage;
    } catch {
      /* empty */
    }
  };

  private async updateUIAspectsAfterLogin() {
    await userDataStore.loadCoreDetailsAfterLogin();
    this.updateLogoTemplate();
    this.userDisplayName = getCurrentUser()?.friendlyName;
    this.requestUpdate();
  }
}

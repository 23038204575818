// eslint-disable-next-line import/named
import {
  BranchQuote,
  BranchQuoteSupport,
  BranchQuoteSupportItemType,
  BranchQuoteSupportStatus
} from '../../../api/dealer-api-interface-franchisee';
import { customElement } from 'lit/decorators.js';
import { emptyGuid, newGuid } from '../../../../webmodule-common/other/api/guid';
import { EventTemplate } from '../../../../webmodule-common/other/ui/events';
import { FranchiseeQuoteContainerManager } from '../data/franchisee-quote-manager';
import { getApiFactory } from '../../../api/api-injector';
import { html } from 'lit';
import { isEmptyOrSpace } from '../../../../webmodule-common/other/ui/string-helper-functions';
import { PageControlTabWithIndependantSaving } from '../../../../webmodule-common/other/ui/data-entry-screen-base';
import { Snippet } from '../../../../webmodule-common/interop/webmodule-interop';
import { tlang } from '../../../../webmodule-common/other/language/lang';
import { WebModuleBranchQuoteConversationEditor } from '../../conversation/wm-branchquote-support-editor';
import { WebModuleBranchQuoteSupportNew } from '../../conversation/wm-branchquote-support-new';

@customElement('wm-franchiseequotesupportticketview')
export class FranchiseeQuoteSupportTicketView extends PageControlTabWithIndependantSaving {
  private addRequestType: BranchQuoteSupportItemType;
  private _branchQuoteSupport?: BranchQuoteSupport | undefined;
  public get branchQuoteSupport(): BranchQuoteSupport | undefined {
    return this._branchQuoteSupport;
  }
  public set branchQuoteSupport(value: BranchQuoteSupport | undefined) {
    this._branchQuoteSupport = value;
    this.requestUpdate();
  }
  protected dataPrimaryKey: string = newGuid();
  protected quoteManager: FranchiseeQuoteContainerManager;
  protected franchiseeApi = getApiFactory().franchisee();
  constructor(
    quoteManager: FranchiseeQuoteContainerManager,
    branchQuoteSupport?: BranchQuoteSupport,
    addRequestType?: BranchQuoteSupportItemType
  ) {
    super();
    this.quoteManager = quoteManager;
    this.branchQuoteSupport = branchQuoteSupport;
    this.addRequestType = addRequestType ?? BranchQuoteSupportItemType.QuoteAssistanceRequest;
  }

  get forceReadonly() {
    return this.quoteManager.isReadonly();
  }
  get branchQuote(): BranchQuote {
    return this.quoteManager.branchQuote;
  }
  get newEditor(): WebModuleBranchQuoteSupportNew | undefined {
    return this.ui.querySelector('#newEditor') as WebModuleBranchQuoteSupportNew;
  }
  get ticketEditor(): WebModuleBranchQuoteConversationEditor | undefined {
    return this.ui.querySelector('#ticketeditor') as WebModuleBranchQuoteConversationEditor;
  }

  override isDataReadonly(): boolean {
    return (
      super.isDataReadonly() ||
      (this.branchQuoteSupport !== undefined &&
        (this.branchQuoteSupport?.status == BranchQuoteSupportStatus.Resolved ||
          this.branchQuoteSupport?.status == BranchQuoteSupportStatus.Cancelled))
    );
  }

  protected isNew(): boolean {
    return !this.branchQuoteSupport || isEmptyOrSpace(this.branchQuoteSupport.recordVersion);
  }
  protected name() {
    return 'support-ticket';
  }
  protected abandonAndCloseButton(force?: boolean) {
    if (this.isDataReadonly()) return html``;
    return html` <webmodule-button size="small" variant="default" @click=${() => this.abandonAndClose(force)}>
      ${this.discardLabel()}
    </webmodule-button>`;
  }
  buttonMenu(): Snippet {
    if (this.isNew()) {
      return html`${this.abandonAndCloseButton(true)}
      ${this.newEditor?.actionsTemplate().draft()}${this.newEditor?.actionsTemplate().post()}`;
    }

    return html`${this.abandonAndCloseButton()}${this.ticketEditor?.branchSupportActionsTemplate()} `;
  }
  discardLabel(): unknown {
    if (this.isDataReadonly()) return tlang`Close`;

    return this.isNew() ? tlang`Discard` : tlang`Discard Changes`;
  }
  get quoteSetId() {
    return this.quoteManager.quote.quoteSetId;
  }
  get branchQuoteSupportId(): string {
    return this.branchQuoteSupport?.id ?? emptyGuid;
  }
  //true then this page is not valid, and should be assumed to be cancelled
  public isTab(): boolean {
    return true;
  }

  protected getValidationErrors(): string[] {
    return this.isNew() ? (this.newEditor?.getValidations() ?? []) : (this.ticketEditor?.getValidationErrors() ?? []);
  }

  protected async revertChanges(): Promise<boolean> {
    return true;
  }
  async prepareForSave(): Promise<void> {
    if (this.forceReadonly) return;
  }

  public internalDataChanged(): boolean {
    return this.isNew() ? true : (this.ticketEditor?.hasChanged ?? false);
  }
  protected getCaption(): Snippet {
    if (this.isNew()) return tlang`New %%support-request%%`;

    return tlang`%%support-request%%`;
  }

  protected async internalSaveData(): Promise<boolean> {
    return this.isNew() ? ((await this.newEditor?.save()) ?? true) : (this.ticketEditor?.save() ?? true);
  }
  get quoteId() {
    return this.quoteManager.quoteId;
  }

  protected addRequestTemplate() {
    const eventCreated = (e: CustomEvent<{ branchQuoteSupport: BranchQuoteSupport }>) => {
      this.branchQuoteSupport = e.detail.branchQuoteSupport;
      this.dispatchUiChanged();
    };
    const eventCancelled = () => {
      //close this page
    };
    const eventChanged = (_e: CustomEvent<{ isUploading: boolean }>) => {
      this.dispatchUiChanged();
    };

    return html`<wmview-branchquote-support-new
      id="newEditor"
      .quoteManager=${this.quoteManager}
      .supportType=${this.addRequestType}
      .showButtons=${false}
      .showCancelButton=${false}
      .supplierName=${this.quoteManager.supplierName}
      @wm-event-created=${eventCreated}
      @wm-event-cancel=${eventCancelled}
      @wm-event-changed=${eventChanged}
    ></wmview-branchquote-support-new>`;
  }
  protected editRequestTemplate() {
    const eventCloseTicket = (_e: CustomEvent<{ isUploading: boolean }>) => {
      this.tryClose();
    };
    const eventStateChange = () => {
      this.refreshParent();
    };
    const eventSaved = (e: CustomEvent<{ branchQuoteSupport: BranchQuoteSupport }>) => {
      this.branchQuoteSupport = e.detail.branchQuoteSupport;
      this.refreshParent();
    };
    return html`
      <wmview-branchquote-conversation-editor
        .showHeader=${true}
        .showCloseButton=${false}
        .readonly=${this.isDataReadonly()}
        id="ticketeditor"
        @wm-event-close=${eventCloseTicket}
        @wm-event-changed=${eventStateChange}
        @wm-event-saved=${eventSaved}
        .branchQuoteSupport=${this.branchQuoteSupport}
        .supplierName=${this.quoteManager.supplierName}
      ></wmview-branchquote-conversation-editor>
    `;
  }
  protected bodyTemplate(): EventTemplate {
    return this.isNew() ? this.addRequestTemplate() : this.editRequestTemplate();
  }
}

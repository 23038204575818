import { BranchQuoteSupportItemType, BranchQuoteSupportStatus } from '../../../api/dealer-api-interface-franchisee';
import { branchUsersCache } from '../../cache-impl/branch-users-cache';
import { customElement, state } from 'lit/decorators.js';
import { EventTemplate } from '../../../../webmodule-common/interop/webmodule-interop';
import { getInternalId } from '../../../../webmodule-common/other/ui/databinding/databinding';
import { html } from 'lit';
import {
  MenuIconOption,
  PageButtonLocation,
  PageControl,
  PageControlOptions,
  PageManager
} from '../../../../webmodule-common/other/ui/page-control';
import { QuoteSupportInboxTable } from './quote-support-inbox-table';
import { tlang } from '../../../../webmodule-common/other/language/lang';
import { userDataStore } from '../../common/current-user-data-store';
import { ViewBase } from '../../../../webmodule-common/other/ui/view-base';

@customElement('wm-supportinboxview')
export class SupportInboxView extends ViewBase {
  @state()
  protected viewAll?: QuoteSupportInboxTable;
  @state()
  protected viewReviews?: QuoteSupportInboxTable;
  @state()
  protected viewSupport?: QuoteSupportInboxTable;
  @state()
  protected viewCustomItems?: QuoteSupportInboxTable;
  @state()
  protected viewArchived?: QuoteSupportInboxTable;

  pageControl: PageControl;
  elementId: string;

  private userCache = branchUsersCache();

  constructor() {
    super();
    this.elementId = `quote-support-inbox-view-${getInternalId()}`;

    this.createTables();
    this.pageControl = this.createPageControl();
    this.pageControl.pageButtonLocation = PageButtonLocation.right;
  }

  public async afterConstruction(): Promise<void> {
    await super.afterConstruction();
    await this.userCache.prefetch([userDataStore.defaultBranch.id]);
  }

  public createTables(): void {
    const activeStates =
      // eslint-disable-next-line no-bitwise
      BranchQuoteSupportStatus.Active |
      BranchQuoteSupportStatus.ReActivated |
      BranchQuoteSupportStatus.New |
      BranchQuoteSupportStatus.Overdue;

    const getBranchUserIds = () =>
      this.userCache.getUsersForBranch(userDataStore.defaultBranch.id)?.map(x => x.id) ?? [];

    this.viewAll = new QuoteSupportInboxTable({
      tabName: 'all',
      caption: () => tlang`Current (All)`,
      statusFilter: activeStates,
      typeFilter: BranchQuoteSupportItemType.All,
      getBranchUsers: getBranchUserIds
    });
    this.viewReviews = new QuoteSupportInboxTable({
      tabName: 'quote-reviews',
      caption: () => tlang`%%quote%% Reviews`,
      statusFilter: activeStates,
      typeFilter: BranchQuoteSupportItemType.QuoteReview,
      getBranchUsers: getBranchUserIds,
      columnOptions: { hideRequestTypeColumn: true }
    });
    this.viewSupport = new QuoteSupportInboxTable({
      tabName: 'support-request',
      caption: () => tlang`%%support-request%%`,
      statusFilter: activeStates,
      typeFilter: BranchQuoteSupportItemType.QuoteAssistanceRequest,
      getBranchUsers: getBranchUserIds,
      columnOptions: { hideRequestTypeColumn: true }
    });
    this.viewCustomItems = new QuoteSupportInboxTable({
      tabName: 'special-item-request',
      caption: () => tlang`%%special-item-request%%`,
      statusFilter: activeStates,
      typeFilter: BranchQuoteSupportItemType.EngineeredToOrder,
      getBranchUsers: getBranchUserIds,
      columnOptions: { hideRequestTypeColumn: true }
    });
    this.viewArchived = new QuoteSupportInboxTable({
      tabName: 'archived-items',
      caption: () => tlang`Archived`,
      // eslint-disable-next-line no-bitwise
      statusFilter: BranchQuoteSupportStatus.Cancelled | BranchQuoteSupportStatus.Resolved,
      typeFilter: BranchQuoteSupportItemType.All,
      getBranchUsers: getBranchUserIds,
      columnOptions: { showResolvedColumns: true, hideLastModifiedColumn: true }
    });
  }

  protected getPagesForPageControl(): PageManager[] {
    const list: PageManager[] = [];

    if (this.viewAll) list.push(this.viewAll.createPageManager());
    if (this.viewReviews) list.push(this.viewReviews.createPageManager());
    if (this.viewSupport) list.push(this.viewSupport.createPageManager());
    if (this.viewCustomItems) list.push(this.viewCustomItems.createPageManager());
    if (this.viewArchived) list.push(this.viewArchived.createPageManager());
    return list;
  }

  createPageControl(): PageControl {
    // build static pages for each of the configured table settings
    const getInitialPageManagers = (): PageManager[] => {
      return this.getPagesForPageControl();
    };
    const menu: MenuIconOption[] = [];
    const options: PageControlOptions = {
      defaultTabIndex: 0,
      menuIcons: menu,
      pageInitializer: () => getInitialPageManagers()
    };
    return new PageControl(options);
  }

  async prepareForSave(): Promise<void> {
    //DOES NOTHING HANDLED BY CHILDREN
  }

  public internalDataChanged(): boolean {
    return false;
  }

  protected template(): EventTemplate {
    return html` <div id=${this.elementId} class="page-content">${this.pageControl.ui}</div>`;
  }
}

import { cache } from '../cache-impl/cache-registry';
import { constructAsync } from '../../../webmodule-common/other/async-constructor';
import { EventSnippet } from '../../../webmodule-common/other/ui/events';
import { FranchiseeProjectView } from './views/franchisee-project-view';
import { getProjectTitle } from './data/project-helper-functions';
import { ProjectContainerManager } from '../../projects/data/project-container';
import { ProjectView } from '../../projects/views/project-view';
import { ResourceLocker } from '../common/resource-lock';
import { tlang } from '../../../webmodule-common/other/language/lang';
import { waitPatiently } from '../../../webmodule-common/other/ui/modal-spinner';

export async function projectViewFactory(
  projectContainerManager: ProjectContainerManager,
  title: EventSnippet
): Promise<ProjectView> {
  return await constructAsync(
    new FranchiseeProjectView({
      title: title,
      projectContainerManager: projectContainerManager,
      quoteCache: cache().quote,
      purchaseOrderCache: cache().purchaseOrder
    })
  );
}

export class ProjectLock extends ResourceLocker {
  constructor(id: string, lockRevokedEvent: () => Promise<void>) {
    super(id, 'project', 'project', lockRevokedEvent);
  }
}

export async function createProjectView(containerManager: ProjectContainerManager): Promise<ProjectView> {
  const waiting = waitPatiently(
    () => tlang`Opening %%project%%`,
    () => tlang`Please wait while we construct the %%project%%"`
  );
  let projectEditor: ProjectView | null = null;
  try {
    projectEditor = await projectViewFactory(containerManager, getProjectTitle);
    return projectEditor;
  } finally {
    await waiting.hideModal();
  }
}

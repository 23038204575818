import { clone, compare } from './clone';
import { NullPromise } from '../interop/webmodule-interop';

export class DataProvider<TItem extends object> {
  provider: TItem | (() => TItem);
  updateProviderDataEvent?: (item: TItem) => NullPromise<TItem>;
  constructor(provider: TItem | (() => TItem), updateProviderDataEvent?: (item: TItem) => NullPromise<TItem>) {
    this.provider = provider;
    this.updateProviderDataEvent = updateProviderDataEvent;
  }
  get value(): TItem {
    if (typeof this.provider === 'function') return (this.provider as any)();
    else return this.provider;
  }
  async updateProviderData(item: TItem): NullPromise<TItem> {
    if (this.updateProviderDataEvent) return await this.updateProviderDataEvent(item);

    Object.assign(this.value, item);
    return this.value;
  }
}
export class ProviderBackup<TItem extends object> {
  provider: DataProvider<TItem>;
  item: TItem;
  constructor(provider: DataProvider<TItem>) {
    this.provider = provider;
    this.item = clone(this.provider.value);
  }
  get changed(): boolean {
    return !compare(this.provider.value, this.item);
  }
  async updateProviderData() {
    return await this.provider.updateProviderData(this.item);
  }
  async commitChanges() {
    const result = await this.updateProviderData();
    this.reset();
    return result;
  }
  reset() {
    this.item = clone(this.provider.value);
  }
  pushChangedItem(item: TItem) {
    this.item = clone(item);
  }
}

import { AppResourcePage } from './app-resource-base';
import {
  clientContainerManagerFactory,
  ClientLock,
  createClientView,
  getClientContainer
} from '../clients/client-ui-adaptor';
import { customElement } from 'lit/decorators.js';
import { goStaticURL } from '../../../webmodule-common/other/ui/resource-resolver';
import { isEmptyOrSpace } from '../../../webmodule-common/other/ui/string-helper-functions';
import { ModalViewBase } from '../../../webmodule-common/other/ui/data-entry-screen-base';
import { NullPromise } from '../../../webmodule-common/interop/types/misc';
import { ResourceLocker } from '../common/resource-lock';
import { tlang } from '../../../webmodule-common/other/language/lang';

@customElement('app-client')
export class AppClientPage extends AppResourcePage {
  async redirectPath(): NullPromise<string> {
    return '/clients';
  }

  async createModalEditor(id: string): NullPromise<ModalViewBase> {
    const container = getClientContainer(id);

    if (isEmptyOrSpace(id)) id = container.clientId;
    const qm = clientContainerManagerFactory(container, () => !this.lock?.isLockOwner);
    await qm.needsClient();
    return await createClientView(qm);
  }

  meta() {
    return {
      title: tlang`%%client%% Editor`,
      description: 'SoftTech dealer CPQ'
    };
  }

  protected createLock(id: string): ResourceLocker | undefined {
    return new ClientLock(id, async () => {
      await this.view?.abandonAndClose(true);
      goStaticURL('/clients');
    });
  }
}

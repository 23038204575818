import { html, LitElement } from 'lit';
import { Snippet } from '../../../../webmodule-common/other/ui/events';

export class DashboardWidget extends LitElement {
  protected createRenderRoot(): HTMLElement | DocumentFragment {
    return this;
  }

  protected getHeader(): Snippet {
    return '';
  }

  protected getBody(): Snippet {
    return html``;
  }

  protected htmlEncode(value: string): string {
    return value.replace(/&/g, '&amp;').replace(/</g, '&lt;').replace(/>/g, '&gt;').replace(/"/g, '&quot;');
  }

  protected render(): unknown {
    return html`
      <div class="card dashboard-widget">
        <div class="card-header">${this.getHeader()}</div>
        <div class="card-body">${this.getBody()}</div>
      </div>
    `;
  }
}

import { ApiCommunications, NullPromise } from '../../webmodule-common/interop/webmodule-interop';
import { ClientApi } from './client-api';
import {
  InputCreateClient,
  InputCreateContact,
  InputUpdateClient,
  InputUpdateContact,
  RequestBrowseClientSummary,
  RequestBrowseContactSummary,
  RequestGetClient,
  RequestGetClientListByIds,
  RequestGetClientSummary,
  RequestGetContact,
  ResultBrowseClientSummary,
  ResultBrowseContactSummary,
  ResultCreateClient,
  ResultCreateContact,
  ResultGetClient,
  ResultGetClientListByIds,
  ResultGetClientSummary,
  ResultGetContact,
  ResultUpdateClient,
  ResultUpdateContact
} from './dealer-api-interface-client';
import { ResultGetClientDefaultPaymentType } from './dealer-api-interface-franchisee';

export class DealerClientApi implements ClientApi {
  clientsApiPath = 'api/Client/';
  franchiseeApiPath = 'api/Franchisee/';
  api: ApiCommunications;

  constructor(api: ApiCommunications) {
    this.api = api;
  }

  async browseClientSummary(body: RequestBrowseClientSummary): NullPromise<ResultBrowseClientSummary> {
    return await this.api.post<ResultBrowseClientSummary>(`${this.clientsApiPath}BrowseClientSummary`, body);
  }

  async browseContactSummary(body: RequestBrowseContactSummary): NullPromise<ResultBrowseContactSummary> {
    return await this.api.post<ResultBrowseContactSummary>(`${this.clientsApiPath}BrowseContactSummary`, body);
  }

  async createClient(body: InputCreateClient): NullPromise<ResultCreateClient> {
    return await this.api.post<ResultCreateClient>(`${this.clientsApiPath}CreateClient`, body);
  }

  async createContact(body: InputCreateContact): NullPromise<ResultCreateContact> {
    return await this.api.post<ResultCreateContact>(`${this.clientsApiPath}CreateContact`, body);
  }

  async getClient(body: RequestGetClient): NullPromise<ResultGetClient> {
    return await this.api.post<ResultGetClient>(`${this.clientsApiPath}GetClient`, body);
  }

  async getClientListByIds(body: RequestGetClientListByIds): NullPromise<ResultGetClientListByIds> {
    return await this.api.post<ResultGetClientListByIds>(`${this.clientsApiPath}GetClientListByIds`, body);
  }

  async getClientSummary(body: RequestGetClientSummary): NullPromise<ResultGetClientSummary> {
    return await this.api.post<ResultGetClientSummary>(`${this.clientsApiPath}GetClientSummary`, body);
  }

  async getContact(body: RequestGetContact): NullPromise<ResultGetContact> {
    return await this.api.post<ResultGetContact>(`${this.clientsApiPath}GetContact`, body);
  }

  async updateClient(body: InputUpdateClient): NullPromise<ResultUpdateClient> {
    return await this.api.post<ResultUpdateClient>(`${this.clientsApiPath}UpdateClient`, body);
  }

  async updateContact(body: InputUpdateContact): NullPromise<ResultUpdateContact> {
    return await this.api.post<ResultUpdateContact>(`${this.clientsApiPath}UpdateContact`, body);
  }

  async getClientDefaultType(entityId: string): NullPromise<ResultGetClientDefaultPaymentType> {
    return await this.api.post<ResultGetClientDefaultPaymentType>(
      `${this.franchiseeApiPath}GetClientsDefaultPaymentProfile`,
      {
        entityId
      }
    );
  }
}

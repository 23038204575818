import { branchUsersCache } from '../cache-impl/branch-users-cache';
import { constructAsync } from '../../../webmodule-common/other/async-constructor';
import { customElement, state } from 'lit/decorators.js';
import { disposeOf } from '../../../webmodule-common/other/dispose';
import { getInternalId } from '../../../webmodule-common/other/webmodule-common';
import { html } from 'lit';
import { PageBase } from './helpers/page-base';
import { SupportInboxView } from '../support-inbox/views/support-inbox-view';
import { tlang } from '../../../webmodule-common/other/language/lang';
import { userDataStore } from '../common/current-user-data-store';

@customElement('app-support-inbox')
export class AppSupportInbox extends PageBase {
  @state()
  supportInboxView: SupportInboxView | null = null;
  internalId: string = getInternalId();

  private userCache = branchUsersCache();

  constructor() {
    super();
  }

  meta() {
    return {
      title: tlang`Support Inbox`,
      description: tlang`Support Inbox`
    };
  }

  private get elementId(): string {
    return `support-inbox-page-${this.internalId}`;
  }

  protected async franchiseeInformationFactory(): Promise<SupportInboxView> {
    return await constructAsync(new SupportInboxView());
  }

  async dispose() {
    await super.dispose();
    await disposeOf(this.supportInboxView);
    this.supportInboxView = null;
  }

  protected async afterUserConnected() {
    await userDataStore.reLoadFranchisee();
    this.supportInboxView = await this.franchiseeInformationFactory();
  }

  render() {
    if (!this.loggedIn) return html``;
    return html`
      <div id=${this.elementId} class="page-content">
        <div class="page-container">${this.supportInboxView?.ui}</div>
      </div>
    `;
  }

  protected async awaken(): Promise<void> {
    await this.userCache.prefetch([userDataStore.defaultBranch.id]);
  }
}

import { cache } from '../../cache-impl/cache-registry';
import { customElement } from 'lit/decorators.js';
import { emptyGuid } from '../../../../webmodule-common/other/api/guid';
import {
  getPurchaseOrderNumberFormatted,
  getPurchaseOrderStatus
} from '../../../purchase-orders/data/purchase-order-helper-functions';
import { html, TemplateResult } from 'lit';
import { moneyToTemplateResult } from '../../../../webmodule-common/other/currency-formatter';
import { PurchaseOrder, ResultGetPurchaseOrders } from '../../../api/dealer-api-interface-franchisee';
import {
  PurchaseOrderSummaryTable,
  PurchaseOrderSummaryTableOptions
} from '../../../purchase-orders/views/purchase-order-summary-table';
import { resolveURL } from '../../../../webmodule-common/other/ui/resource-resolver';
import { resourceProject } from '../../../projects/ui/launcher';
import { resourceQuote } from '../../../quotes/ui/launcher';
import { staleIcon } from '../../../../webmodule-common/other/ui/icons/icon-notification-signal';
import { tlang } from '../../../../webmodule-common/other/language/lang';
import type {
  WebModuleLitTable,
  WebModuleLitTableColumnDef
} from '../../../../webmodule-common/components/src/webmodule-components';

@customElement('wm-franchiseepurchaseordersummarytable')
export class FranchiseePurchaseOrderSummaryTable extends PurchaseOrderSummaryTable {
  purchaseOrderCache = cache().purchaseOrder;
  quoteCache = cache().quote;
  projectCache = cache().project;
  prlCache = cache().projectResourceLink;
  userCache = cache().userProfile;

  constructor(options: PurchaseOrderSummaryTableOptions) {
    super(options);
  }

  protected override getPreFetched(results: ResultGetPurchaseOrders): Promise<void>[] {
    const basePreFetch = super.getPreFetched(results);

    const purchaseOrderKeys = results.purchaseOrders.results?.map(x => x.id) ?? [];

    const purchaseOrderCachePromise = this.purchaseOrderCache.preFetch(purchaseOrderKeys);

    basePreFetch.push(purchaseOrderCachePromise);

    const userKeys = results.purchaseOrders.results?.map(x => x.assignedToUserId);
    const userProfilesPreFetch = this.userCache.preFetch(userKeys);
    basePreFetch.push(userProfilesPreFetch);

    return basePreFetch;
  }

  protected override async doPreFetching(results: ResultGetPurchaseOrders): Promise<void> {
    await super.doPreFetching(results);

    const poKeys = results.purchaseOrders.results.map(x => x.id);
    await this.purchaseOrderCache.getMany(poKeys);
    const links = await this.prlCache.getMany(poKeys);

    const quoteKeys = links?.map(x => x.data.quoteId) ?? [];
    const projectKeys = links?.map(x => x.data.projectId) ?? [];

    await Promise.all([this.quoteCache.preFetch(quoteKeys), this.projectCache.preFetch(projectKeys)]);
  }

  private getQuoteLink(po: PurchaseOrder): TemplateResult {
    const prl = this.prlCache.getLocalData(po.id);
    const quoteTitle = prl ? (this.quoteCache.getLocalData(prl.quoteId)?.quoteSummary.title ?? '') : '';

    return prl
      ? html`<a class="quote-link" href="${resolveURL(resourceQuote, prl.quoteId)}" data-quote-id="${prl?.quoteId}"
          >${quoteTitle}</a
        >`
      : html`<span class="quote-link">${quoteTitle}</span>`;
  }

  private getProjectLink(po: PurchaseOrder): TemplateResult {
    const prl = this.prlCache.getLocalData(po.id);
    const title = prl ? (this.projectCache.getLocalData(prl.projectId)?.projectSummary.title ?? '') : '';

    return prl
      ? html`<a
          class="project-link"
          href="${resolveURL(resourceProject, prl.projectId)}"
          data-project-id="${prl.projectId}"
          >${title}</a
        >`
      : html`<span class="project-link">${title}</span>`;
  }

  stale(row: PurchaseOrder) {
    return this.staleData.exists(row.id);
  }

  getColumns(): WebModuleLitTableColumnDef[] {
    return [
      {
        title: tlang`System %%purchase-order-abrev%% No.`,
        fieldName: 'purchaseOrderNumber',
        sortable: true,
        classes: 'colpxmax-140 purchase-order-number',
        displayValue: (_table: WebModuleLitTable, item: unknown) => {
          const purchaseOrder = item as PurchaseOrder;
          return this.stale(purchaseOrder)
            ? // eslint-disable-next-line @typescript-eslint/no-base-to-string
              `${staleIcon(false)}${this.getPurchaseOrderLink(purchaseOrder, getPurchaseOrderNumberFormatted(purchaseOrder))}`
            : this.getPurchaseOrderLink(purchaseOrder, getPurchaseOrderNumberFormatted(purchaseOrder));
        }
      },
      {
        title: tlang`%%franchisee%% %%purchase-order-abrev%% No.`,
        fieldName: 'customPurchaseOrderNumber',
        sortable: true,
        classes: 'colpxmax-140 dealer-purchase-order-number',
        displayValue: (_table: WebModuleLitTable, item: unknown) => {
          const purchaseOrder = item as PurchaseOrder;
          return html`${purchaseOrder.customPurchaseOrderNumber}`;
        }
      },
      {
        title: tlang`%%purchase-order%% Title`,
        fieldName: 'title',
        sortable: true,
        classes: 'colpx-140 purchase-order-title',
        displayValue: (_table: WebModuleLitTable, item: unknown) => {
          const purchaseOrder = item as PurchaseOrder;
          return this.getPurchaseOrderLink(purchaseOrder, purchaseOrder.title);
        }
      },
      {
        title: tlang`%%project%% Title`,
        fieldName: 'id',
        classes: 'colpx-100 purchase-order-project-title',
        displayValue: (_table: WebModuleLitTable, item: unknown) => {
          const purchaseOrder = item as PurchaseOrder;
          return this.getProjectLink(purchaseOrder);
        }
      },
      {
        title: tlang`%%quote%% Title`,
        fieldName: 'id',
        classes: 'colpx-100 purchase-order-quote-title',
        displayValue: (_table: WebModuleLitTable, item: unknown) => {
          const purchaseOrder = item as PurchaseOrder;
          return this.getQuoteLink(purchaseOrder);
        }
      },
      {
        title: tlang`%%author%%`,
        fieldName: 'assignedToUserId',
        classes: 'colpx-100 purchase-order-author',
        displayValue: (_table: WebModuleLitTable, item: unknown) => {
          const purchaseOrder = item as PurchaseOrder;
          return this.getUserDisplayValue(purchaseOrder.assignedToUserId);
        }
      },
      {
        title: tlang`Last Modified`,
        fieldName: 'lastModifiedDate',
        classes: 'colpx-100 purchase-order-last-modified-date',
        displayValue: (_table: WebModuleLitTable, item: unknown) => {
          const purchaseOrder = item as PurchaseOrder;
          const dt = new Date(purchaseOrder.lastModifiedDate ?? purchaseOrder.dateCreated);
          return `${dt.toLocaleDateString()} ${dt.toLocaleTimeString()}`;
        }
      },
      {
        title: tlang`Status`,
        fieldName: 'state',
        classes: 'colpxmax-140 purchase-order-state no-pseudo',
        displayValue: (_table: WebModuleLitTable, item: unknown) => {
          const purchaseOrder = item as PurchaseOrder;
          return getPurchaseOrderStatus(purchaseOrder.state, true);
        }
      },
      {
        title: tlang`Net Cost`,
        fieldName: 'calculatedNetTotal',
        classes: 'colpxmax-140 alignment-right purchase-order-total no-pseudo',
        displayValue: (_table: WebModuleLitTable, item: unknown) => {
          const purchaseOrder = item as PurchaseOrder;
          return moneyToTemplateResult(purchaseOrder.calculatedNetTotal + purchaseOrder.calculatedAdjustmentTotal);
        }
      }
    ];
  }

  protected getUserDisplayValue(value: string): TemplateResult {
    if (value == emptyGuid) return html``;
    //prefetch should already have been called
    return html`${this.userCache.getLocal(value)?.displayValue ?? ''}`;
  }
}

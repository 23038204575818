import { emptyGuid } from '../../../../webmodule-common/other/api/guid.js';
import { flagInSet } from '../../../../webmodule-common/other/ui/string-helper-functions.js';
import { FranchiseeQuoteContainerManager } from './franchisee-quote-manager.js';
import { getCurrentUser } from '../../../../webmodule-common/other/api/current-user.js';
import {
  IUserClaims,
  supplierHasFullPrivelages,
  userClaims
} from '../../../../webmodule-common/other/currentuser-claims.js';
import { IUserQuoteStateEngine } from '../../../quotes/data/quote-state-engine.js';
import { Quote, QuoteSetSibling, QuoteState } from '../../../api/dealer-api-interface-quote.js';
import { userSecurity } from '../../../../webmodule-common/other/api/user-security.js';

export function getQuoteStateEngine(quoteManager: FranchiseeQuoteContainerManager,
                                    useSupplierReviewProcess: boolean = true): IUserQuoteStateEngine {
  return new UserQuoteStateEngine(quoteManager, useSupplierReviewProcess);
}

export class QuoteSetSiblingManager {
  _siblings: () => QuoteSetSibling[];
  quote: Quote | undefined;
  constructor(siblings: () => QuoteSetSibling[], quote?: Quote) {
    this.quote = quote;
    this._siblings = siblings;
  }
  get siblings() {
    return this._siblings();
  }

  private hasState(state: QuoteState) {
    if (this.quote && this.quote.state === state) return true;
    const sibling = this.siblings.filter(x => x.state === state);
    return sibling.length > 0;
  }

  public get isNewQuoteAllowed() {
    return !this.workflowBusy && !this.supplierEditWorkflowBusy;
  }
  public get isAccepted() {
    return this.hasState(QuoteState.Accepted) || this.hasState(QuoteState.Accepted_AssignedToReviewer);
  }
  public get isApproved() {
    return this.hasState(QuoteState.Approved);
  }
  public get isReviewPending() {
    return this.hasState(QuoteState.SupplierReviewPending);
  }
  public get isReviewed() {
    return this.hasState(QuoteState.SupplierReviewed);
  }
  public get workflowBusy() {
    return this.isApproved || this.isReviewed || this.isReviewPending || this.isSupplierIssued;
  }
  public get supplierEditWorkflowBusy() {
    return this.isAccepted || this.isApproved || this.isReviewed || this.isSupplierIssued;
  }
  public get isSupplierIssued() {
    const sibling = this.siblings.filter(x => x.state === QuoteState.Issued && x.supplierApproved);
    return sibling.length > 0;
  }
  public get canIssueActive() {
    return !this.isAccepted && !this.isApproved && !this.isReviewPending && !this.isReviewed;
  }
}

export class UserQuoteStateEngine implements IUserQuoteStateEngine {
  qcm: FranchiseeQuoteContainerManager;
  userClaims: IUserClaims = userClaims(getCurrentUser()?.id ?? emptyGuid, userSecurity());
  sibMan: QuoteSetSiblingManager;
  private _useSupplierReviewProcess: boolean;

  constructor(qcm: FranchiseeQuoteContainerManager, useSupplierReviewProcess: boolean) {
    this.qcm = qcm;
    this._useSupplierReviewProcess = useSupplierReviewProcess;
    this.sibMan = new QuoteSetSiblingManager(() => this.qcm.siblings ?? []);
  }

  isAnyStateChangeAllowed(states?: QuoteState[]): boolean {
    if (states === undefined) {
      return (
        this.isStateChangeAllowed(QuoteState.Accepted) ||
        this.isStateChangeAllowed(QuoteState.Accepted_AssignedToReviewer) ||
        this.isStateChangeAllowed(QuoteState.Accepted_RefusedBySupplier) ||
        this.isStateChangeAllowed(QuoteState.Active) ||
        this.isStateChangeAllowed(QuoteState.Approved) ||
        this.isStateChangeAllowed(QuoteState.IssuePending) ||
        this.isStateChangeAllowed(QuoteState.SupplierReviewPending) ||
        this.isStateChangeAllowed(QuoteState.SupplierReviewed)
      );
    }

    for (const state of states) {
      if (this.isStateChangeAllowed(state)) return true;
    }
    return false;
  }

  isQuoteEditable(): boolean {
    const isSupplierAgent = this.userClaims.isAgent;
    const currentState = this.qcm.quoteState;

    return isSupplierAgent ? this.canSupplierEditQuote(currentState) : this.canDealerEditQuote(currentState);
  }

  canDealerEditQuote(currentState: QuoteState): boolean {
    return (
      // eslint-disable-next-line no-bitwise
      flagInSet(currentState, QuoteState.Draft | QuoteState.Active) &&
      !this.sibMan.workflowBusy &&
      !this.sibMan.isAccepted
    );
  }

  canSupplierEditQuote(currentState: QuoteState): boolean {
    let allowed =
      !this.sibMan.supplierEditWorkflowBusy &&
      // eslint-disable-next-line no-bitwise
      flagInSet(currentState, QuoteState.Active | QuoteState.SupplierReviewPending | QuoteState.Draft);
    if (supplierHasFullPrivelages()) {
      allowed = allowed || this.canDealerEditQuote(currentState);
    }
    return allowed;
  }
  isStateChangeBlocked(state: QuoteState, originalState?: QuoteState): boolean {
    const allowed = this.isStateChangeAllowed(state, originalState);
    return !allowed;
  }

  private canSupplierChangeState(state: QuoteState, currentState: QuoteState): boolean {
    let canEdit = false;
    switch (state) {
      case QuoteState.Approved:
      case QuoteState.Rejected:
      case QuoteState.SupplierReviewPending:
        //Supplier can only Approve an accepted quote if there are no other quotes higher
        //up the food chain. those higher up ones must be placed into rejected or cancelled
        //before a supplier can approve a new one
        canEdit =
          !this.sibMan.workflowBusy &&
          // eslint-disable-next-line no-bitwise
          flagInSet(currentState, QuoteState.Accepted | QuoteState.Accepted_AssignedToReviewer);
        break;
      case QuoteState.SupplierReviewed:
        canEdit = !this.sibMan.workflowBusy && currentState == QuoteState.SupplierReviewPending;
        break;
      case QuoteState.Accepted_AssignedToReviewer:
        canEdit = currentState === QuoteState.Accepted && !this.qcm.quote.supplierApproved;
        break;
    }

    if (supplierHasFullPrivelages()) {
      canEdit = canEdit || this.canDealerChangeState(state, currentState);
    }

    return canEdit;
  }

  private canDealerChangeState(state: QuoteState, currentState: QuoteState): boolean {
    switch (state) {
      case QuoteState.Draft:
        return false;
      case QuoteState.Active:
        //we have to make sure we cannot "Add" a quote to a quoteset that is finalized or in a progressive state
        return !this.sibMan.workflowBusy && currentState === QuoteState.Draft;
      case QuoteState.IssuePending:
        return (
          !this.sibMan.isApproved &&
          !this.sibMan.isReviewPending &&
          !this.sibMan.isAccepted &&
          ((currentState === QuoteState.Active && !this.sibMan.isReviewed) ||
            currentState === QuoteState.SupplierReviewed)
        );
      case QuoteState.Accepted:
        //Normal dealer can mark on as accepted as long as no other item in the quote set can do it
        return (
          //we can only accept a quote in a quote set, if no other
          //quote has been elevated
          !this.sibMan.workflowBusy &&
          !this.sibMan.isAccepted &&
          //only allow accepted from the original pre-review issued state
          currentState === QuoteState.Issued &&
          !this.qcm.quote.supplierApproved
        );

      case QuoteState.Approved:
        return (
          !this.sibMan.workflowBusy &&
          //Workflow coming from a 1000-R01 reviewed item
          //that was re-issued, and accepted, or re-issued
          //does not require a new review process.
          //can go direct to approved.
          ((currentState === QuoteState.Issued && this.qcm.quote.supplierApproved) ||
            (currentState === QuoteState.Accepted && this.qcm.quote.supplierApproved) ||
            //This is a quote that does not have any supplier
            //product. it is a quote without a purchase order
            //and doesn't need review
            (currentState === QuoteState.Accepted && !this.qcm.isSupplierQuote)
            || (currentState === QuoteState.Issued && !this._useSupplierReviewProcess)
          )
        );
      case QuoteState.Cancelled:
        return (
          (!this.sibMan.isApproved && currentState === QuoteState.SupplierReviewed) ||
          (!this.sibMan.workflowBusy && !this.sibMan.isAccepted && currentState == QuoteState.Active)
        );
      case QuoteState.Rejected:
        return !this.sibMan.workflowBusy && currentState == QuoteState.Issued;
    }
    return false;
  }
  isStateChangeAllowed(state: QuoteState, originalState?: QuoteState): boolean {
    //const isAdmin = this.userClaims.isAdmin;
    const isSupplierAgent = this.userClaims.isAgent;
    const currentState = originalState ?? this.qcm.quoteState;

    const allowed = isSupplierAgent
      ? this.canSupplierChangeState(state, currentState)
      : this.canDealerChangeState(state, currentState);

    return allowed;
  }
}

import { customElement, state } from 'lit/decorators.js';
import { DataEntryPageControlView } from '../../../../webmodule-common/other/ui/data-entry-screen-base.js';
import { emptyGuid } from '../../../../webmodule-common/other/api/guid.js';
import { EventTemplate } from '../../../../webmodule-common/other/ui/events.js';
import { FieldType } from '../../../../webmodule-common/other/ui/databinding/data-tracker.js';
import { FormInputAssistant } from '../../../../webmodule-common/other/ui/templateresult/form-input-assistant.js';
import { getApiFactory } from '../../../api/api-injector.js';
import { getQuoteSuppliers, isMultiSupplier, QuoteSupplier } from '../../../quotes/quote-service.js';
import { isEmptyOrSpace, validId } from '../../../../webmodule-common/other/ui/string-helper-functions.js';
import {
  PaymentProfileCalculation,
  ResultGetSupplierPricingRule
} from '../../../api/dealer-api-interface-franchisee.js';
import {
  PaymentProfileDetailView,
  PaymentProfileDetailViewOptions
} from '../../../franchisee/view/payment-profile-detail-view.js';
import { Snippet } from '../../../../webmodule-common/interop/types/misc.js';
import { tlang } from '../../../../webmodule-common/other/language/lang.js';
import { pricingModelType } from '../../../../webmodule-common/pricing/pricing-model';
import { html } from 'lit';

@customElement('wm-franchiseepaymentprofiledetailview')
export class FranchiseePaymentProfileDetailView extends PaymentProfileDetailView {
  protected supplierApi = getApiFactory().supplier();
  @state()
  protected suppliers: QuoteSupplier[] = [];
  @state()
  private _supplierIdForMarginTemplate?: string;
  @state()
  private _supplierPricingRule: ResultGetSupplierPricingRule | null = null;

  constructor(owner: DataEntryPageControlView, options: PaymentProfileDetailViewOptions) {
    super(owner, options);

    const addField = (
      fieldName: string,
      propertyType?: FieldType,
      nullable?: boolean,
      editorFieldName?: string,
      data?: () => any
    ) => {
      this.dataTracker.addObjectBinding(
        data ?? (() => this.paymentProfile),
        fieldName,
        editorFieldName ?? fieldName,
        propertyType ?? FieldType.string,
        nullable ?? false
      );
    };

    addField('supplierId');
  }

  async afterConstruction(): Promise<void> {
    await super.afterConstruction();
    await this.updatePricingRules();
  }

  public async loadOrRefresh(): Promise<void> {
    await super.loadOrRefresh();
    this.suppliers = await getQuoteSuppliers();
    if (!isMultiSupplier() && this.isNew()) {
      this.paymentProfile.supplierId = this.suppliers.length == 1 ? this.suppliers[0].supplierId : emptyGuid;
      await this.updatePricingRules();
      this.paymentProfileManager.resetBackup();
    }
  }

  public getValidationErrors(): string[] {
    const errors = super.getValidationErrors();

    if (isEmptyOrSpace(this.paymentProfile.supplierId)) {
      errors.push(tlang`Please Select a %%supplier%%.`);
    }

    return errors;
  }

  protected isNew(): boolean {
    return this.paymentProfile.id == emptyGuid;
  }

  protected getCaption(): Snippet {
    return tlang``;
  }

  protected bodyTemplate(): EventTemplate {
    const forms = new FormInputAssistant(this.dataTracker);
    forms.immediateBindingUpdate = true;

    if (isMultiSupplier()) {
      this.suppliers.unshift({ description: '', supplierId: emptyGuid, online: false });
    }

    const supplierConverter = (qs: QuoteSupplier) => {
      return {
        text: tlang`${qs.description}`,
        value: qs.supplierId,
        disabled: false
      };
    };

    const isSupplierSelectorReadOnly = this.paymentProfile.supplierId != emptyGuid && !this.isNew();
    const supplierSelectorControl = isMultiSupplier()
      ? forms.arraySelect('supplierId', this.suppliers, supplierConverter, {
          title: tlang`%%supplier%%`,
          readonly: isSupplierSelectorReadOnly,
          events: { change: _ => this.onSupplierSelected() }
        })
      : forms.textHidden('supplierId');

    return html`
      <form class="frm-client-details form-one-col">
        <div class="row">
          <div>
            ${forms.textRequired('name', tlang`%%payment-profile%%`, 100)} ${supplierSelectorControl}
            ${forms.radioGroup('calculation', PaymentProfileCalculation, tlang`Calculation`, {
              events: { change: this.eventCalculationChanged }
            })}
            ${forms.floatRequired(
              'value',
              `Value (%)`,
              this.pricingModel.min,
              this.pricingModel.max === -1 ? undefined : this.pricingModel.max
            )}
            ${forms.note('quoteTerms', tlang`%%quote%% Terms and Conditions`, 5000)}
          </div>
        </div>
      </form>
    `;
  }

  protected onSupplierSelected() {
    this.updatePricingRules();
  }

  private eventCalculationChanged: (e: Event) => void = async () => {
    await this.updatePricingRules();
    this.requestUpdate();
  };

  private async updatePricingRules() {
    this.pricingModel.update({
      min: null,
      max: null
    });

    if (!validId(this.paymentProfile.supplierId)) return;
    if (this._supplierIdForMarginTemplate !== this.paymentProfile.supplierId) {
      this._supplierIdForMarginTemplate = this.paymentProfile.supplierId;
      this._supplierPricingRule = await this.supplierApi.getSupplierPricingRule({
        supplierId: this.paymentProfile.supplierId
      });
    }
    const isMargin = this.paymentProfile.calculation === pricingModelType.margin;
    if (this._supplierPricingRule) {
      const pricingRule = this._supplierPricingRule.pricingRule;

      if (this._supplierPricingRule.pricingRuleType.hasMinMax) {
        this.pricingModel.update({
          calculation: this.paymentProfile.calculation,
          min: isMargin ? pricingRule.minValue : pricingRule.minMarkup,
          max: isMargin ? pricingRule.maxValue : pricingRule.maxMarkup
        });
      }

      if (!this.pricingModel.isValid(this.paymentProfile.value)) {
        this.paymentProfile.value = isMargin ? pricingRule.minValue : pricingRule.minMarkup;
      }
    }
  }
}

import { customElement } from 'lit/decorators.js';
import { DataCacheGeneric } from '../../../webmodule-common/cache/generic-data-cache';
import { DevelopmentError } from '../../../webmodule-common/other/development-error';
import { EventQuoteItemContainerAction } from '../data/events';
import { EventTemplate } from '../../../webmodule-common/other/ui/events';
import { html, nothing } from 'lit';
import { QuoteContainerManager } from '../data/quote-container';
import { ViewBase } from '../../../webmodule-common/other/ui/view-base';
import type { TableColumnConfiguration } from '../../api/dealer-api-interface-franchisee';
import type { IUserSettings } from '../../../webmodule-common/other/context/IUserSettings';
import { userLocalSettings } from '../../../webmodule-common/other/context/UserSettingsContext';
import { internalQuoteItemFields } from '../data/quote-helper-functions';
import { currentUserClaims } from '../../../webmodule-common/other/currentuser-claims';

export interface QuoteItemsViewOptions {
  quoteManager: QuoteContainerManager;
  userProfileCache: DataCacheGeneric;
  eventRunQuoteItemAction: EventQuoteItemContainerAction;
}

interface QuoteItemUserPageSettings {
  isClientFacingView: boolean;
  clientFacingConfiguration?: TableColumnConfiguration[];
  internalConfiguration?: TableColumnConfiguration[];
}

export class SettingsManager {
  private readonly configSaveKey = 'quote-item-summary-user-configuration';
  private userSettingsContext: IUserSettings = userLocalSettings;

  //This is set in the loadSetting that is called from constructor
  private _settings!: QuoteItemUserPageSettings;
  private _activeConfig?: TableColumnConfiguration[];
  private _systemDefault: TableColumnConfiguration[];
  private _supplierOverride: TableColumnConfiguration[];

  constructor(systemDefaults: TableColumnConfiguration[], supplierOverride: TableColumnConfiguration[]) {
    this._systemDefault = systemDefaults;
    this._supplierOverride = supplierOverride;

    this.loadSettings();
  }

  public get getSettings(): QuoteItemUserPageSettings {
    return this._settings;
  }

  public get getIsClientFacing(): boolean {
    return this._settings.isClientFacingView;
  }

  public get getActiveConfiguration() {
    if (this._activeConfig) return this._activeConfig;
    //This contains all columns available
    let cols = this._systemDefault;

    cols = cols.map(x => {
      const override = this.getIsClientFacing && internalQuoteItemFields.find(y => y == x.code);

      if (override) {
        return { ...x, canSwitch: false, defaultDisplay: false };
      }

      return x;
    });

    // Override system default with supplier defaults
    if (this._supplierOverride) {
      cols = cols.map(x => {
        const override = this._supplierOverride.find(y => y.code == x.code);

        //Supplier should always be able to toggle options, ie restrictions apply to dealer user.
        if (override) {
          const canUserChange = currentUserClaims().isAgent || override.canSwitch;
          return { ...x, canSwitch: canUserChange, defaultDisplay: override.defaultDisplay };
        }

        return x;
      });
    }

    const userOverrides = this.getIsClientFacing
      ? this._settings.clientFacingConfiguration
      : this._settings.internalConfiguration;

    if (userOverrides) {
      cols = cols.map(x => {
        const override = userOverrides.find(y => y.code == x.code && x.canSwitch);

        if (override) {
          return { ...x, defaultDisplay: override.defaultDisplay };
        }

        return x;
      });
    }
    this._activeConfig = cols;
    return this._activeConfig;
  }

  public updateConfiguration(newSettings: TableColumnConfiguration | TableColumnConfiguration[]): void {
    if (this._settings.isClientFacingView) {
      this._settings.clientFacingConfiguration = this.overrideConfiguration(
        this._settings.clientFacingConfiguration,
        newSettings
      );
    } else {
      this._settings.internalConfiguration = this.overrideConfiguration(
        this._settings.internalConfiguration,
        newSettings
      );
    }
    this.saveSettings();
  }

  public toggleColumnDisplay(code: string) {
    const config = this.getActiveConfiguration.find(x => x.code == code);

    if (config) {
      config.defaultDisplay = !config?.defaultDisplay;

      this.updateConfiguration(config);
      this.saveSettings();
    }
  }

  public toggleClientFacingView() {
    this._settings.isClientFacingView = !this._settings.isClientFacingView;
    this.saveSettings();
  }

  private overrideConfiguration(
    // eslint-disable-next-line @typescript-eslint/default-param-last
    currentConfig: TableColumnConfiguration[] = [],
    newSettings: TableColumnConfiguration | TableColumnConfiguration[]
  ): TableColumnConfiguration[] {
    if (!Array.isArray(newSettings)) {
      newSettings = [newSettings];
    }

    const currentMap = new Map(currentConfig.map(x => [x.code, x]));
    newSettings.forEach(x => currentMap.set(x.code, x));

    return Array.from(currentMap.values());
  }

  private saveSettings() {
    this.userSettingsContext.saveSetting<QuoteItemUserPageSettings>(this.configSaveKey, this._settings);
    this._activeConfig = undefined;
  }

  private loadSettings() {
    this._settings =
      this.userSettingsContext.getSetting<QuoteItemUserPageSettings>(this.configSaveKey) ??
      new (class implements QuoteItemUserPageSettings {
        clientFacingConfiguration: TableColumnConfiguration[] = [];
        internalConfiguration: TableColumnConfiguration[] = [];
        isClientFacingView = false;
      })();
  }
}

//saving buttons etc.
@customElement('wm-quoteitemsview')
export class QuoteItemsView extends ViewBase {
  protected readonly quoteManager: QuoteContainerManager;
  protected readonly eventRunQuoteItemActions: EventQuoteItemContainerAction;
  protected settingsManager?: SettingsManager;

  constructor(options: QuoteItemsViewOptions) {
    super();
    this.quoteManager = options.quoteManager;
    this.eventRunQuoteItemActions = options.eventRunQuoteItemAction;
  }

  public get isClientFacing(): boolean {
    return this.settingsManager?.getIsClientFacing ?? false;
  }

  public async prepareForSave() {
    throw new DevelopmentError('quote-items-view, Method not implemented.');
  }

  getValidationErrors(): string[] {
    return [];
  }

  public async refreshData(): Promise<void> {
    //this is a force reload of the data. This is not something that we want to do, if items are inuse
    //as we would end up with a bad loading of data.
  }

  public async invalidate(): Promise<void> {
    this.requestUpdate();
  }

  protected template(): EventTemplate {
    return html`${nothing}`;
  }
}

import { clone, compare } from '../../../webmodule-common/other/clone';
import { EventNotify } from '../../../webmodule-common/other/ui/events';
import { FranchiseeApi } from '../../api/franchisee-api';
import { getApiFactory } from '../../api/api-injector';
import { isEmptyOrSpace } from '../../../webmodule-common/other/ui/string-helper-functions';
import { runEventNotify } from '../../../webmodule-common/other/array-helper';
import { StockItem } from '../../api/dealer-api-interface-franchisee';

export class StockManager {
  api: FranchiseeApi = getApiFactory().franchisee();
  items: StockItem[] | null;
  itemsBackup: StockItem[] | null = null;
  showTab = false;
  afterSave: EventNotify[] = [];
  refreshItemsFlag = true;

  constructor(items: StockItem[] | null) {
    this.items = items;
  }

  async needsStockItems(searchTerm?: string): Promise<boolean> {
    if (!this.items || searchTerm || this.refreshItemsFlag) {
      const filterTerm = searchTerm ? searchTerm : null;
      const catalogueItems = await this.api.getStock({ filter: filterTerm });

      if (!catalogueItems) return false;

      this.refreshItemsFlag = false;
      await this.reset(catalogueItems.stockItems);
    }

    await this.evalVisibility();
    return true;
  }

  private async reset(stockItems: StockItem[]) {
    this.items = stockItems;
    this.itemsBackup = clone(stockItems);
  }

  private async evalVisibility() {
    this.showTab = this.items != null && this.items.length >= 1;
  }

  async saveStockItems() {
    if (!this.items) return false;

    const updateResult = await this.api.updateStock({
      stock: this.items
    });

    if (!updateResult) return false;

    await this.reset(updateResult.stockItems);
    await this.evalVisibility();
    await this.doAfterSave();
    return true;
  }

  getStockItem(itemId: string) {
    return this.items?.find(x => {
      return x.id == itemId;
    });
  }

  updateStockItem(itemId: string, description?: string, unitCost?: number | null, code?: string) {
    if ((description == undefined && unitCost == undefined && code == undefined) || !this.items) return;

    const index = this.items?.findIndex(x => x.id == itemId) ?? null;

    if (index < 0) return;

    if (description != undefined) this.items[index].description = description;

    if (unitCost != undefined) this.items[index].singleUnitCost = unitCost;
  }

  changed(): boolean {
    return !compare(this.itemsBackup, this.items);
  }

  reportValidation(): boolean {
    return (
      !this.items?.some(
        stockItem => isEmptyOrSpace(stockItem.description) || (stockItem.singleUnitCost && stockItem.singleUnitCost < 0)
      ) ?? true
    );
  }

  getValidation() {
    return (
      this.items
        ?.filter(
          stockItem =>
            isEmptyOrSpace(stockItem.description) || (stockItem.singleUnitCost && stockItem.singleUnitCost < 0)
        )
        .flatMap(stockItem => {
          const errors: { id: string; code: string; property: string; message: string }[] = [];

          if (isEmptyOrSpace(stockItem.description))
            errors.push({
              id: stockItem.id,
              code: stockItem.code,
              property: 'description',
              message: 'Please provide a description'
            });

          if (stockItem.singleUnitCost && stockItem.singleUnitCost <= 0)
            errors.push({
              id: stockItem.id,
              code: stockItem.code,
              property: 'singleUnitCost',
              message: 'Please provide a single unit price more of at least 0 or more'
            });

          return errors;
        }) ?? []
    );
  }

  private async doAfterSave(): Promise<void> {
    await runEventNotify(this.afterSave);
  }
}

// eslint-disable-next-line import/named
import { customElement } from 'lit/decorators.js';
import { getInternalId } from '../../../webmodule-common/other/ui/databinding/databinding';
import { ViewBase } from '../../../webmodule-common/other/ui/view-base';

@customElement('wm-pageview')
export class PageView extends ViewBase {
  protected internalId: string;

  constructor() {
    super();

    this.internalId = getInternalId();
  }
}

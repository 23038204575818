import { asMarkdownTemplate } from '../../../webmodule-common/other/general/markdown';
import { customElement } from 'lit/decorators.js';
import { DataBinding } from '../../../webmodule-common/other/ui/databinding/databinding';
import { DataProvider, ProviderBackup } from '../../../webmodule-common/other/data-provider';
import { EventTemplate, Snippet } from '../../../webmodule-common/other/ui/events';
import { fireQuickSuccessToast, fireToastError } from '../../../webmodule-common/other/toast-away';
import { Franchisee } from '../../api/dealer-api-interface-franchisee';
import { FranchiseeApi } from '../../api/franchisee-api';
import { getApiFactory } from '../../api/api-injector';
import { html } from 'lit';
import { uploadVirtualFile, VirtualFile } from '../data/image-upload';
import { isAutoSaving } from '../../../webmodule-common/other/save-workflow';
import { PageControlTabWithIndependantSaving } from '../../../webmodule-common/other/ui/data-entry-screen-base';
import { tlang } from '../../../webmodule-common/other/language/lang';
import { userSecurity } from '../../../webmodule-common/other/api/user-security';
import { FormInputAssistant } from '../../../webmodule-common/other/ui/templateresult/form-input-assistant';
import { DataTracker } from '../../../webmodule-common/other/ui/databinding/data-tracker';
import { getAssetCdnPath } from '../../../webmodule-common/other/common/assets';
import { fileIsImageFile } from '../../../webmodule-common/other/ui/file-helper-functions';
import { isSomething } from '../../../webmodule-common/other/general/nothing';
import { isEmptyOrSpace } from '../../../webmodule-common/other/ui/string-helper-functions';

@customElement('wm-franchiseebrandingview')
export class FranchiseeBrandingView extends PageControlTabWithIndependantSaving {
  private franchiseeApi: FranchiseeApi = getApiFactory().franchisee();
  private franchiseeBackup: ProviderBackup<Franchisee>;
  private dataBinding: DataBinding;

  private dataTracker: DataTracker;
  private positiveLogoName = 'positive-logo';
  private negativeLogoName = 'negative-logo';
  private newPositiveLogo?: File;
  private newNegativeLogo?: File;

  constructor(franchiseeDataProvider: DataProvider<Franchisee>) {
    super();
    this.pageFragment = 'branding';
    this.franchiseeBackup = new ProviderBackup<Franchisee>(franchiseeDataProvider);

    this.dataBinding = new DataBinding(this.ui, this.elementId, input => {
      return `${input}-${this.elementId}`;
    });
    this.dataTracker = new DataTracker(this.dataBinding);
    this.dataTracker.addImageLink(
      this.positiveLogoName,
      () => this.getAbsoluteVirtualFilePath(this.franchisee.positiveLogoVirtualPath),
      value => {
        this.newPositiveLogo = value;
      }
    );

    this.dataTracker.addImageLink(
      this.negativeLogoName,
      () => this.getAbsoluteVirtualFilePath(this.franchisee.negativeLogoVirtualPath),
      value => {
        this.newNegativeLogo = value;
      }
    );
  }

  protected async revertChanges(): Promise<boolean> {
    this.newNegativeLogo = undefined;
    this.newNegativeLogo = undefined;

    return Promise.resolve(true);
  }

  get isAdmin() {
    return userSecurity().isAdmin() || userSecurity().isSupplier();
  }

  public isDataReadonly(): boolean {
    return !this.isAdmin;
  }

  public allowDeletePage(): boolean {
    return false;
  }

  protected getCaption(): Snippet {
    return tlang`Branding`;
  }

  getValidationErrors(): string[] {
    const errors: string[] = [];
    // throw image upload error only when a file has been provided, but it isn't a valid image
    if (this.newPositiveLogo && !fileIsImageFile(this.newPositiveLogo)) {
      errors.push(tlang`Please provide a valid Color Logo`);
    }
    // throw image upload error only when a file has been provided, but it isn't a valid image
    if (this.newNegativeLogo && !fileIsImageFile(this.newNegativeLogo)) {
      errors.push(tlang`Please provide a valid White Logo`);
    }
    return errors;
  }

  async prepareForSave(): Promise<void> {
    this.dataTracker.applyChangeToValue();
  }

  public internalDataChanged(): boolean {
    return isSomething(this.newNegativeLogo, this.newPositiveLogo);
  }

  async onEnter(): Promise<void> {
    this.franchiseeBackup.reset();
    this.requestUpdate();
  }

  get franchisee(): Franchisee {
    return this.franchiseeBackup.item;
  }

  protected async internalSaveData(): Promise<boolean> {
    // attempt to upload a logo from the given element and get its uploaded file path

    const newPositiveLogo = await this.dataBinding.createVirtualFile(this.newPositiveLogo);
    if (newPositiveLogo) {
      if (await this.uploadLogoFromElement(newPositiveLogo, 'positive'))
        this.franchisee.positiveLogoVirtualPath = newPositiveLogo?.filePath ?? this.franchisee.positiveLogoVirtualPath;
    }
    const newNegativeLogo = await this.dataBinding.createVirtualFile(this.newNegativeLogo);
    if (newNegativeLogo) {
      if (await this.uploadLogoFromElement(newNegativeLogo, 'negative'))
        this.franchisee.negativeLogoVirtualPath = newNegativeLogo?.filePath ?? this.franchisee.negativeLogoVirtualPath;
    }
    const result = await this.franchiseeBackup.commitChanges();
    if (result) {
      if (!isAutoSaving()) fireQuickSuccessToast(tlang`Branding saved`);
      this.dataTracker.removeFilesFromEditors();
      this.requestUpdate();
      return true;
    }

    return false;
  }

  private async uploadLogoFromElement(fileInformation: VirtualFile, newFileName: string): Promise<boolean> {
    // only attempt an upload if we have information about the image file to be uploaded.
    if (fileInformation) {
      // get the virtual logo path from the API and attempt to upload the file content.
      fileInformation.filePath = this.franchiseeApi.createBrandingLogoImagePath(newFileName, fileInformation.extension);
      const logoResult = await uploadVirtualFile(fileInformation);
      if (logoResult) {
        // if successful remove the local uploaded file to avoid repeated uploads.
        return true;
      }
      fireToastError(new Error(tlang`Unable to upload Logo`));
    }
    // if we get here no logo image has been uploaded, so return null as the path.
    return false;
  }

  /**
   * Attempts to get the absolute virtual file path for a given relative file path.
   * @param relativeVirtualFilePath The relative virtual file path.
   * @returns The full absolute virtual file path.
   */
  private getAbsoluteVirtualFilePath(relativeVirtualFilePath: string | null | undefined): string {
    if (!isEmptyOrSpace(relativeVirtualFilePath))
      return this.franchiseeApi.api.fullUrl(`api/file/${relativeVirtualFilePath}`);
    else return getAssetCdnPath('images/sample-logo-colour.png');
  }

  protected bodyTemplate(): EventTemplate {
    const form = new FormInputAssistant(this.dataTracker, false, true);

    const brandingMsg = asMarkdownTemplate(tlang`${'ref:FranchiseeBrandingView:uploadMsg:1'}
Please upload your business logo<br>
This will be incorporated into documents sent to !!client!! and !!supplier!!, such as !!estimate!!/!!invoice!!/!!purchase-order!!.

Choose a logo to appear at the top of PDFs and reports you print or send.<br>
The minimum permitted size is **800(W) x 400(H)** pixels.<br>
The ideal size of your logo is **1600(W) x 800(H)** pixels.<br>
The image must be **300DPI** in quality and in the **jpg** format on a white background.<br>
If the aspect ratio of your logo is different, it will be scaled down in proportion to fit the template constraints.
`);

    return html` <div>
      <form id="CompanyBrandingForm" class="form-two-col">
        <div class="row branding-wrapper">
          <div class="col-sm-6 form-column">
            <h4 class="section-header branding-image-header">Colour Logo</h4>
            <div class="branding-image-description">This logo appears on the top left hand corner of your masthead</div>
            ${form.imagePicker(this.positiveLogoName, tlang`Color Logo`)}
          </div>
          <div class="col-sm-6 form-column">
            <h4 class="section-header branding-image-header">White Logo</h4>
            <div class="branding-image-description">This logo appears on printed reports ( PDF )</div>
            ${form.imagePicker(this.negativeLogoName, tlang`White Logo`)}
          </div>
        </div>
        <h2>${tlang`Specifications`}</h2>
        <p>${brandingMsg}</p>
      </form>
    </div>`;
  }
}

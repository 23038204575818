import { cache } from '../cache-impl/cache-registry';
import { constructAsync } from '../../../webmodule-common/other/async-constructor';
import { EventBoolean } from '../../../webmodule-common/other/ui/events';
import { FranchiseePurchaseOrderContainerManager } from './data/franchisee-purchase-order-container';
import { FranchiseeV6PurchaseOrderView } from './views/v6/franchisee-v6-purchase-order-view';
import { getPurchaseOrderTitle } from '../../purchase-orders/data/purchase-order-helper-functions';
import {
  PurchaseOrderContainer,
  PurchaseOrderContainerManager
} from '../../purchase-orders/data/purchase-order-container';
import { PurchaseOrderView, PurchaseOrderViewOptions } from '../../purchase-orders/views/purchase-order-view';
import { ResourceLocker } from '../common/resource-lock';
import { tlang } from '../../../webmodule-common/other/language/lang';
import { userDataStore } from '../common/current-user-data-store';
import { waitPatiently } from '../../../webmodule-common/other/ui/modal-spinner';

export function getPurchaseOrderContainer(id: string): PurchaseOrderContainer {
  return new PurchaseOrderContainer(id, null, null);
}

export function getPurchaseOrderContainerManager(
  id: string | PurchaseOrderContainer,
  forceLocked?: EventBoolean
): PurchaseOrderContainerManager {
  const container = id instanceof PurchaseOrderContainer ? id : getPurchaseOrderContainer(id);
  const manager = new FranchiseePurchaseOrderContainerManager(container);
  manager.forceLocked = forceLocked;
  return manager;
}

export async function purchaseOrderViewFactory(options: PurchaseOrderViewOptions): Promise<PurchaseOrderView> {
  // Need to Add V7 views
  return await constructAsync(new FranchiseeV6PurchaseOrderView(options));
}

export class PurchaseOrderLock extends ResourceLocker {
  constructor(id: string, lockRevokedEvent: () => Promise<void>) {
    super(id, 'purchaseorder', 'purchase-order', lockRevokedEvent);
  }
}

export async function createPurchaseOrderView(
  containerManager: PurchaseOrderContainerManager
): Promise<PurchaseOrderView | null> {
  let orderEditor: PurchaseOrderView | null = null;

  const waiting = waitPatiently(
    () => tlang`Opening %%purchase-order%%`,
    () => tlang`Please wait while we construct the %%purchase-order%%"`
  );
  try {
    orderEditor = await purchaseOrderViewFactory({
      title: getPurchaseOrderTitle,
      purchaseOrderContainerManager: containerManager,
      purchaseOrderCache: cache().purchaseOrder,
      purchaseOrderOwnerId: async () => userDataStore.defaultBranch.id
    });
    return orderEditor;
  } finally {
    await waiting.hideModal();
  }
}

import { ApiCommunications, NullPromise } from '../../../webmodule-common/interop/webmodule-interop';
import { cache } from './cache-registry';
import { DataCacheBase } from '../../../webmodule-common/cache/data-cache-base';
import { getApiFactory } from '../../api/api-injector';
import { isEmptyOrSpace } from '../../../webmodule-common/other/ui/string-helper-functions';
import { ItemReference } from '../../../webmodule-common/cache/definitions/cache-item-reference';
import {
  PurchaseOrderState,
  ResultGetPurchaseOrderStateChangeReasonsByOwnerIds,
  ResultPurchaseOrderStateChangeReasonsForState
} from '../../api/dealer-api-interface-franchisee';
import { PurchaseOrderStateChangeReasonCacheData } from './cache-data';

export class PurchaseOrderStateChangeReasonCache extends DataCacheBase<PurchaseOrderStateChangeReasonCacheData> {
  private purchaseOrderApi = getApiFactory().purchaseOrder();

  constructor(api: ApiCommunications) {
    super(api);
  }

  protected override async internalFetch(
    _requestIds: string[]
  ): NullPromise<ItemReference<PurchaseOrderStateChangeReasonCacheData>[]> {
    const result = await this.purchaseOrderApi.getPurchaseOrderStateChangeReasons({
      purchaseOrderStateReasonsOwnerIds: _requestIds
    });
    if (result) {
      return this.processInternalFetch(result);
    }
    return null;
  }

  private async processInternalFetch(
    result: ResultGetPurchaseOrderStateChangeReasonsByOwnerIds
  ): NullPromise<ItemReference<PurchaseOrderStateChangeReasonCacheData>[]> {
    return result.purchaseOrderReasons.map(vr => {
      const item: ItemReference<PurchaseOrderStateChangeReasonCacheData> = {
        id: vr.purchaseOrderStateReasonOwnerId,
        displayValue: vr.purchaseOrderStateReasonOwnerId,
        data: { purchaseOrderStateChangeReasons: vr.purchaseOrderReasons },
        onClick: null
      };
      return item;
    });
  }
}

/**
 * Gets the project state change reasons for a given project state and franchisee owner.
 * @param purchaseOrderState The state of the project.
 * @param franchiseeId The Id of the owning franchisee.
 * @returns The array of project state change reasons, or null if none were found.
 */
export async function getPurchaseOrderStateChangeReasonsForState(
  purchaseOrderState: PurchaseOrderState,
  franchiseeId: string
): NullPromise<ResultPurchaseOrderStateChangeReasonsForState> {
  if (isEmptyOrSpace(franchiseeId)) return null;
  const reasons = async () => {
    const results = await cache().purchaseOrderStateReason.getData(franchiseeId);
    if (results) {
      const changeReasons = results.purchaseOrderStateChangeReasons.find(vr => vr.category == purchaseOrderState);
      if (changeReasons) {
        return changeReasons;
      }
    }
    return null;
  };
  const foundReason = await reasons();
  if (foundReason) {
    return foundReason;
  }
  return null;
}

//use constants everywhere. no typo, 1 source of update
export const pages = {
  login: 'login',

  home: 'home',
  quotes: 'quotes',
  quote: 'quote',
  projects: 'projects',
  project: 'project',
  purchaseOrders: 'purchase-orders',
  purchaseOrder: 'purchase-order',
  supportInbox: 'support-inbox',
  clients: 'clients',
  client: 'client',
  settings: 'settings'
};

import { ApiCommunications, NullPromise } from '../../../webmodule-common/interop/webmodule-interop';
import { DataCacheBase } from '../../../webmodule-common/cache/data-cache-base';
import { getProjectNumberFormatted } from '../projects/data/project-helper-functions';
import { ItemReference } from '../../../webmodule-common/cache/definitions/cache-item-reference';
import { ProjectCacheData, ProjectPermanentDocumentCacheData } from './cache-data';
import {
  RequestGetPermanentProjectDocuments,
  RequestGetProjectSummaryListByIds,
  ResultGetPermanentProjectDocuments,
  ResultGetProjectSummaryListByIds
} from '../../api/dealer-api-interface-project';

export class ProjectCache extends DataCacheBase<ProjectCacheData> {
  constructor(api: ApiCommunications) {
    super(api);
    //depending on what high level controller api we create we could swap this to ClientApi etc.
  }

  //override;
  protected async internalFetch(requestIds: string[]): NullPromise<ItemReference<ProjectCacheData>[]> {
    const input: RequestGetProjectSummaryListByIds = {
      projectIds: requestIds
    };
    const projectPromise = this.api.post<ResultGetProjectSummaryListByIds>(
      `api/Project/GetProjectSummaryListByIds`,
      input
    );
    const resultProject = await projectPromise;
    if (!resultProject) return null;

    return resultProject.projectSummaries.map(q => {
      const qvs: ItemReference<ProjectCacheData> = {
        id: q.id,
        displayValue: `${getProjectNumberFormatted(q)}`,
        data: { projectSummary: q },
        onClick: null
      };
      return qvs;
    });
  }
}

export class ProjectPermanentDocumentCache extends DataCacheBase<ProjectPermanentDocumentCacheData> {
  constructor(api: ApiCommunications) {
    super(api);
    //depending on what high level controller api we create we could swap this to ClientApi etc.
  }

  //override;
  protected async internalFetch(requestIds: string[]): NullPromise<ItemReference<ProjectPermanentDocumentCacheData>[]> {
    const input: RequestGetPermanentProjectDocuments = {
      projectIds: requestIds
    };

    const resultProject = await this.api.post<ResultGetPermanentProjectDocuments>(
      'api/Project/GetPermanentProjectDocuments',
      input
    );

    if (!resultProject) return null;

    return resultProject.documents.map(q => {
      const qvs: ItemReference<ProjectPermanentDocumentCacheData> = {
        id: q.projectId,
        displayValue: q.projectId,
        data: { documents: q.projectDocuments },
        onClick: null
      };
      return qvs;
    });
  }
}

import { QuoteItemContainer } from './quote-item-container';

export const quoteItemAction = {
  properties: 1,
  edit: 2,
  copy: 3,
  delete: 4
};
export type EventQuoteItemContainer = (quoteItemContainer: QuoteItemContainer) => Promise<void>;
export type EventQuoteItemContainerAction = (quoteItemContainer: QuoteItemContainer, action: number) => Promise<void>;

import { DevelopmentError } from '../../../webmodule-common/other/development-error';

export interface QuoteProviderData {
  supplierType: string;
  version: number;
}

export type EventQuoteProviderDataFactoryEvent = (
  supplierType: string,
  supplierId: string
) => PromiseLike<QuoteProviderData>;
export type EventQuoteProviderDataValidateEvent = (data: QuoteProviderData) => Promise<QuoteProviderData>;
let _factory: EventQuoteProviderDataFactoryEvent | null = null;
let _validator: EventQuoteProviderDataValidateEvent | null = null;
export function setQuoteProviderDataFactory(event: EventQuoteProviderDataFactoryEvent) {
  _factory = event;
}
export function setQuoteProviderDataValidateAndUpgradeEvent(event: EventQuoteProviderDataValidateEvent) {
  _validator = event;
}

export async function createQuoteProviderData(supplierType: string, supplierId: string) {
  if (!_factory) throw new DevelopmentError('EventQuoteProviderDataFactoryEvent is null');
  return await _factory(supplierType, supplierId);
}
export async function validateAndUpgradeQuoteProviderData(data: QuoteProviderData) {
  if (!_validator) throw new DevelopmentError('EventQuoteProviderDataValidateEvent is null');
  return await _validator(data);
}

interface VersionParts {
  one: number;
  two: number;
  three: number;
}

export function convertVersionString(version: string): VersionParts {
  const values = version.substring(1).split('.');
  return {
    one: parseInt(values[0]),
    two: parseInt(values[1]),
    three: parseInt(values[2])
  };
}

export function versionGreaterThanOrEqual(requiredVersion: string, version: string): boolean {
  const requiredParts = convertVersionString(requiredVersion);
  const parts = convertVersionString(version);
  if (parts.one > requiredParts.one) return true;
  if (parts.one < requiredParts.one) return false;
  if (parts.two > requiredParts.two) return true;
  if (parts.two < requiredParts.two) return false;
  if (parts.three >= requiredParts.three) return true;

  return false;
}

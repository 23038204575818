// eslint-disable-next-line import/named
import { customElement } from 'lit/decorators.js';
import { EventTemplate, Snippet } from '../../../webmodule-common/other/ui/events';
import { getApiToken } from '../../../webmodule-common/other/api/current-user';
import { html } from 'lit';
import {
  ICynclySupportPage,
  ILicenseServerUICynclySupportPageProvider,
  licenseServerUIBootLoader
} from '../data/licenseserver-integration';
import { PageControlTabWithIndependantSaving } from '../../../webmodule-common/other/ui/data-entry-screen-base';
import { tlang } from '../../../webmodule-common/other/language/lang';

@customElement('wm-cynclysupportview')
export class CynclySupportView extends PageControlTabWithIndependantSaving {
  provider: ILicenseServerUICynclySupportPageProvider = {
    credentials: {
      host: globalThis.dealerConfiguration.licenseServerHost,
      token: getApiToken()
    }
  };
  userConsole?: ICynclySupportPage;
  constructor() {
    super();
    this.pageFragment = 'cynclysupport';
  }
  async reloadData() {
    const licUI = await licenseServerUIBootLoader();
    this.userConsole = licUI?.getCynclyAccessPage?.(this.provider);
    this.requestUpdate();
    await this.refreshParent();
  }
  buttonMenu(): Snippet {
    const buttons = this.userConsole?.actions().map(x => {
      return html` <webmodule-button size="small" variant="primary" ?disabled=${!x.enabled} @click=${x.event}
        >${x.label}
      </webmodule-button>`;
    });
    return html`${buttons}`;
  }
  public internalDataChanged(): boolean {
    return false;
  }

  protected async internalSaveData(): Promise<boolean> {
    //nothing to do
    return true;
  }
  async onEnter(): Promise<void> {
    await this.reloadData();
    this.requestUpdate();
  }
  public allowDeletePage(): boolean {
    return false;
  }
  protected getCaption(): Snippet {
    return tlang`(Internal) Cyncly Support`;
  }
  async prepareForSave(): Promise<void> {
    //
  }
  protected bodyTemplate(): EventTemplate {
    const innerTemplate = this.userConsole?.template() ?? html``;
    return html`<h1>Cyncly Support Access Portal</h1>
      <h5>This page is for Cyncly Support Staff use only</h5>
      <p>
        Click on a url and you will be logged into their system using your Cyncly account to provide support and
        assistance
      </p>
      ${innerTemplate} `;
  }
}

// eslint-disable-next-line import/named
import { customElement } from 'lit/decorators.js';
import { DevelopmentError } from '../../../webmodule-common/other/development-error';
import { DynamicValueBinder, FieldType } from '../../../webmodule-common/other/ui/databinding/data-tracker';
import { EventTemplate, Snippet } from '../../../webmodule-common/other/ui/events';
import { FormInputAssistant } from '../../../webmodule-common/other/ui/templateresult/form-input-assistant';
import { html } from 'lit';
import { LineItemDialog, LineItemView } from './line-item-view';
import { money } from '../../../webmodule-common/other/currency-formatter';
import { newGuid } from '../../../webmodule-common/other/api/guid';
import { QuoteContainerManager } from '../data/quote-container';
import { QuoteItemContainer } from '../data/quote-item-container';
import { quoteItemContentType } from '../data/default-quote-item-content-type';
import { QuoteItemType } from '../../api/dealer-api-interface-quote';
import { tlang } from '../../../webmodule-common/other/language/lang';
import { updateDealerFreehandPrice } from '../data/v6/helper-functions';

@customElement('wm-freehandlineitemdialog')
export class FreehandLineItemDialog extends LineItemDialog {
  interval: NodeJS.Timeout | null = null;

  constructor(quoteManager: QuoteContainerManager, quoteItemContainer: QuoteItemContainer, forceReadonly: boolean) {
    super(quoteManager, quoteItemContainer, forceReadonly);

    if (quoteItemContainer.item.itemType !== QuoteItemType.Basic)
      throw new DevelopmentError(`freehand-line-item-view, ${quoteItemContainer.item.description} is not a freehand`);
    if (quoteItemContainer.item.quoteItemContentType !== quoteItemContentType.freehand)
      throw new DevelopmentError(`quote item ${quoteItemContainer.item.title} is not a freehand item`);

    const item = this.quoteItemContainer.item;
    const price = this.quoteItemContainer.price;

    this.dataTracker.addObjectBinding(() => item, 'title', 'title', FieldType.string, false);
    this.dataTracker.addObjectBinding(() => item, 'description', 'description', FieldType.string, false);
    this.dataTracker.addObjectBinding(() => item, 'quantity', 'quantity', FieldType.float, false);

    this.dataTracker.addObjectBinding(() => item, 'comment', 'comment', FieldType.string, false);
    this.dataTracker.addObjectBinding(() => price, 'singleUnitCost', 'singleUnitCost', FieldType.money, false);
    this.dataTracker.addBinding(
      new DynamicValueBinder(
        FieldType.money,
        false,
        () => {
          try {
            const qty = (this.dataTracker.getEditorValue('quantity') as number) ?? 0;
            const unitCost = (this.dataTracker.getEditorValue('singleUnitCost') as number) ?? 0;
            return money(qty * unitCost, 2);
          } catch {
            return price.quantityCost;
          }
        },
        () => {
          console.log('wont get here');
        },
        () => {
          return true;
        }
      ),
      'quantityCost',
      'quantityCost',
      FieldType.money,
      false
    );

    if (this.quoteManager.allowNonTaxableItems) {
      this.dataTracker.addObjectBinding(() => price, 'isTaxableItem', 'isTaxableItem', FieldType.boolean, false);
    }
  }

  triggerValueChangedEvent = () => {
    const qtyCost = this.dataTracker.getEditorValue('quantityCost') as number;
    const unitCost = this.dataTracker.getEditorValue('singleUnitCost') as number;
    const qty = this.dataTracker.getEditorValue('quantity') as number;
    if (money(qty * unitCost, 2) !== qtyCost) {
      this.requestUpdate(); //no wait
    }
  };

  eventKeyUp = (event: KeyboardEvent) => {
    if (event.code === 'Enter') {
      this.triggerValueChangedEvent(); //this.timedTrigger.triggerEarly(e);
    }
  };
  eventBlur = () => {
    this.triggerValueChangedEvent(); //this.timedTrigger.triggerEarly(e);
  };

  protected getTitle(): Snippet {
    return tlang`%%freehand%%`;
  }

  protected async internalSave() {
    updateDealerFreehandPrice(this.quoteItemContainer.item.quantity, this.quoteItemContainer.price);
    this.ok = true;
  }

  protected async getValidationErrors(): Promise<string[]> {
    const errors = await super.getValidationErrors();

    const quantity = this.dataBinding.getFloat('quantity') ?? 0;
    if (quantity <= 0) {
      errors.push(tlang`Please provide a quantity greater than 0`);
    }

    return errors;
  }

  protected bodyTemplate(): EventTemplate {
    const forms = new FormInputAssistant(this.dataTracker, this.forceReadonly);
    const taxableItemHtml = this.quoteManager.allowNonTaxableItems
      ? forms.switch('isTaxableItem', tlang`Is Taxable Item`)
      : html``;

    return html`
      <form class="freehand-item-editor-container form-one-col">
        <div class="row">
          <div class="form-column">
            ${forms.textRequired('title', tlang`Title`, 120)} ${forms.text('description', tlang`Description`, 120)}
            ${forms.float('quantity', tlang`Quantity`, {
              events: {
                blur: this.eventBlur,
                keyup: this.eventKeyUp
              }
            })}
            ${forms.money('singleUnitCost', tlang`Unit Cost`, {
              events: {
                blur: this.eventBlur,
                keyup: this.eventKeyUp
              }
            })}
            ${forms.moneyReadonly('quantityCost', tlang`Total Cost`)} ${taxableItemHtml}
            ${forms.note('comment', 'Notes', 500)}
          </div>
        </div>
      </form>
    `;
  }
}

//base class to manage the intricacies of quoteitems that may be v6 or otherwise
@customElement('wm-freehandlineitemview')
export class FreehandLineItemView extends LineItemView {
  //this should be called immediately after the constructor and if the return result is not
  //true then this page is not valid, and should be assumed to be cancelled
  public async prepareEditor(): Promise<void> {
    if (this.quoteItemContainer) {
      this._readyToEdit = true;
    } else {
      this._readyToEdit = await this.createQuoteItem();
    }
  }

  async createQuoteItem(): Promise<boolean> {
    const result = await this.quoteManager.createQuoteItem({
      isRestrictedToPowerUser: false,
      id: newGuid(),
      title: tlang`New %%freehand%%`,
      description: '',
      quantity: 1,
      comment: '',
      quoteItemContentType: quoteItemContentType.freehand,
      externalProvider: null,
      buyInData: null,
      price: {
        sourceData: {},
        singleUnitCost: 0,
        supplierGrossSingleUnitCost: 0,
        supplierNettSingleUnitCost: 0,
        supplierPriceAdjustment: 0
      },
      thumbnail: null,
      isTaxableItem: true
    });
    if (result) {
      this.quoteItemContainer = result;
      return true;
    }
    return false;
  }

  public async executeModalEditDialog(): Promise<void> {
    if (!this.quoteItemContainer) return;
    const dlg = new FreehandLineItemDialog(this.quoteManager, this.quoteItemContainer, this.quoteManager.isReadonly());
    await dlg.showModal();
    if (dlg.ok) {
      this.quoteItemContainer = await this.quoteManager.saveAndUpdateQuoteItemWithIndicator(
        this.quoteItemContainer,
        ''
      );
    }
  }
}

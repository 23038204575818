import { html } from 'lit';
import { lang, tlang } from '../../../webmodule-common/other/language/lang';
import { PurchaseOrder, PurchaseOrderState } from '../../api/dealer-api-interface-franchisee';

export const getPurchaseOrderNumberFormatted = (p: PurchaseOrder | null) => {
  if (!p) return '';

  return p.purchaseOrderNumber == 0
    ? tlang`Draft`
    : tlang`%%purchase-order-abbrev%%: ${p.purchaseOrderNumber.toString().padStart(6, '0')}`;
};

export const getPurchaseOrderTitle = (p: any) => {
  const po = p as PurchaseOrder;
  const poNumber = getPurchaseOrderNumberFormatted(po);

  return html`${poNumber} ${po.title} ${getPurchaseOrderStatus(po.state, true)}`;
};

export const getPurchaseOrderStatus = (state: number, asBadge = false) => {
  if (!asBadge) return html`${PurchaseOrderState[state]}`;

  const value = PurchaseOrderState[state].toLowerCase();
  const text = `%%purchase-order-state-${value}%%`;

  return html`<span class="badge badge-${value}">${lang(text)}</span>`;
};

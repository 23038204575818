import { Branch } from '../../../api/dealer-api-interface-franchisee';
import { customElement, property } from 'lit/decorators.js';
import { emptyGuid } from '../../../../webmodule-common/other/api/guid';
import { html, TemplateResult } from 'lit-html';
import { isEmptyOrSpace } from '../../../../webmodule-common/other/ui/string-helper-functions';
import { LitElementBase } from '../../../../webmodule-common/other/litelement-base';
import { TenantLoginPublicInfo } from '../../../api/dealer-api-interface-user';
import { tlang } from '../../../../webmodule-common/other/language/lang';
import {
  WebmoduleChangeEvent,
  WebmoduleSelect
} from '../../../../webmodule-common/components/src/webmodule-components';

export interface DateRange {
  id: string;
  label: string;
  startDate: Date;
  endDate: Date;
}

@customElement('wm-dashboard-filter')
export class DashboardFilter extends LitElementBase {
  @property() public dates?: DateRange[];
  @property() public branches?: Branch[];
  @property() public users?: TenantLoginPublicInfo[];

  @property() public selectedDate?: DateRange;
  @property() public selectedBranchId?: string;
  @property() public selectedUserId?: string;

  protected dateChangeEvent(e: WebmoduleChangeEvent) {
    const target = e.target as WebmoduleSelect | null;

    if (!target) return;

    const dateRangeId = target.value;
    const dateRange = this.dates?.find(dr => dr.id == dateRangeId);
    this.dispatchCustom('date-changed', {
      date: dateRange
    });
  }

  protected branchChangeEvent(e: WebmoduleChangeEvent) {
    const target = e.target as WebmoduleSelect | null;

    if (!target) return;

    const branchId = target.value;
    this.dispatchCustom('branch-changed', {
      branchId: branchId
    });
  }

  protected userChangeEvent(e: WebmoduleChangeEvent) {
    const target = e.target as WebmoduleSelect | null;

    if (!target) return;

    const userId = target.value;
    this.dispatchCustom('user-changed', {
      userId: userId
    });
  }

  protected sendResetFilterEvent(_e: Event) {
    this.dispatchCustom('reset-filters', {});
  }

  protected render(): TemplateResult {
    const dateRangeTemplate = () =>
      this.dates?.map(d => {
        return html`<webmodule-option value=${d.id}>${d.label}</webmodule-option>`;
      });

    const branchTemplate = () =>
      this.branches?.map(b => {
        return html`<webmodule-option value=${b.id}>${b.name}</webmodule-option>`;
      });
    const allBranchesOption =
      this.branches && this.branches.length > 1
        ? html` <webmodule-option value=${emptyGuid}>All Branches</webmodule-option>`
        : html``;

    const userTemplate = () =>
      this.users?.map(u => {
        return html`<webmodule-option value=${u.id}>${u.friendlyName}</webmodule-option>`;
      });
    const isUserDisabled = isEmptyOrSpace(this.selectedBranchId);

    return html`
      <div class="page-dashboard-filter">
        <label>${tlang`Filter Data`}</label>
        <webmodule-select
          @webmodule-change=${this.dateChangeEvent}
          id="selectedDateId"
          value="${this.selectedDate?.id ?? emptyGuid}"
          size="small"
        >
          ${dateRangeTemplate()}
        </webmodule-select>

        <webmodule-select
          @webmodule-change=${this.branchChangeEvent}
          id="selectedBranchId"
          value="${(branchTemplate()?.length ?? 0 > 0) ? (this.selectedBranchId ?? emptyGuid) : null}"
          size="small"
        >
          ${allBranchesOption} ${branchTemplate()}
        </webmodule-select>

        <webmodule-select
          @webmodule-change=${this.userChangeEvent}
          id="selectedUserId"
          ?disabled=${isUserDisabled}
          value="${this.selectedUserId ?? emptyGuid}"
          size="small"
        >
          <webmodule-option value=${emptyGuid}>All Employees</webmodule-option>
          ${userTemplate()}
        </webmodule-select>

        <webmodule-icon class="text-white" library="fa" name="fas-arrows-rotate" @click=${this.sendResetFilterEvent}>
        </webmodule-icon>
      </div>
    `;
  }
}

import { cache } from './cache-registry';
import { getApiFactory } from '../../api/api-injector';
import { TenantLoginPublicInfo } from '../../api/dealer-api-interface-user';

class DealerBranchUserCache {
  private userProfiles: Map<string, Map<string, TenantLoginPublicInfo>> = new Map();
  private userCache = cache().userProfile;

  public async prefetch(): Promise<void> {
    const branchResult = await getApiFactory().franchisee().getBranches();
    if (branchResult) {
      this.userProfiles = new Map();
      for (const branchId in branchResult.branchUsers) {
        const branchUserIds = branchResult.branchUsers[branchId].filter(u => u.isActive).map(u => u.userId);
        await this.addUsersToBranch(branchId, branchUserIds);
      }
    }
  }

  public async addUsersToBranch(branchId: string, userIds: string[]): Promise<void> {
    const cacheData = await this.userCache.getMany(userIds);
    if (cacheData) {
      const branchUsersMap = this.userProfiles.get(branchId) ?? new Map<string, TenantLoginPublicInfo>();
      for (const cacheEntry of cacheData) {
        branchUsersMap.set(cacheEntry.id, cacheEntry.data);
      }
      this.userProfiles.set(branchId, branchUsersMap);
    }
  }

  public getBranchUsers(branchId?: string): TenantLoginPublicInfo[] {
    if (!branchId) {
      return [];
    }
    const users = this.userProfiles.get(branchId);
    if (users) {
      return [...users.values()];
    }
    return [];
  }
}

let _db: DealerBranchUserCache | null = null;
export function getBranchUserCache(): DealerBranchUserCache {
  if (!_db) {
    _db = new DealerBranchUserCache();
  }
  return _db;
}

export function flushBranchUserCache() {
  _db = null;
}

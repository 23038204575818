import { cache } from '../../../cache-impl/cache-registry';
import { customElement } from 'lit/decorators.js';
import { FranchiseeApi } from '../../../../api/franchisee-api';
import { FranchiseePurchaseOrderDEView, FranchiseePurchaseOrderView } from '../franchisee-purchase-order-view';
import { getApiFactory } from '../../../../api/api-injector';
import { ProjectApi } from '../../../../api/project-api';
import { PurchaseOrderApi } from '../../../../api/purchase-order-api';
import { PurchaseOrderDataEntryView } from '../../../../purchase-orders/views/purchase-order-view';
import { QuoteApi } from '../../../../api/quote-api';

@customElement('wm-franchiseev6purchaseorderdeview')
export class FranchiseeV6PurchaseOrderDEView extends FranchiseePurchaseOrderDEView {
  userCache = cache().userProfile;
  franchiseeApi: FranchiseeApi = getApiFactory().franchisee();
  quoteApi: QuoteApi = getApiFactory().quote();
  projectApi: ProjectApi = getApiFactory().project();
  purchaseOrderApi: PurchaseOrderApi = getApiFactory().purchaseOrder();
}

@customElement('wm-franchiseev6purchaseorderview')
export class FranchiseeV6PurchaseOrderView extends FranchiseePurchaseOrderView {
  protected createView(): PurchaseOrderDataEntryView {
    return new FranchiseeV6PurchaseOrderDEView(this.options, this);
  }
}

import { ClientApi } from '../../api/client-api';
import { customElement } from 'lit/decorators.js';
import { EventContactOpen } from '../data/events';
import { EventSnippet } from '../../../webmodule-common/other/ui/events';
import { getApiFactory } from '../../api/api-injector';
import { html } from 'lit';
import { LitTableWrapper } from '../../../webmodule-common/other/ui/littable-view';
import { RequestPage, ResultPaginated } from '../../../webmodule-common/other/ui/RequestPage';
import { resolveURL } from '../../../webmodule-common/other/ui/resource-resolver';
import { resourceClient } from '../../dealer-franchisee/clients/ui/launcher';
import { tlang } from '../../../webmodule-common/other/language/lang';
import { userDataStore } from '../../dealer-franchisee/common/current-user-data-store';
import { ViewContactSummary } from '../../api/dealer-api-interface-client';
import {
  WebModuleLitTable,
  WebModuleLitTableColumnDef
} from '../../../webmodule-common/components/src/webmodule-components';

interface ContactSummaryTableBaseOptions {
  title: EventSnippet;
}

@customElement('wm-contactsummarytablebase')
class ContactSummaryTableBase extends LitTableWrapper<ViewContactSummary> {
  eventTitle: EventSnippet;
  api: ClientApi = getApiFactory().client();
  filter: string | null;
  clientIdFilter: string | null;

  constructor(options: ContactSummaryTableBaseOptions) {
    super();
    this.eventTitle = options.title;
    this.filter = null;
    this.clientIdFilter = null;
  }

  enableFiltering(): boolean {
    return true;
  }

  updateFilter(_searchTerm: string | null) {
    this.filter = _searchTerm;
  }

  updateClientFilter(_clientId: string | null) {
    this.clientIdFilter = _clientId;
  }

  async getRowsFromServer(request: RequestPage): Promise<ResultPaginated<ViewContactSummary>> {
    const results = await this.api.browseContactSummary({
      pageIndex: request.pageIndex,
      pageSize: request.pageSize,
      sortField: request.sortField,
      sortAsc: !request.sortAsc,
      clientOwnerId: userDataStore.clientOwnerId,
      filter: this.filter,
      clientId: this.clientIdFilter
    });
    if (!results)
      return {
        count: 0,
        pageCount: 0,
        pageIndex: 0,
        pageSize: this.pageLength(),
        results: []
      };

    return results.contactSummary;
  }

  getColumns(): WebModuleLitTableColumnDef[] {
    return [
      {
        title: tlang`%%contact%%`,
        fieldName: 'name',
        classes: 'colpx-250 contact-name',
        displayValue: (_table: WebModuleLitTable, item: unknown) => {
          const rowItem = item as ViewContactSummary;
          return html`${rowItem.name}`;
        }
      },
      {
        title: tlang`Primary`,
        fieldName: 'isPrimary',
        classes: 'colpx-250 contact-isprimary',
        displayValue: (_table: WebModuleLitTable, item: unknown) => {
          const rowItem = item as ViewContactSummary;
          return this.isPrimary(rowItem.isPrimary);
        }
      },
      {
        title: tlang`%%client%%`,
        fieldName: 'clientName',
        sortable: true,
        classes: 'colpx-250 contact-client-name',
        displayValue: (_table: WebModuleLitTable, item: unknown) => {
          const rowItem = item as ViewContactSummary;
          return html`${rowItem.clientName}`;
        }
      },
      {
        title: tlang`Email`,
        fieldName: 'email',
        classes: 'colpx-250 contact-email',
        displayValue: (_table: WebModuleLitTable, item: unknown) => {
          const rowItem = item as ViewContactSummary;
          return html`${rowItem.email}`;
        }
      },
      {
        title: tlang`Role`,
        fieldName: 'title',
        classes: 'colpx-250 contact-title',
        displayValue: (_table: WebModuleLitTable, item: unknown) => {
          const rowItem = item as ViewContactSummary;
          return html`${rowItem.title}`;
        }
      },
      {
        title: tlang`Phone`,
        fieldName: 'mobile',
        classes: 'colpx-250 contact-mobile',
        displayValue: (_table: WebModuleLitTable, item: unknown) => {
          const rowItem = item as ViewContactSummary;
          return html`${rowItem.mobile}`;
        }
      }
    ];
  }

  protected isPrimary(data: boolean) {
    return data ? html` <webmodule-icon library="fa" name="far-circle-check"></webmodule-icon>` : '';
  }
}

interface ContactSummaryTableOptions extends ContactSummaryTableBaseOptions {
  openContactEvent: EventContactOpen;
  addButtonTitle: EventSnippet;
  pageFragment: string;
}

@customElement('wm-contactsummarytable')
export class ContactSummaryTable extends ContactSummaryTableBase {
  openContactEvent: EventContactOpen;
  addButtonTitle: EventSnippet;
  pageFragment: string;

  constructor(options: ContactSummaryTableOptions) {
    super({ title: options.title });
    this.openContactEvent = options.openContactEvent;
    this.addButtonTitle = options.addButtonTitle;
    this.pageFragment = options.pageFragment;
  }

  getColumns(): WebModuleLitTableColumnDef[] {
    return [
      {
        title: tlang`%%contact%%`,
        fieldName: 'id',
        classes: 'colpx-250 contact-name',
        sortable: true,
        displayValue: (_table: WebModuleLitTable, item: unknown) => {
          const rowItem = item as ViewContactSummary;

          return html`<a class="contact-link" data-contact-id=${rowItem.id} href="#">${rowItem.name}</a>`;
        },
        click: (e: Event, _table: WebModuleLitTable, item: unknown) => {
          e.preventDefault();
          e.stopImmediatePropagation();

          const rowItem = item as ViewContactSummary;

          this.eventHandler(async () => {
            await this.openContact(rowItem);
          });

          return true;
        }
      },
      {
        title: tlang`Primary`,
        fieldName: 'isPrimary',
        classes: 'colpx-250 contact-is-primary',
        displayValue: (_table: WebModuleLitTable, item: unknown) => {
          const rowItem = item as ViewContactSummary;

          return this.isPrimary(rowItem.isPrimary);
        }
      },
      {
        title: tlang`%%client%%`,
        fieldName: 'clientId',
        sortable: true,
        classes: 'colpx-250 contact-client-name',
        displayValue: (_table: WebModuleLitTable, item: unknown) => {
          const rowItem = item as ViewContactSummary;

          return html`<a
            class="client-link"
            data-contact-id=${rowItem.clientId}
            href="${resolveURL(resourceClient, rowItem.clientId)}"
            >${rowItem.clientName}</a
          >`;
        }
      },
      {
        title: tlang`Email`,
        fieldName: 'email',
        classes: 'colpx-250 contact-email',
        displayValue: (_table: WebModuleLitTable, item: unknown) => {
          const rowItem = item as ViewContactSummary;

          return html`${rowItem.email}`;
        }
      },
      {
        title: tlang`Role`,
        fieldName: 'title',
        classes: 'colpx-250 contact-title',
        displayValue: (_table: WebModuleLitTable, item: unknown) => {
          const rowItem = item as ViewContactSummary;

          return html`${rowItem.title}`;
        }
      },
      {
        title: tlang`Phone`,
        fieldName: 'mobile',
        classes: 'colpx-250 contact-mobile',
        displayValue: (_table: WebModuleLitTable, item: unknown) => {
          const rowItem = item as ViewContactSummary;

          return html`${rowItem.mobile}`;
        }
      }
    ];
  }

  public async openContact(viewContactSummary: ViewContactSummary): Promise<void> {
    await this.openContactEvent?.(viewContactSummary);
  }
}

//We can extend this to add options like pick immediately, and what buttons to show.
interface ContactSummaryPickerOptions extends ContactSummaryTableBaseOptions {
  clientId?: string;
  onContactSelect?: (contactSummary: ViewContactSummary) => void;
}

@customElement('wm-contactsummarypickerbrowser')
export class ContactSummaryPickerBrowser extends ContactSummaryTableBase {
  onContactSelect?: (contactSummary: ViewContactSummary) => void;

  constructor(options: ContactSummaryPickerOptions) {
    super({ title: options.title });
    this.clientIdFilter = options.clientId ?? null;
    this.onContactSelect = options.onContactSelect;
    this.clickrows = true;
  }

  getColumns(): WebModuleLitTableColumnDef[] {
    return [
      {
        title: tlang`%%contact%%`,
        fieldName: 'id',
        classes: 'colpx-250 contact-name',
        sortable: true,
        displayValue: (_table: WebModuleLitTable, item: unknown) => {
          const rowItem = item as ViewContactSummary;

          return html`<span class="contact-link" data-contact-id=${rowItem.id}>${rowItem.name}</span>`;
        }
      },
      {
        title: tlang`Primary`,
        fieldName: 'isPrimary',
        classes: 'colpx-250 contact-is-primary',
        displayValue: (_table: WebModuleLitTable, item: unknown) => {
          const rowItem = item as ViewContactSummary;

          return this.isPrimary(rowItem.isPrimary);
        }
      },
      {
        title: tlang`%%client%%`,
        fieldName: 'clientName',
        classes: 'colpx-250 contact-client-name',
        displayValue: (_table: WebModuleLitTable, item: unknown) => {
          const rowItem = item as ViewContactSummary;

          return html`<span class="client-link" data-contact-id=${rowItem.clientId}>${rowItem.clientName}</span>`;
        }
      }
    ];
  }

  async rowClicked(e: CustomEvent<{ table: WebModuleLitTable; item: ViewContactSummary }>): Promise<void> {
    e.stopImmediatePropagation();
    e.preventDefault();

    this.eventHandler(async () => {
      if (this.onContactSelect) this.onContactSelect?.(e.detail.item);
    });
  }
}

import { html, TemplateResult } from 'lit';
import { LitBaseModal } from './modal/modal-factory-lit';
import { Snippet } from '../../interop/webmodule-interop';
import { tlang } from '../language/lang';

export async function previewImage(src: string) {
  const d = new ImagePreview(src);
  await d.showModal();
}

class ImagePreview extends LitBaseModal {
  src: string;

  constructor(src: string) {
    super();
    this.src = src;
  }
  get borderType(): string | undefined {
    return 'basic';
  }
  protected get modalClasses(): string {
    return 'modal-dialog ';
  }
  protected get modalSize() {
    return 'img-modal modal-fullscreen';
  }
  public template(): TemplateResult {
    const modalContentClasses = `modal-content  border border-${this.borderType}`;
    const modalClasses = `${this.modalClasses} ${this.modalSize}`;
    const eventClose = () => this.hideModal();
    return html` <div
      @click=${eventClose}
      class="modal "
      data-bs-backdrop="static"
      data-bs-keyboard="false"
      tabindex="-1"
    >
      <div class=${modalClasses}>
        <div class=${modalContentClasses}>
          <div class="image-preview d-flex justify-content-center">
            <img @click=${eventClose} src=${this.src} alt="Preview of the uploaded file" />
          </div>
        </div>
      </div>
    </div>`;
  }

  title(): Snippet {
    return tlang`Image Preview`;
  }
  public bodyTemplate() {
    return html``;
  }
}

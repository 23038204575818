import { ApiCommunications, NullPromise } from '../../../webmodule-common/interop/webmodule-interop';
import { DataCacheBase } from '../../../webmodule-common/cache/data-cache-base';
import { getApiFactory } from '../../api/api-injector';
import { ItemReference } from '../../../webmodule-common/cache/definitions/cache-item-reference';
import { projectLink, ProjectResourceLink } from './resource-link';

export class ProjectResourceLinkCache extends DataCacheBase<ProjectResourceLink> {
  projectApi = getApiFactory().project();
  constructor(api: ApiCommunications) {
    super(api);
  }

  protected async internalFetch(requestIds: string[]): NullPromise<ItemReference<ProjectResourceLink>[]> {
    const result = await this.projectApi.getResourcesLinks({ resourceIds: requestIds });
    if (!result) return null;
    return result.resourceLinks.map(viewResourceLink => {
      const qcr: ItemReference<ProjectResourceLink> = {
        id: viewResourceLink.resourceId,
        displayValue: `N/A`,
        data: projectLink(viewResourceLink),
        onClick: null
      };
      return qcr;
    });
  }
}

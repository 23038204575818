import { Editor } from '@toast-ui/editor';

export function newStandardEditor(control: any, value = '', height = 370): Editor {
  return new Editor({
    el: control,
    previewStyle: 'vertical',
    height: `${height}px`,
    initialValue: value,
    usageStatistics: false,
    initialEditType: 'wysiwyg',
    hideModeSwitch: true
  });
}

import { constructAsync } from '../../../webmodule-common/other/async-constructor';
import { customElement, state } from 'lit/decorators.js';
import { disposeOf } from '../../../webmodule-common/other/dispose';
import { FranchiseePurchaseOrderListView } from '../purchase-orders/views/franchisee-purchase-order-list-view';
import { getInternalId } from '../../../webmodule-common/other/ui/databinding/databinding';
import { html } from 'lit';
import { PageBase } from './helpers/page-base';
import { tlang } from '../../../webmodule-common/other/language/lang';
import { userDataStore } from '../common/current-user-data-store';

@customElement('app-purchase-orders')
export class AppPurchaseOrdersPage extends PageBase {
  internalId: string = getInternalId();
  @state()
  purchaseOrderListView: FranchiseePurchaseOrderListView | null = null;

  constructor() {
    super();
  }
  protected async awaken() {
    await this.setActiveTabByHash();
    await super.awaken();
  }
  private async setActiveTabByHash() {
    await this.purchaseOrderListView?.setActiveTabByHash();
  }

  private get elementId(): string {
    return `purchase-order-page-${this.internalId}`;
  }

  render() {
    if (!this.loggedIn) return html``;
    return html`
      <div id=${this.elementId} class="page-content">
        <div class="page-container">${this.purchaseOrderListView?.ui}</div>
      </div>
    `;
  }

  meta() {
    return {
      title: tlang`%%purchase-order%% Summary`,
      description: 'SoftTech dealer CPQ'
    };
  }

  protected createRenderRoot(): HTMLElement | DocumentFragment {
    return this;
  }

  protected async afterUserConnected() {
    this.purchaseOrderListView = this.purchaseOrderListViewFactory();

    await constructAsync(this.purchaseOrderListView);
    await this.setActiveTabByHash();
  }
  async dispose() {
    await super.dispose();
    await disposeOf(this.purchaseOrderListView);
    this.purchaseOrderListView = null;
  }
  private purchaseOrderListViewFactory(): FranchiseePurchaseOrderListView {
    const listView = new FranchiseePurchaseOrderListView({
      purchaseOrderOwnerId: async () => {
        await userDataStore.loadCoreDetails();
        //this will need to be changed to a franchisee branch that the user belongs to
        if (!userDataStore.defaultBranch) return '';
        return userDataStore.defaultBranch.id;
      }
    });
    return listView;
  }
}

import { FranchiseeQuoteContainerManager } from '../franchisee-quote-manager';
import {
  getV6FrameForContainer,
  getV6QuoteItemForContainer,
  getV6SupplierForContainer,
  isV6,
  updateQuoteItemContainer
} from '../../../../quotes/data/v6/helper-functions';
import { isFrame, processQuoteItemState } from '../../../../quotes/data/quote-helper-functions';
import { Message, MessageType } from '../../../../../webmodule-common/other/messages';
import {
  OutOfDateQuoteItemHandler,
  QuoteItemProcessingCompleteItem
} from '../../../../quotes/views/quote-item-validation-modal';
import { QuoteContainerManager } from '../../../../quotes/data/quote-container';
import { QuoteFrameProcessingDataProvider } from '../../../../../webmodule-common/interop/webmodule-interop';
import { QuoteItemContainer } from '../../../../quotes/data/quote-item-container';
import { showDevelopmentError } from '../../../../../webmodule-common/other/development-error';
import { tlang } from '../../../../../webmodule-common/other/language/lang';
import { v6Config } from '../../../../v6config/v6config';
import { V6FranchiseeQuoteProviderData } from '../franchisee-quote-provider-data';

export interface ItemContainerCompareResult {
  structureChanged: boolean;
  validationsChanged: boolean;
}

export class V6OutOfDateQuoteItemHandler implements OutOfDateQuoteItemHandler {
  qcm: FranchiseeQuoteContainerManager;
  constructor(qcm: FranchiseeQuoteContainerManager) {
    this.qcm = qcm;
  }
  isOutOfDate(qic: QuoteItemContainer): boolean {
    return this.qcm.isFrameOutOfDate(qic, []);
  }
  isValidatingItem(qic: QuoteItemContainer): boolean {
    return isV6(qic) && isFrame(qic.item);
  }
  displayInfo(qic: QuoteItemContainer): string {
    const si = getV6SupplierForContainer(qic);

    return si?.quoteItemVersion ?? 'N/A';
  }
  async processItemEvent(
    qm: QuoteContainerManager,
    itemContainer: QuoteItemContainer,
    completeItem: QuoteItemProcessingCompleteItem,
    progress: (caption: string) => Promise<void>
  ): Promise<void> {
    //get the v6QuoteItem that was placed in memory that maps to the ui.
    const v6QuoteItem = getV6QuoteItemForContainer(itemContainer);
    if (!v6QuoteItem) {
      //should never happen.
      await showDevelopmentError('quote-item-validation-modal.ts, v6QuoteItem is null');
      return;
    }

    const data = qm.quoteProviderData() as V6FranchiseeQuoteProviderData;
    const input: QuoteFrameProcessingDataProvider = {
      supplierId: qm.quote.supplierId,
      supplierReferenceOverrideKey: qm.quote.quoteOwnerId,
      quantity: itemContainer.item.quantity,
      quoteDefaults: data.defaultOptions,
      quoteIGUs: data.igu,
      quoteItem: v6QuoteItem,
      progress: progress
    };
    const result = await v6Config().processV6QuoteItem(input);

    if (result) {
      const thumb = result.thumbnail ?? '';
      const buyInCost = await qm.getBuyInCosts(result.quoteItem['buyIn']);
      updateQuoteItemContainer(itemContainer, result.quoteItem, result.price, buyInCost);
      completeItem.newPrice = itemContainer.price.quantityCost;

      await progress(processQuoteItemState.finalizing);

      const dataVer = itemContainer.data?.recordVersion;
      const newContainer = await qm.saveAndUpdateQuoteItem(itemContainer, thumb);
      itemContainer.item = newContainer.item;
      itemContainer.data = newContainer.data;
      itemContainer.price = newContainer.price;
      itemContainer.buyInData = newContainer.buyInData;
      const validations = getV6FrameForContainer(newContainer)?.validations;
      completeItem.validation =
        validations?.map(x => {
          const m: Message = {
            message: x.text ?? '',
            messageType: MessageType.Warning
          };
          return m;
        }) ?? [];
      if (dataVer === newContainer.data?.recordVersion) {
        completeItem.error = tlang`could not be saved`;
        await progress(processQuoteItemState.errors);
      } else {
        completeItem.completionStatus = processQuoteItemState.completed;
        await progress(processQuoteItemState.completed);
      }
    } else await progress(processQuoteItemState.errors);
  }
  get displayInfoTitle(): string {
    return tlang`Version`;
  }
  async finalProcessing(qm: QuoteContainerManager): Promise<boolean> {
    await qm.runAndSaveSSI();
    return true;
  }
}

export interface OutOfDateFailure {
  qic: QuoteItemContainer;
  reason: string;
}
export interface FinalProcessItem {
  qic: QuoteItemContainer;
  completeItem: QuoteItemProcessingCompleteItem;
}

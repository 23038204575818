// eslint-disable-next-line import/named
import { customElement } from 'lit/decorators.js';
import { EventTemplate, Snippet } from '../../../webmodule-common/other/ui/events';
import { html } from 'lit';
import { Quote } from '../../api/dealer-api-interface-quote';
import { QuoteContainerManager } from '../data/quote-container';
import { ViewBase } from '../../../webmodule-common/other/ui/view-base';

export interface QuoteSupplierDefaultsViewOptions {
  quoteManager: QuoteContainerManager;
  areItemsOpen: () => boolean;
}
@customElement('wm-quotesupplierdefaultsview')
export class QuoteSupplierDefaultsView extends ViewBase {
  quoteManager: QuoteContainerManager;
  areItemsOpen: () => boolean;

  constructor(options: QuoteSupplierDefaultsViewOptions) {
    super();
    this.quoteManager = options.quoteManager;
    this.areItemsOpen = options.areItemsOpen;
  }
  async afterConstruction(): Promise<void> {
    await this.quoteManager.needsQuote();
    await this.quoteManager.needsQuoteItems();
  }

  async prepareForSave() {
    //nothing to do
  }

  buttonMenu(): Snippet {
    return html``;
  }

  get supplierId(): string {
    return this.quoteManager.quote.supplierId;
  }
  get quote(): Quote {
    return this.quoteManager.quote;
  }
  protected template(): EventTemplate {
    return html``;
  }

  public async refresh(): Promise<void> {
    //override;
  }
}

import { ApiCommunications, NullPromise } from '../../../webmodule-common/interop/webmodule-interop';
import { DataCacheBase } from '../../../webmodule-common/cache/data-cache-base';
import { getQuoteNumberFormatted } from '../../quotes/data/quote-helper-functions';
import { ItemReference } from '../../../webmodule-common/cache/definitions/cache-item-reference';
import { QuoteCacheData } from './cache-data';
import {
  RequestGetQuoteSummaryListByIds,
  ResultGetQuoteSummaryListByIds,
  ViewQuoteSummary
} from '../../api/dealer-api-interface-quote';
import { SSEQuote } from '../../quotes/data/sse-quote';
import { WMEventSource } from '../../api/event-source';

export class QuoteCache extends DataCacheBase<QuoteCacheData> {
  constructor(api: ApiCommunications) {
    super(api);
    //depending on what high level controller api we create we could swap this to ClientApi etc.
    WMEventSource.getInstance().addEventListener(WMEventSource.quote, (_data: unknown, _eventName: string) => {
      const data = _data as SSEQuote;
      this.flush([data.id]);
    });
  }

  public async updateFromSummaries(input: ViewQuoteSummary[]) {
    //flush the cache
    input.forEach(x => {
      this.expire(x.id);
    });
    const resultQuotes: ResultGetQuoteSummaryListByIds = {
      quoteSummaries: input
    };
    const items = await this.processInternalFetch(resultQuotes);
    this.addToCache(items);
    return items;
  }
  //override;

  protected async processInternalFetch(
    resultQuotes: ResultGetQuoteSummaryListByIds | null
  ): NullPromise<ItemReference<QuoteCacheData>[]> {
    if (!resultQuotes) return null;

    return resultQuotes.quoteSummaries.map(q => {
      const data: QuoteCacheData = {
        quoteSummary: q
      };
      const qvs: ItemReference<QuoteCacheData> = {
        id: q.id,
        displayValue: `${getQuoteNumberFormatted(q)}`,
        data: data,
        onClick: null
      };
      return qvs;
    });
    //
  }
  protected async internalFetch(requestIds: string[]): NullPromise<ItemReference<QuoteCacheData>[]> {
    const input: RequestGetQuoteSummaryListByIds = {
      quoteIds: requestIds
    };

    //these should just be added to the API Exposures so that they are reuseable
    const resultQuotes = await this.api.post<ResultGetQuoteSummaryListByIds>(
      `api/Quote/GetQuoteSummaryListByIds`,
      input
    );

    return await this.processInternalFetch(resultQuotes);
  }
}

import { Branch } from '../../api/dealer-api-interface-franchisee';
import { customElement, property } from 'lit/decorators.js';
import { branchUsersCache } from '../cache-impl/branch-users-cache';
import { DateRange } from '../dashboard/widget/dashboard-filter';
import { emptyGuid, newGuid } from '../../../webmodule-common/other/api/guid';
import { flushDashboard } from '../dashboard/data/dashboard-cache';
import { getInternalId } from '../../../webmodule-common/other/ui/databinding/databinding';
import { html, TemplateResult } from 'lit';
import { PageBase } from './helpers/page-base';
import { tlang } from '../../../webmodule-common/other/language/lang';
import { userDataStore } from '../common/current-user-data-store';

/**
 * configure the dashboard based on the type of user.
 * we will determine this user level by using a security level on the backend
 * and converting that to a type
 * or maybe add a table that the manager can allocate and configure
 */
const dashboardContentType = {
  unknown: 0,
  /**
   * this is the typical quote creator, and only one implemented
   */
  branchUser: 1,
  franchiseManager: 2,
  franchiseAdministrator: 4
};

@customElement('app-home')
export class AppHome extends PageBase {
  internalId: string = getInternalId();
  // For more information on using properties and state in lit
  // check out this link https://lit.dev/docs/components/properties/
  @property() message = tlang`Welcome!`;
  @property({ type: Number }) contentType = 0;

  private currentDate = new Date();

  private availableDateRanges: DateRange[] = [];
  @property({ type: Object }) private selectedDateRange?: DateRange;

  private availableBranches: Branch[] = [];
  @property() private selectedBranchId?: string;
  @property() private selectedUserId?: string;

  private userCache = branchUsersCache();

  constructor() {
    super();
    this.contentType = dashboardContentType.branchUser;
  }

  private get elementId(): string {
    return `dashboard-page-${this.internalId}`;
  }
  protected async afterUserConnected() {
    this.requestUpdate();
  }
  protected async awaken() {
    //if we ever reach this page and we are not logged in
    //then we want to force a page reload which will
    //take us through our full reload process of the apoplication
    //in index.html including the user login process
    if (!this.loggedIn) setTimeout(() => (location.href = ''), 100);

    const last6months = new Date(this.currentDate);
    last6months.setMonth(last6months.getMonth() - 6);
    this.addDateRange(tlang`Last 6 months`, last6months);

    const last90days = new Date(this.currentDate);
    last90days.setMonth(last90days.getMonth() - 3);
    this.addDateRange(tlang`Last 90 days`, last90days);

    const last60days = new Date(this.currentDate);
    last60days.setMonth(last60days.getMonth() - 2);
    this.addDateRange(tlang`Last 60 days`, last60days);

    // get start date for activity over the past month
    const last30days = new Date(this.currentDate);
    last30days.setMonth(last30days.getMonth() - 1);
    this.addDateRange(tlang`Last 30 days`, last30days);

    const last7days = new Date(this.currentDate);
    last7days.setDate(last7days.getDate() - 7);
    this.addDateRange(tlang`Last 7 days`, last7days);

    const last24hours = new Date(this.currentDate);
    last24hours.setDate(last24hours.getDate() - 1);
    this.addDateRange(tlang`Last 24 hours`, last24hours);

    this.selectedDateRange = this.availableDateRanges[3];

    await userDataStore.reLoadFranchisee();
    const branches = userDataStore.getUserBranches();
    this.availableBranches = branches;

    await this.userCache.prefetch(branches.map(b => b.id));

    if (branches && branches.length == 1) {
      this.selectedBranchId = branches[0].id;
    }
  }

  render() {
    if (!this.loggedIn) return html``;
    return html`
      <div id=${this.elementId} class="page-content">
        <div class="page-container">${this.getTemplateForCurrentUser()}</div>
      </div>
    `;
  }
  getTemplateForCurrentUser(): TemplateResult {
    if (this.contentType === dashboardContentType.branchUser) return this.getTemplateForBranchUser();
    return html``;
  }
  getTemplateForBranchUser(): TemplateResult {
    return html` <div class="page-dashboard-content">
      <wm-dashboard-filter
        class="dashboard-item-filter"
        .dates=${this.availableDateRanges}
        .branches=${this.availableBranches}
        .users=${this.userCache.getUsersForBranch(this.selectedBranchId)}
        .selectedDate=${this.selectedDateRange}
        .selectedBranchId=${this.selectedBranchId}
        .selectedUserId=${this.selectedUserId}
        @wm-event-date-changed=${this.onDateRangeChanged}
        @wm-event-branch-changed=${this.onBranchChanged}
        @wm-event-user-changed=${this.onUserChanged}
        @wm-event-reset-filters=${this.onFilterReset}
      ></wm-dashboard-filter>
      <div class="dashboard-items-wrapper filtered-dashboard-items">
        <div class="dashboard-item dashboard-item-piechart">
          <sales-success-widget
            .dateRange=${this.selectedDateRange}
            .branchId=${this.selectedBranchId}
            .userId=${this.selectedUserId}
          ></sales-success-widget>
        </div>
        <div class="dashboard-item dashboard-item-piechart">
          <dashboard-branch-monthly-revenue-pipeline
            .branchId=${this.selectedBranchId}
            .assignedToUserId=${this.selectedUserId}
          ></dashboard-branch-monthly-revenue-pipeline>
        </div>
        <div class="dashboard-item dashboard-item-barchart">
          <generated-purchase-orders-revenue-widget
            .dateRange=${this.selectedDateRange}
            .branchId=${this.selectedBranchId}
            .userId=${this.selectedUserId}
          ></generated-purchase-orders-revenue-widget>
        </div>
        <div class="dashboard-item dashboard-item-barchart">
          <issued-quotes-revenue-widget
            .dateRange=${this.selectedDateRange}
            .branchId=${this.selectedBranchId}
            .userId=${this.selectedUserId}
          ></issued-quotes-revenue-widget>
        </div>
      </div>
      <div class="dashboard-items-wrapper unfiltered-dashboard-items">
        <div class="dashboard-item dashboard-item-table">
          <dashboard-branch-monthly-delivery-pipeline
            .branchId=${this.selectedBranchId}
            .assignedToUserId=${this.selectedUserId}
          ></dashboard-branch-monthly-delivery-pipeline>
        </div>
      </div>
    </div>`;
  }
  async dispose() {
    flushDashboard();
  }
  meta() {
    return {
      title: `Dealer's Module`,
      titleTemplate: null,
      description: 'SoftTech dealer CPQ'
    };
  }

  protected addDateRange(label: string, startDate: Date) {
    this.availableDateRanges.push({
      id: newGuid(),
      label: label,
      startDate: startDate,
      endDate: this.currentDate
    });
  }

  protected onDateRangeChanged = (e: CustomEvent<{ date?: DateRange }>) => {
    const dateRange = e.detail.date;
    if (dateRange) {
      this.selectedDateRange = dateRange;
    }
  };

  protected onBranchChanged = (e: CustomEvent<{ branchId?: string }>) => {
    const branchId = e.detail.branchId;
    if (branchId) {
      this.selectedBranchId = branchId;
      this.selectedUserId = emptyGuid;
    }
  };

  protected onUserChanged = (e: CustomEvent<{ userId?: string }>) => {
    if (e.detail.userId) {
      this.selectedUserId = e.detail.userId;
    }
  };

  protected onFilterReset = (_: CustomEvent<NonNullable<unknown>>) => {
    if (this.availableDateRanges.length > 1) this.selectedDateRange = this.availableDateRanges[1];
    this.selectedBranchId = emptyGuid;
    this.selectedUserId = emptyGuid;
  };
}

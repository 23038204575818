import {
  BranchQuoteSupport,
  BranchQuoteSupportStatus,
  QuoteItemConversation,
  ResultGetBranchQuoteSupport
} from '../../api/dealer-api-interface-franchisee';
import { customElement, property, query, state } from 'lit/decorators.js';
import { html } from 'lit';
import { LitElementBase } from '../../../webmodule-common/other/litelement-base';
import { NullPromise } from '../../../webmodule-common/interop/webmodule-interop';
import {
  WebModuleLitTable,
  WebModuleLitTableColumnDef
} from '../../../webmodule-common/components/src/webmodule-components';

import { buildModifiedTime, getSupportStatusBadge, getSupportTypeDisplayValue } from './conversation-helper';
import { cache } from '../cache-impl/cache-registry';
import { FranchiseeQuoteContainerManager } from '../quotes/data/franchisee-quote-manager';
import {
  serverDateTimeToLocal,
  serverDateTimeToLocalDateTime
} from '../../../webmodule-common/other/datetime-converter';
import { sleep } from '../../../webmodule-common/other/general/time';
import { tlang } from '../../../webmodule-common/other/language/lang';

export interface OpenTicketHandler {
  item: BranchQuoteSupport;
  links: QuoteItemConversation[];
  handled: boolean;
}

@customElement('wmview-branchquote-support')
export class WebModuleQuoteSupportInboxView extends LitElementBase {
  @property() quoteManager?: FranchiseeQuoteContainerManager;
  get quoteId(): string | undefined {
    return this.quoteManager?.branchQuote.id;
  }
  get quoteSetId(): string | undefined {
    return this.quoteManager?.branchQuote.quoteSetId;
  }
  @property() supplierName = '';
  @property()
  private _activeTicket?: BranchQuoteSupport | undefined;
  public get activeTicket(): BranchQuoteSupport | undefined {
    return this._activeTicket;
  }
  public set activeTicket(value: BranchQuoteSupport | undefined) {
    if (this._activeTicket !== value) {
      this._activeTicket = value;
      this.dispatchCustom('activateticket', {
        branchQuoteSupport: value
      });
    }
  }
  @query('#branchquote-support-inbox-table')
  table?: WebModuleLitTable;

  @state()
  private _results: ResultGetBranchQuoteSupport | undefined;
  private get results(): ResultGetBranchQuoteSupport | undefined {
    return this._results;
  }
  private set results(value: ResultGetBranchQuoteSupport | undefined) {
    this._results = value;
  }

  get data() {
    return this.results?.items;
  }
  get conversationLinks() {
    return this.results?.conversationLinks;
  }
  getConversationLinks(conversationId: string) {
    return this.conversationLinks?.filter(x => x.conversationId === conversationId) ?? [];
  }

  private loadDataPromise?: NullPromise<ResultGetBranchQuoteSupport> = undefined;

  public async refreshData() {
    if (this.table) {
      this.loadDataPromise = undefined;
      this.table?.fetchEvent();
      while (!this.loadDataPromise) await sleep(20);
      await this.loadDataPromise;
    }
  }
  protected eventCloseTicket = async (e: Event) => {
    e.stopImmediatePropagation();
    e.preventDefault();
    //we may want to have some form of edit validation.
    this.activeTicket = undefined;
  };
  protected eventTicketClick = (e: CustomEvent<{ item: BranchQuoteSupport }>) => {
    const result = this.dispatchCustom('open-ticket', {
      item: e.detail.item,
      links: this.getConversationLinks(e.detail.item.conversationId),
      handled: false
    });
    if (!result.handled) this.activeTicket = e.detail.item;
  };
  /** This is called by the table when it wants new page data */
  private async internalDataLoad(e: CustomEvent) {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    const table = e.detail.table as WebModuleLitTable;
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
    const index = (e.detail?.pageIndex as number) ?? 0;
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
    const length = (e.detail?.pageLength as number) ?? 100;

    this.loadDataPromise = this.quoteManager?.needsQuoteSupport(true);
    this.results = (await this.loadDataPromise) ?? undefined;
    if (!this.data) {
      table.data = [];
    }
    if (this.data) {
      //preload userids
      await cache().userProfile.preFetch(this.data.map(x => x.lastModifiedUserId));

      table.data = this.data.slice(index, index + length);
      table.rowCount = this.data.length;
    } else table.data = [];
  }

  renderTableView() {
    const keyEvent = (item: BranchQuoteSupport) => {
      return item.id;
    };
    const columns: WebModuleLitTableColumnDef[] = [];
    columns.push({
      title: tlang`%%support-subject%%`,
      classes: 'colpx-240 col-subject',
      fieldName: 'subject',
      displayValue: (_table: WebModuleLitTable, item: BranchQuoteSupport) => {
        return html`<span>${item.subject}</span>`;
      }
    });
    columns.push({
      title: tlang`Type`,
      classes: 'colpx-80',
      fieldName: 'xx',
      displayValue: (_table: WebModuleLitTable, item: BranchQuoteSupport) => getSupportTypeDisplayValue(item.type)
    });
    columns.push({
      title: tlang`Status`,
      classes: 'colpx-120 col-status',
      fieldName: 'xx',
      displayValue: (_table: WebModuleLitTable, item: BranchQuoteSupport) => getSupportStatusBadge(item.status)
    });
    columns.push({
      title: tlang`Created`,
      classes: 'colpx-80',
      fieldName: 'dateCreated',
      displayValue: (_table: WebModuleLitTable, item: BranchQuoteSupport) =>
        serverDateTimeToLocalDateTime(item.dateCreated).toLocaleString()
    });
    columns.push({
      title: tlang`Modified`,
      classes: 'colpx-140',
      fieldName: 'branchName',
      displayValue: (_table: WebModuleLitTable, item: BranchQuoteSupport) => {
        const label = cache().userProfile.getLocalData(item.lastModifiedUserId)?.friendlyName ?? '';
        const dateValue = buildModifiedTime(serverDateTimeToLocalDateTime(item.lastModified));
        return html`<span class="lastmodified-date me-2">${dateValue}</span>
          <span class="lastmodified-user">${label}</span>`;
      }
    });
    columns.push({
      title: tlang`Date Resolved`,
      classes: 'colpx-100',
      fieldName: 'xx',
      displayValue: (_table: WebModuleLitTable, item: BranchQuoteSupport) => {
        const resolvedDate =
          item.status === BranchQuoteSupportStatus.Resolved
            ? serverDateTimeToLocal(item.lastModified).toLocaleDateString()
            : '-';

        return resolvedDate;
      }
    });
    columns.push({
      title: tlang`Resolution`,
      classes: 'colpx-80',
      fieldName: 'xx',
      displayValue: (_table: WebModuleLitTable, item: BranchQuoteSupport) => {
        const resolvedDate =
          item.status === BranchQuoteSupportStatus.Resolved
            ? buildModifiedTime(serverDateTimeToLocalDateTime(item.lastModified))
            : '-';
        return resolvedDate;
      }
    });

    return html`<div class="branchquote-support-inbox">
      <webmodule-lit-table
        id="branchquote-support-inbox-table"
        .rowClass=${'tr'}
        .colClass=${'column'}
        .keyevent=${keyEvent}
        tablestyle="nestedtable"
        .clickrows=${true}
        @rowclick=${this.eventTicketClick}
        .columns=${columns}
        pageLength="600"
        @fetch=${(e: CustomEvent) => this.internalDataLoad(e)}
        @fetchtemplate=${(e: CustomEvent) => {
          this._loadExtension(e);
        }}
      >
      </webmodule-lit-table>
    </div>`;
  }
  renderTicketView() {
    return html`
      <wmview-branchquote-conversation-editor
        .readonly=${this.isReadonly}
        id="ticketeditor"
        @wm-event-close=${this.eventCloseTicket}
        .branchQuoteSupport=${this.activeTicket}
        .supplierName=${this.supplierName}
      ></wmview-branchquote-conversation-editor>
    `;
  }
  render() {
    const innerTemplate = !this.activeTicket ? this.renderTableView() : this.renderTicketView();

    return html`<div class="branchquote-support-inbox">${innerTemplate}</div>`;
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  private _loadExtension(_e: CustomEvent) {
    //const item: Tenant = e.detail.item;
    //e.detail.table?.addExtension(item, this.getExtensionForTenant());
  }

  connectedCallback(): void {
    super.connectedCallback();
  }
  disconnectedCallback(): void {
    super.disconnectedCallback();
  }
}

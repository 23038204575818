import {
  createStagedEvent,
  defaultStagedThreshold,
  EventCancellation
} from '../../../webmodule-common/other/general/staged-event';
import { customElement } from 'lit/decorators.js';
import { DataCacheGeneric } from '../../../webmodule-common/cache/generic-data-cache';
import { EventBooleanAsync, EventTemplate, Snippet } from '../../../webmodule-common/other/ui/events';
import { html } from 'lit';
import { notificationSignal } from '../../../webmodule-common/other/ui/icons/icon-notification-signal';
import { ProjectContainerManager } from '../data/project-container';
import { SSEOrder } from '../../purchase-orders/data/sse-order';
import { SSEProjectResourceReference } from '../data/sse-project';
import { SSEQuote } from '../../quotes/data/sse-quote';
import { ViewBase } from '../../../webmodule-common/other/ui/view-base';
import { WMEventSource } from '../../api/event-source';

export type EventResource = (id: string) => Promise<boolean>;

export interface ProjectResourceViewOptions {
  projectManager: ProjectContainerManager;
  quoteCache: DataCacheGeneric;
  purchaseOrderCache: DataCacheGeneric;
  canClose: EventBooleanAsync;
}
@customElement('wm-projectresourceview')
export class ProjectResourceView extends ViewBase {
  protected readonly projectManager: ProjectContainerManager;
  protected quoteCache: DataCacheGeneric;
  protected purchaseOrderCache: DataCacheGeneric;
  protected canClose: EventBooleanAsync;

  constructor(options: ProjectResourceViewOptions) {
    super();
    this.projectManager = options.projectManager;
    this.quoteCache = options.quoteCache;
    this.canClose = options.canClose;
    this.purchaseOrderCache = options.purchaseOrderCache;
  }

  async afterConstruction(): Promise<void> {
    await super.afterConstruction();
    WMEventSource.getInstance().addEventListener(
      [WMEventSource.quote, WMEventSource.order, WMEventSource.projectResource, WMEventSource.projectResourceDel],
      this._event
    );
  }
  private _eventCancel: EventCancellation = new EventCancellation();
  private _gatedRefreshEvent = createStagedEvent({
    eventFinally: () => notificationSignal(false),

    event: () => {
      this.dispatchCustom('!related-changed', false);
      this.refreshData();
    },
    cancelToken: this._eventCancel,
    testInterval: 500,
    threshold: defaultStagedThreshold,
    eventTriggered: () => {
      this.dispatchCustom('!related-changed', true);
      notificationSignal(true);
    }
  });
  private _event = (_data: unknown, _eventName: string) => {
    switch (_eventName) {
      case WMEventSource.quote:
        // eslint-disable-next-line no-case-declarations
        const qdata = _data as SSEQuote;
        // eslint-disable-next-line no-case-declarations
        const quoteId = qdata.id;
        if (this.projectManager.hasQuote(quoteId)) this._gatedRefreshEvent();
        break;
      case WMEventSource.order:
        // eslint-disable-next-line no-case-declarations
        const odata = _data as SSEOrder;
        // eslint-disable-next-line no-case-declarations
        const orderId = odata.id;
        if (this.projectManager.hasOrder(orderId)) this._gatedRefreshEvent();
        break;
      case WMEventSource.projectResource:
      case WMEventSource.projectResourceDel:
        // eslint-disable-next-line no-case-declarations
        const rdata = (_data as SSEProjectResourceReference).resource;

        if (this.projectManager.project.id === rdata.projectId) this._gatedRefreshEvent();
        break;
    }
  };

  onResourceChange() {
    this.ui.dispatchEvent(
      new CustomEvent('resource-changed', {
        bubbles: true,
        composed: true,
        detail: {}
      })
    );
  }
  async dispose(): Promise<void> {
    this._eventCancel.cancel();
    await super.dispose();
    WMEventSource.getInstance().removeEventListener([WMEventSource.quote, WMEventSource.order], this._event);
  }
  public async invalidate(): Promise<void> {
    this.requestUpdate();
  }

  public async refreshData(): Promise<void> {
    this._eventCancel.cancel();
  }

  public buttonMenu(): Snippet {
    return html``;
  }

  protected template(): EventTemplate {
    return html``;
  }

  public async updateResources(): Promise<void> {}
}

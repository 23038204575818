import {
  ClientCacheData,
  ContactCacheData,
  PaymentProfileCacheData,
  ProjectCacheData,
  ProjectPermanentDocumentCacheData,
  ProjectStateChangeReasonCacheData,
  PurchaseOrderCacheData,
  PurchaseOrderStateChangeReasonCacheData,
  QuoteCacheData,
  QuoteItemConversations,
  QuoteStateChangeReasonCacheData
} from './cache-data';
import { ClientPrimaryContact } from '../../api/dealer-api-interface-client';
import { DataCache } from '../../../webmodule-common/cache/data-cache';
import { DataCacheGeneric } from '../../../webmodule-common/cache/generic-data-cache';
import { DevelopmentError } from '../../../webmodule-common/other/development-error';
import { ProjectResourceLink } from './resource-link';
import { TenantLoginPublicInfo } from '../../api/dealer-api-interface-user';

interface DealerCacheRegistry {
  client: DataCache<ClientCacheData>;
  quoteItemConversation: DataCache<QuoteItemConversations>;
  contact: DataCache<ContactCacheData>;
  primaryContact: DataCache<ClientPrimaryContact>;
  projectResourceLink: DataCache<ProjectResourceLink>;
  paymentProfile: DataCache<PaymentProfileCacheData>;
  project: DataCache<ProjectCacheData>;
  projectPermanentDocuments: DataCache<ProjectPermanentDocumentCacheData>;
  quote: DataCache<QuoteCacheData>;
  resource: DataCacheGeneric;
  userProfile: DataCache<TenantLoginPublicInfo>;
  purchaseOrder: DataCache<PurchaseOrderCacheData>;
  quoteStateReason: DataCache<QuoteStateChangeReasonCacheData>;
  projectStateReason: DataCache<ProjectStateChangeReasonCacheData>;
  purchaseOrderStateReason: DataCache<PurchaseOrderStateChangeReasonCacheData>;
}

let _cache: DealerCacheRegistry | null = null;
let _event: (() => DealerCacheRegistry) | null = null;

export function setCacheRegistry(event: () => DealerCacheRegistry) {
  _event = event;
}

/**
 * this should only be done when logging off.
 */
export function flushCache() {
  _cache = null;
}
export function cache(): DealerCacheRegistry {
  if (!_cache) {
    _cache = _event?.() ?? null;
    if (!_cache) throw new DevelopmentError('DealerCacheRegistry setCacheRegistry invalid');
  }

  return _cache;
}

import {
  BranchQuoteSupportItemType,
  BranchQuoteSupportStatus,
  QuoteItemConversation,
  ViewBranchQuoteSupportInboxItem
} from '../../../api/dealer-api-interface-franchisee';
import {
  buildModifiedTime,
  getSupportStatusBadge,
  getSupportTypeDisplayValue
} from '../../conversation/conversation-helper';
import { cache } from '../../cache-impl/cache-registry';
import { customElement, state } from 'lit/decorators.js';
import {
  DataBinding,
  DataTracker,
  emptyGuid,
  FieldType,
  FormInputAssistant
} from '../../../../webmodule-common/other/webmodule-common';
import { EventSnippet } from '../../../../webmodule-common/interop/webmodule-interop';
import { formatQuoteNumber } from '../../../quotes/data/quote-helper-functions';
import { getApiFactory } from '../../../api/api-injector';
import { html, TemplateResult } from 'lit';
import { LitTableWrapper } from '../../../../webmodule-common/other/ui/littable-view';
import { PageManager } from '../../../../webmodule-common/other/ui/page-control';
import { RequestPage, ResultPaginated } from '../../../../webmodule-common/other/ui/RequestPage';
import { resolveURL } from '../../../../webmodule-common/other/ui/resource-resolver';
import { resourceQuote } from '../../../quotes/ui/launcher';
import {
  serverDateTimeToLocal,
  serverDateTimeToLocalDateTime
} from '../../../../webmodule-common/other/datetime-converter';
import { tlang } from '../../../../webmodule-common/other/language/lang';
import { userDataStore } from '../../common/current-user-data-store';
import {
  WebModuleLitTable,
  WebModuleLitTableColumnDef
} from '../../../../webmodule-common/components/src/components/table/lit-table';

export interface QuoteSupportInboxTableOptions {
  tabName: string;
  caption: EventSnippet;
  statusFilter: BranchQuoteSupportStatus;
  typeFilter: BranchQuoteSupportItemType;
  getBranchUsers: () => string[];
  columnOptions?: QuoteSupportInboxColumnOptions;
  defaultUserId?: string;
}

export interface QuoteSupportInboxColumnOptions {
  showResolvedColumns?: boolean;
  hideLastModifiedColumn?: boolean;
  hideRequestTypeColumn?: boolean;
}

@customElement('wm-branchquotesupportinboxviewtab')
export class QuoteSupportInboxTable extends LitTableWrapper<ViewBranchQuoteSupportInboxItem> {
  franchiseeApi = getApiFactory().franchisee();
  userCache = cache().userProfile;

  options: QuoteSupportInboxTableOptions;

  dataIsStale = false;
  private titleFilter: string | null = null;

  private dataTracker: DataTracker;

  @state()
  private _conversationLinks: QuoteItemConversation[] | undefined;
  private get conversationLinks(): QuoteItemConversation[] | undefined {
    return this._conversationLinks;
  }

  private set conversationLinks(value: QuoteItemConversation[] | undefined) {
    this._conversationLinks = value;
  }

  @state() _selectedTicketOwnerId?: string;
  private get selectedTicketOwnerId(): string | undefined {
    return this._selectedTicketOwnerId;
  }

  private set selectedTicketOwnerId(value: string | undefined) {
    this._selectedTicketOwnerId = value;
    this.refreshData();
  }

  constructor(options: QuoteSupportInboxTableOptions) {
    super();
    this.options = options;

    this.selectedTicketOwnerId = options.defaultUserId ?? emptyGuid;

    this.dataTracker = new DataTracker(new DataBinding(this));

    const addField = (
      fieldName: string,
      propertyType?: FieldType,
      nullable?: boolean,
      editorFieldName?: string,
      data?: () => any
    ) => {
      this.dataTracker.addObjectBinding(
        data ?? (() => this),
        fieldName,
        editorFieldName ?? fieldName,
        propertyType ?? FieldType.string,
        nullable ?? false
      );
    };
    addField('selectedTicketOwnerId', FieldType.string, false);
  }

  async getRowsFromServer(request: RequestPage): Promise<ResultPaginated<ViewBranchQuoteSupportInboxItem>> {
    const result = await this.franchiseeApi.getBranchQuoteSupportInbox({
      filter: this.titleFilter,
      requestPage: {
        pageIndex: request.pageIndex,
        pageSize: request.pageSize
      },
      sortAsc: request.sortAsc,
      sortField: request.sortField,
      statusFilter: this.options.statusFilter,
      ticketOwnerId: this.selectedTicketOwnerId ?? emptyGuid,
      typeFilter: this.options.typeFilter,
      branchId: userDataStore.defaultBranch.id
    });
    if (result) {
      this.conversationLinks = result.conversationLinks;
      return result.items;
    }
    this.conversationLinks = [];
    return {
      count: 0,
      pageCount: 0,
      pageIndex: 0,
      pageSize: this.pageLength(),
      results: []
    };
  }

  getColumns(): WebModuleLitTableColumnDef[] {
    const columns: WebModuleLitTableColumnDef[] = [];

    columns.push({
      title: tlang`%%quote%% No.`,
      fieldName: 'quoteNumber',
      sortable: true,
      classes: 'colpxmax-120 support-quote-number',
      displayValue: (_table: WebModuleLitTable, item: ViewBranchQuoteSupportInboxItem) => {
        const quoteNumber = formatQuoteNumber(item.quoteSuffix, item.quoteNumber, item.quoteType);
        return this.getQuoteLink(item, quoteNumber);
      }
    });
    columns.push({
      title: tlang`%%quote%% Title`,
      fieldName: 'title',
      classes: 'colpxmax-320 support-quote-title',
      displayValue: (_table: WebModuleLitTable, item: ViewBranchQuoteSupportInboxItem) => {
        return this.getQuoteLink(item, item.quoteTitle);
      }
    });
    columns.push({
      title: tlang`%%client%%`,
      fieldName: 'clientName',
      classes: 'colpxmax-160 support-client-name',
      displayValue: (_table: WebModuleLitTable, item: ViewBranchQuoteSupportInboxItem) => {
        return `${item.clientName}`;
      }
    });
    columns.push({
      title: tlang`Ticket Title`,
      fieldName: 'ticketTitle',
      classes: 'colpx-100',
      displayValue: (_table: WebModuleLitTable, item: ViewBranchQuoteSupportInboxItem) => {
        const hasConversation =
          item.requestType === BranchQuoteSupportItemType.EngineeredToOrder ||
          item.requestType === BranchQuoteSupportItemType.QuoteAssistanceRequest;
        return hasConversation ? this.getConversationLink(item, item.subject) : item.subject;
      }
    });
    columns.push({
      title: tlang`%%author%%`,
      fieldName: 'createdUserId',
      classes: 'colpxmax-180 support-author',
      displayValue: (_table: WebModuleLitTable, item: ViewBranchQuoteSupportInboxItem) => {
        const author = this.userCache.getLocal(item.createdUserId);
        return `${author?.data.friendlyName ?? ''}`;
      }
    });
    if (!this.options.columnOptions?.hideRequestTypeColumn) {
      columns.push({
        title: tlang`Request Type`,
        fieldName: 'requestType',
        sortable: true,
        classes: 'colpxmax-130 support-request-type',
        displayValue: (_table: WebModuleLitTable, item: ViewBranchQuoteSupportInboxItem) => {
          return getSupportTypeDisplayValue(item.requestType);
        }
      });
    }
    columns.push({
      title: tlang`Status`,
      fieldName: 'status',
      sortable: true,
      classes: 'colpx-100 support-table-status',
      displayValue: (_table: WebModuleLitTable, item: ViewBranchQuoteSupportInboxItem) => {
        return getSupportStatusBadge(item.status);
      }
    });
    columns.push({
      title: tlang`Date Submitted`,
      fieldName: 'dateCreated',
      sortable: true,
      classes: 'colpxmax-140 support-date-submitted',
      displayValue: (_table: WebModuleLitTable, item: ViewBranchQuoteSupportInboxItem) => {
        return serverDateTimeToLocalDateTime(item.dateCreated).toLocaleString();
      }
    });
    if (!this.options.columnOptions?.hideLastModifiedColumn) {
      columns.push({
        title: tlang`Last Modified`,
        fieldName: 'lastModified',
        sortable: true,
        classes: 'colpxmax-300 support-last-modified',
        isDefaultSortDirectionAscending: false,
        displayValue: (_table: WebModuleLitTable, item: ViewBranchQuoteSupportInboxItem) => {
          const label = cache().userProfile.getLocalData(item.lastModifiedUserId)?.friendlyName ?? '';
          const dateValue = buildModifiedTime(serverDateTimeToLocalDateTime(item.lastModified));
          return html`<span class="lastmodified-date me-2">${dateValue}</span>
            <span class="lastmodified-user">${label}</span>`;
        }
      });
    } else if (this.options.columnOptions?.showResolvedColumns) {
      columns.push({
        title: tlang`Date Resolved`,
        fieldName: 'lastModified',
        sortable: true,
        classes: 'colpxmax-140 support-date-resolved',
        displayValue: (_table: WebModuleLitTable, item: ViewBranchQuoteSupportInboxItem) => {
          const resolvedDate =
            item.status === BranchQuoteSupportStatus.Resolved
              ? serverDateTimeToLocal(item.lastModified).toLocaleDateString()
              : '-';

          return resolvedDate;
        }
      });
      columns.push({
        title: tlang`Resolution`,
        fieldName: 'resolution',
        classes: 'colpx-100',
        displayValue: (_table: WebModuleLitTable, item: ViewBranchQuoteSupportInboxItem) => {
          const resolvedDate =
            item.status === BranchQuoteSupportStatus.Resolved
              ? buildModifiedTime(serverDateTimeToLocalDateTime(item.lastModified))
              : '-';
          return resolvedDate;
        }
      });
    }

    return columns;
  }

  getQuoteLink(item: ViewBranchQuoteSupportInboxItem, value: string | TemplateResult): TemplateResult {
    return html`<a
      class="quote-link"
      href="${resolveURL(resourceQuote, item.branchQuoteId)}"
      quoteid="${item.branchQuoteId}"
      >${value}</a
    >`;
  }

  getConversationLink(item: ViewBranchQuoteSupportInboxItem, value: string | TemplateResult): TemplateResult {
    const supportFragment =
      item.requestType === BranchQuoteSupportItemType.EngineeredToOrder ||
      item.requestType === BranchQuoteSupportItemType.QuoteAssistanceRequest
        ? `support_${item.conversationId}`
        : null;
    return html`<a
      class="quote-link"
      href="${resolveURL(resourceQuote, item.branchQuoteId, supportFragment)}"
      quoteid="${item.branchQuoteId}"
      >${value}</a
    >`;
  }

  override enableFiltering(): boolean {
    return true;
  }

  override updateFilter(_searchTerm: string | null) {
    this.titleFilter = _searchTerm;
    this.refreshData();
  }

  override enableAdvancedFiltering(): boolean {
    return true;
  }

  override getDefaultSortField(): string | undefined {
    if (!this.options.columnOptions?.hideLastModifiedColumn) {
      return 'lastModified';
    }
    if (this.options.columnOptions?.showResolvedColumns) {
      return 'lastModified';
    }
    return 'quoteNumber';
  }

  override advancedFilterTemplate(): TemplateResult {
    const forms = new FormInputAssistant(this.dataTracker, false, true);
    const uniqueTicketOwners = [...new Set(this.options.getBranchUsers())];
    uniqueTicketOwners.unshift(emptyGuid);

    const toSelectValue = (text: string, value: string) => {
      return { text: text, value: value, disabled: false };
    };

    const convertAuthorId = (authorId: string) => {
      if (authorId === emptyGuid) {
        return toSelectValue(`All Users`, authorId);
      }
      const entry = this.userCache.getLocal(authorId);
      if (entry?.data) {
        return toSelectValue(entry.data.friendlyName ?? entry.displayValue, entry.data.id);
      }
      return toSelectValue(``, authorId);
    };

    return html`
      ${forms.arraySelect('selectedTicketOwnerId', uniqueTicketOwners, convertAuthorId, {
        title: tlang`Ticket Owner`
      })}
    `;
  }

  // eslint-disable-next-line @typescript-eslint/require-await
  override async rowClicked(
    details: CustomEvent<{ table: WebModuleLitTable; item: ViewBranchQuoteSupportInboxItem }>
  ): Promise<void> {
    this.dispatchCustom('open-ticket', {
      item: details.detail.item,
      links: this.getConversationLinks(details.detail.item.conversationId),
      handled: false
    });
  }

  getConversationLinks(conversationId: string) {
    return this.conversationLinks?.filter(x => x.conversationId === conversationId) ?? [];
  }

  public createPageManager(): PageManager {
    return {
      caption: this.options.caption,
      canClose: () => Promise.resolve(false),
      canLeave: () => Promise.resolve(true),
      content: () => this,
      hasDelete: () => false,
      // eslint-disable-next-line @typescript-eslint/require-await
      onEnter: async () => {
        //enable data load the first time we enter the tab, but not before
        this.delayedDataLoad = false;
        // not-awaiting on purpose, for faster page switch. safe to do
        if (this.dataIsStale) this.refreshData();
      },
      data: this,
      pageFragment: this.options.tabName
    };
  }
}

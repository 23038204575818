import { EventSnippet } from '../../interop/webmodule-interop';
import { tlang } from '../language/lang';
import { WaitPatiently } from './modal-spinner';

export function waitPatientlyLoading(title?: EventSnippet, message?: EventSnippet) {
  const modal = new WaitPatientlyLoading();
  modal.eventTitle = title ?? (() => tlang`Loading`);
  modal.message = message ?? (() => tlang`just a moment...`);

  modal.delayedShow();
  return modal;
}

//a progress window default to run will doing a save to the server
export class WaitPatientlyLoading extends WaitPatiently {
  get borderType() {
    return 'basic';
  }
}

import type { LitElement } from 'lit';

type UpdateHandler = (prev?: unknown, next?: unknown) => void;

type NonUndefined<A> = A extends undefined ? never : A;

type UpdateHandlerFunctionKeys<T extends object> = {
  [K in keyof T]-?: NonUndefined<T[K]> extends UpdateHandler ? K : never;
}[keyof T];

interface MonitorOptions {
  /**
   * Flag to indicate if we should only start watching properties after initial render.
   */
  delayUntilFirstUpdate?: boolean;
}

/**
 * Runs when @property or @state properties changes, and before the element update.
 *
 * Example:
 * @monitor('propName')
 * handlePropChange() {}
 */
export function monitor(propertyName: string | string[], options?: MonitorOptions) {
  // Default delayMonitorUntilFirstUpdate = false, override with options passed in.
  const resolvedOptions: Required<MonitorOptions> = {
    delayUntilFirstUpdate: false,
    ...options
  };

  return <ElemClass extends LitElement>(proto: ElemClass, decoratedFnName: UpdateHandlerFunctionKeys<ElemClass>) => {
    // @ts-expect-error - update is a protected property
    const { update } = proto;
    const monitoredProperties = Array.isArray(propertyName) ? propertyName : [propertyName];

    // @ts-expect-error - update is a protected property
    proto.update = function (this: ElemClass, changedProps: Map<keyof ElemClass, ElemClass[keyof ElemClass]>) {
      monitoredProperties.forEach(property => {
        const key = property as keyof ElemClass;
        if (changedProps.has(key)) {
          const oldValue = changedProps.get(key);
          const newValue = this[key];

          if (oldValue !== newValue) {
            if (!resolvedOptions.delayUntilFirstUpdate || this.hasUpdated) {
              (this[decoratedFnName] as unknown as UpdateHandler)(oldValue, newValue);
            }
          }
        }
      });

      update.call(this, changedProps);
    };
  };
}

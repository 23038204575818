import { awaitableTemplate } from '../../../../webmodule-common/other/ui/template-processor';
import { customElement, property } from 'lit/decorators.js';
import { DashboardWidget } from './dashboard-widget';
import { DateRange } from './dashboard-filter';
import { generatedPurchaseOrdersCache } from '../data/generated-purchase-orders-cache';
import { html } from 'lit-html';
import { issuedQuotesCache } from '../data/issued-quotes-cache';
import { localDateTimeToServer } from '../../../../webmodule-common/other/datetime-converter';
import { Snippet } from '../../../../webmodule-common/interop/webmodule-interop';
import { tlang } from '../../../../webmodule-common/other/language/lang';

const ApexCharts = (globalThis as any).ApexCharts;

@customElement('sales-success-widget')
export class SalesSuccessWidget extends DashboardWidget {
  private totalQuotes: number = 0;
  private totalPurchaseOrders: number = 0;

  @property() dateRange?: DateRange;
  @property() branchId?: string;
  @property() userId?: string;

  private _quotesCache = issuedQuotesCache();
  private _purchaseOrdersCache = generatedPurchaseOrdersCache();

  chart: any | null = null;

  protected async refreshData(): Promise<Snippet> {
    if (this.dateRange) {
      const startDate = localDateTimeToServer(this.dateRange.startDate);
      const endDate = localDateTimeToServer(this.dateRange.endDate);
      const quoteResult = await this._quotesCache?.getData(startDate, endDate, this.branchId, this.userId);
      if (quoteResult) {
        this.totalQuotes = quoteResult.revenueSummary.reduce((total, summary) => {
          return (total += summary.count);
        }, 0);
      }
      const purchaseOrderResult = await this._purchaseOrdersCache?.getData(
        startDate,
        endDate,
        this.branchId,
        this.userId
      );
      if (purchaseOrderResult) {
        this.totalPurchaseOrders = purchaseOrderResult.revenueSummary.reduce((total, summary) => {
          return (total += summary.count);
        }, 0);
      }
      this.chart.updateSeries([this.totalPurchaseOrders, this.unconvertedQuoteCount]);
    }
    return html`${this.totalPurchaseOrders} / ${this.totalQuotes}`;
  }

  protected firstUpdated(): void {
    const options = {
      series: [this.totalPurchaseOrders, this.unconvertedQuoteCount],
      labels: [tlang`Converted !!quote!!`, tlang`Unconverted !!quote!!`],
      colors: ['#3282CC', '#C7CBD0'],
      chart: {
        type: 'donut',
        height: '140%',
        offsetY: 0
      },
      dataLabels: {
        enabled: false
      },
      tooltip: {
        enabled: false
      },
      plotOptions: {
        pie: {
          expandOnClick: false,
          donut: {
            size: '60%',
            labels: {
              show: true,
              total: {
                show: true,
                showAlways: true,
                label: tlang`Converted !!quote!!`,
                formatter: () => this.getChartLabel()
              },
              name: {
                show: false
              },
              value: {
                show: true
              }
            }
          }
        }
      },
      legend: { show: false },
      states: {
        hover: {
          filter: {
            type: 'none',
            value: 0
          }
        },
        active: {
          filter: {
            type: 'none',
            value: 0
          }
        }
      }
    };
    this.chart = new ApexCharts(this.querySelector('.salesSuccessChart'), options);
    this.chart.render();
  }

  protected getHeader(): Snippet {
    return tlang`Sales Success`;
  }

  protected getBody(): Snippet {
    return html`
      <div class="container">
        <div class="row chart-widget-data-wrapper">
          <div class="col-6 chart-widget-data">
            <div class="display-5 text-primary">${awaitableTemplate(this.refreshData(), 'Loading...')}</div>
            <div class="display-6 text-secondary">${tlang`Conversion !!quote!! to !!order!!`}</div>
          </div>
          <div class="col-6 chart-widget-chart">
            <div class="salesSuccessChart"></div>
          </div>
        </div>
      </div>
    `;
  }

  protected get unconvertedQuoteCount(): number {
    return this.totalQuotes - this.totalPurchaseOrders;
  }

  protected getChartLabel(): string {
    const result = ((this.totalPurchaseOrders + Number.EPSILON) / this.totalQuotes) * 100;
    const percentage = Math.round(result);
    // if there are currently no quotes in the system, we get a infinity result due to division by 0,
    // so check to make sure we have a finite result to return as a percentage.
    if (Number.isFinite(percentage)) {
      return `${percentage}%`;
    }
    return `0%`;
  }
}

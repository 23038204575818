import { ApiCommunications, NullPromise } from '../../webmodule-common/interop/webmodule-interop';
import {
  InputCreateQuote,
  InputCreateQuoteItem,
  InputDeleteQuote,
  InputDeleteQuoteItem,
  InputDuplicateQuote,
  InputDuplicateQuoteItem,
  InputSetQuoteAssignedToUser,
  InputUpdateEtoItemsV2,
  InputUpdateQuote,
  InputUpdateQuoteItem,
  InputUpdateQuoteItems,
  type InputUpdateQuotePrice,
  InputUpdateQuoteSetState,
  RequestGetIssuedQuoteRevenue,
  RequestGetQuote,
  RequestGetQuoteItemsSummary,
  RequestGetQuoteItemsSummaryById,
  RequestGetQuoteSetSummary,
  RequestGetQuoteStateAudit,
  RequestGetQuoteStateChangeReasonsByOwnerIds,
  RequestGetQuoteSummary,
  RequestGetQuoteTotalsByState,
  RequestGetQuoteUserIdsByOwnerId,
  RequestGetQuoteUserIdsByOwnerIds,
  ResultCreateQuote,
  ResultCreateQuoteItem,
  ResultDeleteQuote,
  ResultDeleteQuoteItem,
  ResultDuplicateQuote,
  ResultDuplicateQuoteItem,
  ResultGetIssuedQuoteRevenue,
  ResultGetQuote,
  ResultGetQuoteItemsSummary,
  ResultGetQuoteItemsSummaryById,
  ResultGetQuoteSetSummary,
  ResultGetQuoteStateAudit,
  ResultGetQuoteStateChangeReasonsByOwnerIds,
  ResultGetQuoteSummary,
  ResultGetQuoteTotalsByState,
  ResultGetQuoteUserIdsByOwnerId,
  ResultGetQuoteUserIdsByOwnerIds,
  ResultSetQuoteAssignedToUser,
  ResultUpdateEtoItems,
  ResultUpdateQuote,
  ResultUpdateQuoteItem,
  ResultUpdateQuoteItems,
  type ResultUpdateQuotePrice,
  ResultUpdateQuoteSetState
} from './dealer-api-interface-quote';
import { QuoteApi } from './quote-api';
import { tenantId } from '../../webmodule-common/other/api/current-user';

export class QuoteApiImpl implements QuoteApi {
  api: ApiCommunications;

  constructor(api: ApiCommunications) {
    this.api = api;
  }

  async updateQuotePrice(input: InputUpdateQuotePrice): NullPromise<ResultUpdateQuotePrice> {
    return await this.api.post<ResultUpdateQuotePrice>('api/Quote/UpdateQuotePrice', input);
  }

  async deleteQuote(input: InputDeleteQuote): NullPromise<ResultDeleteQuote> {
    return await this.api.post<ResultDeleteQuote>('api/Quote/DeleteQuote', input);
  }

  async duplicateQuote(input: InputDuplicateQuote): NullPromise<ResultDuplicateQuote> {
    return await this.api.post<ResultDuplicateQuote>('api/Quote/DuplicateQuote', input);
  }

  async getQuoteSummary(request: RequestGetQuoteSummary): NullPromise<ResultGetQuoteSummary> {
    return await this.api.post<ResultGetQuoteSummary>('api/Quote/GetQuoteSummary', request);
  }

  async getQuoteSetSummary(request: RequestGetQuoteSetSummary): NullPromise<ResultGetQuoteSetSummary> {
    return await this.api.post<ResultGetQuoteSetSummary>('api/Quote/GetQuoteSetSummary', request);
  }

  async getQuote(request: RequestGetQuote): NullPromise<ResultGetQuote> {
    return await this.api.post<ResultGetQuote>('api/Quote/GetQuote', request);
  }

  async createQuote(input: InputCreateQuote): NullPromise<ResultCreateQuote> {
    return await this.api.post<ResultCreateQuote>('api/Quote/CreateQuote', input);
  }

  async updateQuote(input: InputUpdateQuote): NullPromise<ResultUpdateQuote> {
    return await this.api.post<ResultUpdateQuote>('api/Quote/UpdateQuote', input);
  }

  async createQuoteItem(input: InputCreateQuoteItem): NullPromise<ResultCreateQuoteItem> {
    return await this.api.post<ResultCreateQuoteItem>('api/QuoteItem/CreateQuoteItem', input);
  }

  async updateQuoteItem(input: InputUpdateQuoteItem): NullPromise<ResultUpdateQuoteItem> {
    return await this.api.post<ResultUpdateQuoteItem>('api/QuoteItem/UpdateQuoteItem', input);
  }

  async updateQuoteItems(input: InputUpdateQuoteItems): NullPromise<ResultUpdateQuoteItems> {
    return await this.api.post<ResultUpdateQuoteItems>('api/QuoteItem/UpdateQuoteItems', input);
  }

  async deleteQuoteItem(input: InputDeleteQuoteItem): NullPromise<ResultDeleteQuoteItem> {
    return await this.api.post<ResultDeleteQuoteItem>('api/QuoteItem/DeleteQuoteItem', input);
  }

  async duplicateQuoteItem(input: InputDuplicateQuoteItem): NullPromise<ResultDuplicateQuoteItem> {
    return await this.api.post<ResultDuplicateQuoteItem>('api/QuoteItem/DuplicateQuoteItem', input);
  }

  async getQuoteTotalsByState(request: RequestGetQuoteTotalsByState): NullPromise<ResultGetQuoteTotalsByState> {
    return await this.api.post<ResultGetQuoteTotalsByState>('api/Quote/GetQuoteTotalsByState', request);
  }

  async getQuoteItemsSummary(request: RequestGetQuoteItemsSummary): NullPromise<ResultGetQuoteItemsSummary> {
    return await this.api.post<ResultGetQuoteItemsSummary>('api/QuoteItem/GetQuoteItemsSummary', request);
  }

  createQuoteItemThumbnailPath(quoteId: string, quoteItemId: string, ext: string): string {
    const d = new Date().getTime();
    return `T${tenantId()}/services/quote/${quoteId}/quoteItem/${quoteItemId}/${d}/thumbnail${ext}`;
  }

  async getQuoteItemsSummaryById(
    request: RequestGetQuoteItemsSummaryById
  ): NullPromise<ResultGetQuoteItemsSummaryById> {
    return await this.api.post<ResultGetQuoteItemsSummaryById>('api/QuoteItem/GetQuoteItemsSummaryById', request);
  }

  async getQuoteStateAudit(request: RequestGetQuoteStateAudit): NullPromise<ResultGetQuoteStateAudit> {
    return await this.api.post<ResultGetQuoteStateAudit>('api/Quote/GetQuoteStateAudit', request);
  }

  async getQuoteStateChangeReasons(
    request: RequestGetQuoteStateChangeReasonsByOwnerIds
  ): NullPromise<ResultGetQuoteStateChangeReasonsByOwnerIds> {
    return await this.api.post<ResultGetQuoteStateChangeReasonsByOwnerIds>(
      'api/Quote/GetQuoteStateChangeReasonsByOwnerIds',
      request
    );
  }

  async updateQuoteSetState(input: InputUpdateQuoteSetState): NullPromise<ResultUpdateQuoteSetState> {
    return await this.api.post<ResultUpdateQuoteSetState>('api/Quote/UpdateQuoteSetState', input);
  }

  async getIssuedQuoteRevenue(request: RequestGetIssuedQuoteRevenue): NullPromise<ResultGetIssuedQuoteRevenue> {
    return await this.api.post<ResultGetIssuedQuoteRevenue>('api/Quote/GetIssuedQuoteRevenue', request);
  }

  async updateEtoItemsV2(input: InputUpdateEtoItemsV2): NullPromise<ResultUpdateEtoItems> {
    return await this.api.post<ResultUpdateEtoItems>('api/Quote/UpdateEtoItemsV2', input);
  }

  async getQuoteUserIdsByOwnerId(
    request: RequestGetQuoteUserIdsByOwnerId
  ): NullPromise<ResultGetQuoteUserIdsByOwnerId> {
    return await this.api.post<ResultGetQuoteUserIdsByOwnerId>(`api/Quote/GetQuoteUserIdsByOwnerId`, request);
  }

  async getQuoteUserIdsByOwnerIds(
    request: RequestGetQuoteUserIdsByOwnerIds
  ): NullPromise<ResultGetQuoteUserIdsByOwnerIds> {
    return await this.api.post<ResultGetQuoteUserIdsByOwnerIds>(`api/Quote/GetQuoteUserIdsByOwnerIds`, request);
  }

  async setAuthorForQuotes(input: InputSetQuoteAssignedToUser): NullPromise<ResultSetQuoteAssignedToUser> {
    return await this.api.post<ResultSetQuoteAssignedToUser>('api/Quote/SetQuoteAssignedToUser', input);
  }
}

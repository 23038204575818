import { ApiCommunications, NullPromise } from '../../webmodule-common/interop/webmodule-interop';
import {
  InputCreatePurchaseOrder,
  InputUpdatePurchaseOrder,
  RequestGetGeneratedPurchaseOrderRevenue,
  RequestGetPurchaseOrder,
  RequestGetPurchaseOrderDeliveryPipeline,
  RequestGetPurchaseOrders,
  RequestGetPurchaseOrderStateAudit,
  RequestGetPurchaseOrderStateChangeReasonsByOwnerIds,
  RequestGetPurchaseOrderTotalsByState,
  ResultCreatePurchaseOrder,
  ResultGetGeneratedPurchaseOrderRevenue,
  ResultGetPurchaseOrder,
  ResultGetPurchaseOrderDeliveryPipeline,
  ResultGetPurchaseOrders,
  ResultGetPurchaseOrderStateAudit,
  ResultGetPurchaseOrderStateChangeReasonsByOwnerIds,
  ResultGetPurchaseOrderTotalsByState,
  ResultUpdatePurchaseOrder
} from './dealer-api-interface-franchisee';
import { PurchaseOrderApi } from './purchase-order-api';

export class PurchaseOrderApiImplementation implements PurchaseOrderApi {
  api: ApiCommunications;
  private readonly purchaseOrderApiPath = 'api/Franchisee/';

  constructor(api: ApiCommunications) {
    this.api = api;
  }

  async getPurchaseOrderDeliveryPipeline(
    request: RequestGetPurchaseOrderDeliveryPipeline
  ): NullPromise<ResultGetPurchaseOrderDeliveryPipeline> {
    return await this.post('GetPurchaseOrderDeliveryPipeline', request);
  }

  async getPurchaseOrders(request: RequestGetPurchaseOrders): NullPromise<ResultGetPurchaseOrders> {
    return await this.post('GetPurchaseOrders', request);
  }

  async getPurchaseOrder(request: RequestGetPurchaseOrder): NullPromise<ResultGetPurchaseOrder> {
    return await this.post('GetPurchaseOrder', request);
  }

  async createPurchaseOrder(input: InputCreatePurchaseOrder): NullPromise<ResultCreatePurchaseOrder> {
    return await this.post('CreatePurchaseOrder', input);
  }

  async updatePurchaseOrder(input: InputUpdatePurchaseOrder): NullPromise<ResultUpdatePurchaseOrder> {
    return await this.post('UpdatePurchaseOrder', input);
  }

  async getTotalsByState(
    request: RequestGetPurchaseOrderTotalsByState
  ): NullPromise<ResultGetPurchaseOrderTotalsByState> {
    return await this.post('GetPurchaseOrderTotalsByState', request);
  }

  private async post<T>(endpoint: string, request: any): NullPromise<T> {
    return await this.api.post(`${this.purchaseOrderApiPath}${endpoint}`, request);
  }

  async getPurchaseOrderStateAudit(
    request: RequestGetPurchaseOrderStateAudit
  ): NullPromise<ResultGetPurchaseOrderStateAudit> {
    return await this.post('GetPurchaseOrderStateAudit', request);
  }

  async getPurchaseOrderStateChangeReasons(
    request: RequestGetPurchaseOrderStateChangeReasonsByOwnerIds
  ): NullPromise<ResultGetPurchaseOrderStateChangeReasonsByOwnerIds> {
    return await this.post('GetPurchaseOrderStateChangeReasonsByOwnerIds', request);
  }

  async getGeneratedPurchaseOrderRevenue(
    request: RequestGetGeneratedPurchaseOrderRevenue
  ): NullPromise<ResultGetGeneratedPurchaseOrderRevenue> {
    return await this.post('GetGeneratedPurchaseOrderRevenue', request);
  }
}

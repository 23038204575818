import { buttonsSaveCancel } from '../../../../webmodule-common/other/ui/modal-confirmation';
import { cache } from '../../cache-impl/cache-registry';
import { constructAsync } from '../../../../webmodule-common/other/async-constructor';
import { customElement } from 'lit/decorators.js';
import { FranchiseePaymentProfileDetailView } from './franchisee-payment-profile-detail-view';
import { PaymentProfileContainerManager } from '../../../franchisee/data/payment-profile-container-manager';
import { PaymentProfileDataEntryView, PaymentProfileView } from '../../../franchisee/view/payment-profile-view';
import { PaymentProfileDetailView } from '../../../franchisee/view/payment-profile-detail-view';
import { TemplateResult } from 'lit-html';

@customElement('wm-franchiseepaymentprofiledataentryview')
export class FranchiseePaymentProfileDataEntryView extends PaymentProfileDataEntryView {
  public async saveAndClose(): Promise<boolean> {
    if (this.isDataReadonly()) return false;

    return await this.tryClose();
  }

  protected paymentProfileContainerManagerFactory(): PaymentProfileContainerManager {
    const manager = new PaymentProfileContainerManager(this.paymentProfile, this.eventTitle, this.paymentProfileApi);
    manager.afterSave.push(() => {
      cache().paymentProfile.flush([this.paymentProfile.paymentProfileId]);
    });
    return manager;
  }

  protected async paymentProfileDetailViewFactory(): Promise<PaymentProfileDetailView> {
    return await constructAsync(
      new FranchiseePaymentProfileDetailView(this, {
        paymentProfileManager: this.paymentProfileManager
      })
    );
  }
}

@customElement('wm-franchiseepaymentprofileview')
export class FranchiseePaymentProfileView extends PaymentProfileView {
  protected createView(): PaymentProfileDataEntryView {
    const x = new FranchiseePaymentProfileDataEntryView(this.options);
    x.owner = this;
    return x;
  }

  protected async save() {
    return this.view?.saveAndClose();
  }

  protected footerTemplate(): TemplateResult | null {
    const saveEvent = async () => {
      if (await this.save()) await this.hideModal();
    };
    const buttons = buttonsSaveCancel();
    return this.createConfirmCancelButtons(buttons.ok, saveEvent, buttons.cancel);
  }
}
